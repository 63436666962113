<template>
  <div class="nav-rights withdraw">
   <!-- <div class="headBox">
      <span style="color:aliceblue;font-size:20px;">
        {{$t('uc.finance.pickup')}}
      </span>
    </div> -->
    <div class="headBox">
       <img src="../assets/newImage/back.png" alt="" style="width:20px;height:15px;" @click="goback">
      <span >
        {{$t('uc.finance.trans')}}
      </span>
      <span>{{  }}</span>
    </div>
      

     
       <div class="rightarea">
        
        <!-- <div style="display:flex; justify-items:flex-end;margin-top: -5px;">
          <img src="../assets/newImage/coin.png" style="width:30px;height:30px;" alt="">
          <span style="font-size: 20px;color: #a2a2a1; margin-left: 10px;">{{selfamount}}</span>  
        </div> -->
        
        <!-- <Select v-model="model1" style="width:80%;margin-top: -10px;" disabled>
          <Option v-for="item in coinList" :value="item.bankName" :key="item.id">{{item.acctName}}</Option>
        </Select> -->
      <section style="margin-top:10px;" v-if="model1 == 'USDT'">
      <!-- <div style="font-size:20px;color:#c1bfb6;">
        <span >{{$t('uc.finance.personalassets')}} :: TRC20</span>
      </div> -->
     <div class="celllall2">
      <!-- <span style="margin-left:20px">
        {{$t('uc.forget.userID')}}
      </span> -->
      <!-- <input type="text" v-model="inputAddress" prop="inputAddress" @input="changePrice('inputAddress')" size="large" style="color: #e4c87b;border-radius: 10px; margin-top: -10px; border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;" clearable  :placeholder="$t('uc.forget.addresstip21')"> -->
      <Input
                v-model="inputAddress"
                type="text"
                size="large"
                style="color: #e4c87b;border-radius: 10px; margin-top: -10px; border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  "
                clearable
                :placeholder="$t('uc.forget.addresstip21')"
              /> 
    </div>
     <div class="celllall2">
      <!-- <span style="margin-left:20px">
        {{$t('uc.finance.invitingmining')}}
      </span> -->

      <Input
                v-model="withdrawAmount"
                type="text"
                size="large"
                style="color: #e4c87b;border-radius: 10px;  border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  "
                clearable
                @on-change="onKeyYHKNameInput"
                :placeholder="$t('uc.finance.invitingmining')"
              /> 
      <!-- <input type="text" @change="onKeyYHKNameInput" v-model="withdrawAmount" style="background: #000;color: #898989; width:80%;padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px;border-style:ridge;margin-left: 20px;" clearable  :placeholder="$t('uc.finance.invitingmining')"> -->
     </div>
      <!-- <div style="margin-top:20px" >
                        <span style="font-size: 14px;color: #a2a2a1;">{{$t('uc.finance.personalassets')}}</span>
                    </div>
                    <div>
                      <Button  style="background-color:#e5b932;border-radius: 25px; height: 40px; width: 100px; font-weight: bold; border: none;margin: 10px;margin-top: 0%;">TRC20</Button>
                    </div> -->
      <div style="margin-top:80px;">
        <Button class="btnSub"  @click="firstModel">{{$t('uc.forget.save')}}</Button>
      </div>                
                  
      </section>
      <section v-else-if=" model1 == 'HUIONE'">
      </section>
 
      <div style="color: #907633;  width: 100%;text-align: center;margin-top: 10px;padding: 10px;letter-spacing: 1px;">
        <span>
          {{$t('common.notice23')}}
        </span>
      </div>
      </div>
    

    <Modal v-model="modal2" width="360">
      <p slot="header" style="color:#f60;text-align:center">
       
        <span>{{$t('uc.forget.emailcodetip1')}}</span>
      </p>
      <Form class="withdraw-form-inline" ref="formInline" :model="formInline" inline>
        <FormItem>
          <Input type="password" password v-model="formInline.fundpwd" :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
        <FormItem>
          <Input type="password" password v-model="formInline.refundpwd" :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" size="large" long @click="submitCode">{{$t('uc.forget.save')}}</Button>
      </div>
    </Modal>

    <Modal v-model="modal9" width="450">
      
      <p slot="header">
        {{$t("uc.forget.emailcodetip")}}
      </p>
      <Form class="withdraw-form-inline" ref="formInline" :model="formInline" inline>
        <FormItem>
          <Input type="password" password v-model="formInline.fundpwd" :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
       
      </Form>
      <div slot="footer">
        <span style="margin-right:50px" @click="cancel">{{$t("common.close")}}</span>
        <span style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;" @click="checkpp">{{$t("common.ok")}}</span>
      </div>
    </Modal>

    <Modal v-model="modal10" width="450">
      
      <p slot="header">
        {{$t("common.tibifirst")}}
      </p>
      <p>
        {{$t('uc.forget.confirmpwd11')}} : {{inputAddress}}
      </p>
      <br>
      <p>
        {{$t('uc.forget.walletaddress2')}} : {{withdrawOutAmount}}
      </p>
      <div slot="footer">
        <span style="margin-right:50px" @click="cancel">{{$t("common.close")}}</span>
        <span style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;" @click="okfirst">{{$t("common.ok")}}</span>
      </div>
    </Modal>



  </div>
</template>


<script>



export default {
    components: {
  },
  data() {
    return {
      user: {},
      codeIsSending: false,
      sendcodeValue: this.$t("uc.regist.sendcode"),
      countdown: 60,
      formInline: {
        fundpwd: "",
        refundpwd: ""
      },
      value14:'',
       modal9: false,
      modal10: false,
      modal: false,
      modal2: false,
      fundpwd: "",
      currentCoin: {},
      transaction: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      loading: true,
      withdrawAdress: "",
      inputAddress: "", //用户输入的地址
      withdrawAmount: 0,
      withdrawFee: 0,
      withdrawOutAmount: 0,
      coinType: "",
      coinList: [],
      tableWithdraw: [],
      allTableWithdraw: [],
      model1:'USDT',
      moneybab:'',
      model21:'',
      selfamount:'',
    };
  },
  watch: {
    currentCoin: function() {
      this.withdrawFee =
        this.currentCoin.minTxFee +
        (this.currentCoin.maxTxFee - this.currentCoin.minTxFee) / 2;
    }
  },
  methods: {
    goback() {
      //获取个人安全信息
      this.$router.back(-1)
     
    },
    changePrice(){
    // eslint-disable-next-line no-useless-escape
    this.inputAddress = this.inputAddress.replace(/[^\d.]/g,"") //清除非 数字和小数点的字符
    this.inputAddress = this.inputAddress.replace(/\.{2,}/g,".") //清除第二个小数点
    this.inputAddress = this.inputAddress.replace(/^\./g,""); //验证第一个字符是数字而不是字符
    this.inputAddress = this.inputAddress.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
    // eslint-disable-next-line no-useless-escape
    this.inputAddress = this.inputAddress.indexOf(".") > 0? this.inputAddress.split(".")[0].substring(0, 6) + "." + this.inputAddress.split(".")[1]: this.inputAddress.substring(0, 6); //限制只能输入7位正整数
},
    okfirst(){
      this.getMemberInfo()
    },
    onKeyYHKNameInput() {
       let value = '' + this.withdrawAmount
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
        }
        this.$nextTick(function(){
          this.withdrawOutAmount = value;
          this.withdrawAmount = Number(value).toLocaleString()
        })
     },
    cancel() {
      this.modal9 = false;
      this.modal10 = false;
      this.formInline.code = "";
      this.formInline.fundpwd = "";
    },
    sendCode() {
      this.$http.post(this.host + "/uc/mobile/withdraw/code").then(response => {
        var resp = response.body;
        if (resp.code == 0) {
          this.settime();
          this.$Notice.success({
            title: this.$t("common.tip"),
            desc: resp.message
          });
        } else {
          this.$Notice.error({
            title: this.$t("common.tip"),
            desc: resp.message
          });
        }
      });
    },
    settime() {
      this.sendcodeValue = this.countdown;
      this.codeIsSending = true;
      let timercode = setInterval(() => {
        this.countdown--;
        this.sendcodeValue = this.countdown;
        if (this.countdown <= 0) {
          clearInterval(timercode);
          this.sendcodeValue = this.$t("uc.regist.sendcode");
          this.countdown = 60;
          this.codeIsSending = false;
        }
      }, 1000);
    },
    changePage(index) {
      this.transaction.page = index - 1;
      this.getList();
    },
    onAddressChange(data) {
      this.inputAddress = data;
    },
    clearValues() {
      if (this.$refs.address) {
        this.$refs.address.setQuery(" ");
      }
      this.withdrawAdress = "";
      this.inputAddress = "";
      this.withdrawAmount = 0;
      // this.withdrawFee= 0;
      this.withdrawOutAmount = 0;
    },
    getCurrentCoinRecharge() {
      if (this.coinType != "") {
        var temp = [];
        for (var i = 0; i < this.allTableWithdraw.length; i++) {
          //   if (this.allTableWithdraw[i].symbol == this.coinType) {
          if (this.allTableWithdraw[i].coin.unit == this.coinType) {
            temp.push(this.allTableWithdraw[i]);
          }
        }
        this.tableWithdraw = temp;
      } else {
        this.tableWithdraw = this.allTableWithdraw;
      }
    },
getMemberInfo() {
      //获取个人安全信息
      var self = this;
     
      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
          
           if (resp.data.jbPassword) {
            // self.getAddrList()
            self.modal9 = true
           }else{
            // this.$Message.error(this.$t('uc.forget.emailcodetip1'));
            self.modal2 = true
           }
          
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },
  getMember() {
      //获取个人安全信息
      var self = this;
      // var params = {
      //     };
         
      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
         
      this.$http
        .post(self.host + this.api.uc.personalWallet)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            // self.user = resp.data;
            // self.usernameS = this.user.username.slice(0,1);
            self.tableWithdraw = resp.data
           
            self.tableWithdraw.forEach(element => {
              if (element.ccy == 'USDT') {
                
                self.selfamount = element.amount
                
              }
            });
            // if (this.isTg) {
            //   this.changeCoin()
            //   this.sliderSellMarketPercent = Number(this.$store.getters.amount)
            // }
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

checkpp(){
  
  if (this.formInline.fundpwd == '') {
              this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: this.$t("uc.forget.emailcodetip")
                  });
                  return
             }
             this.getAddrList(this.formInline.fundpwd)

// let params = {};
  
// params['password']= this.formInline.fundpwd
// this.$http
//         .post(this.host + this.api.uc.mylistrecord, params)
//         .then(response => {
//           this.fundpwd = "";
//           var resp = response.body;
//           if (resp.code == "0000") {
//             this.modal9 = false;
//             this.formInline.fundpwd = ''
//             // this.$Message.success(resp.mesg);
//             if (resp.data == true) {
//               this.getAddrList()
//             }else
//              this.$Notice.error({
//                     title: this.$t("common.tip"),
//                     desc: this.$t("uc.regist.emailerr")
//                   });
            
//           } else {
//             this.$Message.error(resp.mesg);
//           }
//         });
},

submitCode(){
  if (this.formInline.fundpwd != this.formInline.refundpwd) {
              this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: this.$t("uc.forget.pwdvalidate2")
                  });
                  return
             }
let params = {};
  
params['jbPassword']= this.formInline.fundpwd
this.$http
        .post(this.host + this.api.uc.activitylist, params)
        .then(response => {
          this.fundpwd = "";
          var resp = response.body;
          if (resp.code == "0000") {
            this.modal2 = false;
            this.formInline.fundpwd = ''
            this.getAddrList()
            // this.ok()
          } else {
            this.$Message.error(resp.mesg);
          }
        });
},



    //withdraw
    ok() {
      // if (this.formInline.fundpwd == "") {
      //   this.modal = true;
      //   this.$Message.error(this.$t("otc.chat.msg7tip"));
      //   return;
      // }
      let params = {};
      // for (let i = 0; i < this.currentCoin.addresses; i++) {
      //   if(this.currentCoin.addresses[i].address == this.withdrawAdress){
      //     params["remark"] = this.currentCoin.addresses[i].remark;
      //   }
      // }

      params["transferNo"] = this.model21;
     
      this.$http
        .post(this.host + this.api.uc.getfreecard,params)
        .then(response => {
          this.formInline.fundpwd = "";
          var resp = response.body;
          if (resp.code == "0000") {
            this.modal9 = false;
            this.formInline.code = "";
            this.formInline.fundpwd = "";
            this.selfamount = resp.data.accountBalance
            // this.transaction.page = 0;
            // this.getList();
            this.clearValues();
            this.$Message.success(resp.mesg);
          } else {
            this.$Message.error(resp.mesg);
          }
        });
    },

getAddress() {
      //获取个人安全信息
      var self = this;
      // var params = {
      //     };
         
      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
         
      this.$http
        .post(self.host + this.api.uc.wallet)
        .then(response => {
          var resp = response.body;
          if (resp.length > 0) {
            // self.inputAddress = resp[0].walletAddress
          } else {
            if (resp.mesg) {
              self.$Message.error(resp.mesg);
            }
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

    getAddrList1() {
  //     if (this.$store.getters.member.pwdStatus == '0') {
  //         this.$Message.error(this.$t('uc.forget.emailcodetip1'));
  //         return
  // }
      let params = {};
      
      params["amount"] = this.withdrawOutAmount
      params["ccy"] = "USDT"
      params["userNo"] = this.inputAddress
      // BigDecimal amount String userNo  String ccy 
      this.$http
        .post(this.host + this.api.uc.checkuser, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            if (resp.data.id != null && resp.data.userType != null) {
              let member = this.$store.getters.member
              if (member.userType == '1' || resp.data.userType == '1') {
                this.modal10 = true;
              } else {
                this.$Message.error(this.$t('uc.login.normaluser'))
              }
              
          //  this.$Message.success(resp.mesg)
            }else
            this.$Message.error(this.$t('uc.login.nouser'))
          } else {
            this.$Message.error(resp.mesg);
          }
        });
    },
    // initwithdraw
    getAddrList(pass) {
  //     if (this.$store.getters.member.pwdStatus == '0') {
  //         this.$Message.error(this.$t('uc.forget.emailcodetip1'));
  //         return
  // }

//   amount (number, optional): 转账金额 ,
// ccy (string, optional): 币种 ,
// pass (string, optional): 交易密码 ,
// payeeUserNo (string, optional): 用户编号
      let params = {};
      let that = this
      params["amount"] = this.withdrawOutAmount
      params["ccy"] = 'USDT'
      params["payeeUserNo"] = this.inputAddress
      params['pass'] = pass
this.eloading = true
      // BigDecimal amount String userNo  String ccy 
      this.$http
        .post(this.host + this.api.uc.exchangecard, params)
        .then(response => {
          var resp = response.body;
          that.eloading = false
          if (resp.code == "0000") {
            that.model21 = resp.data
            that.modal10 = false
            that.modal9 = false
            that.modal2 = false
            that.clearValues();
            that.getMember()
            that.$Message.success(resp.mesg)
          } else {
            that.$Message.error(resp.mesg);
          }
        });
    },
  
    accSub(arg1, arg2) {
      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      //last modify by deeka
      //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    round(v, e) {
      var t = 1;
      for (; e > 0; t *= 10, e--);
      for (; e < 0; t /= 10, e++);
      return Math.round(v * t) / t;
    },
    computerAmount() {
      this.withdrawOutAmount = this.round(
        this.accSub(this.withdrawAmount, this.withdrawFee),
        this.currentCoin.withdrawScale
      );
    },
    computerAmount2() {
      this.withdrawAmount =
        (this.withdrawAmount + "").replace(/([0-9]+\.[0-9]{6})[0-9]*/, "$1") -
        0;
      this.withdrawOutAmount = this.round(
        this.accSub(this.withdrawAmount, this.withdrawFee),
        this.currentCoin.withdrawScale
      );
    },
    getList() {
      //获取tableRecharge
      this.loading = true;
      let memberId = 0;
      !this.$store.getters.isLogin && this.$router.push("/login");
      this.$store.getters.isLogin && (memberId = this.$store.getters.member.id);
      console.log("hahah"+ memberId)
      this.$http.post(this.host + this.api.uc.paydividends).then(response => {
          var resp = response.body;
          if (resp.code == '0000') {
            if (resp.data) {
            
              console.log('xwe')
              this.coinList = resp.data
              // resp.data.forEach(element => {
              //   if (element.acctName == 'USDT') {
              //     this.panel1 = element
              //   } else {
              //     this.panel2 = element
              //   }
              // });
            }
            this.loading = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
    },
    valid() {
      this.withdrawAdress = this.withdrawAdress || this.inputAddress;
      if (this.coinType == "") {
        this.$Message.error(this.$t("uc.finance.withdraw.symboltip"));
        return false;
      } else if (this.withdrawAdress == "") {
        this.$Message.error(this.$t("uc.finance.withdraw.addresstip"));
        return false;
      } else if (
        this.withdrawAmount == "" ||
        this.withdrawAmount == 0 ||
        this.withdrawAmount - 0 < this.currentCoin.minAmount
      ) {
        this.$Message.error(
          this.$t("uc.finance.withdraw.numtip2") + this.currentCoin.minAmount
        );
        return false;
      } else if (this.withdrawAmount - 0 < this.withdrawFee) {
        this.$Message.error(this.$t("uc.finance.withdraw.numtip3"));
        return false;
      } else if (
        this.withdrawFee == "" ||
        this.withdrawFee == 0 ||
        this.withdrawFee - 0 > this.currentCoin.maxTxFee ||
        this.withdrawFee - 0 < this.currentCoin.minTxFee
      ) {
        this.$Message.error(
          this.$t("uc.finance.withdraw.feetip1") +
            this.currentCoin.minTxFee +
            " , " +
            this.$t("uc.finance.withdraw.feetip2") +
            this.currentCoin.maxTxFee
        );
        return false;
      } else {
        return true;
      }
    },
    apply() {
      if (this.valid()) {
        this.modal = true;
        let timercode = setInterval(() => {
          if (this.countdown <= 0) {
            clearInterval(timercode);
            this.sendcodeValue = this.$t("uc.regist.sendcode");
            this.codeIsSending = false;
          }
        }, 1000);
      }
    },

firstModel(){
   
  //  this.getAddrList1();
   this.modal10 = true;
},
    
    getMoneyback(){
//  var self = this;
//     this.$http
//         .post(this.host + this.api.uc.apply)
//         .then(response => {
//           var resp = response.body;
//           if (resp.code == "0000") {
//             this.getMember();
//           } else {
//             self.$Message.error(resp.mesg);
//           }
//         });
  },
  },
  created() {
    this.getMember();
    this.$http.options.emulateJSON = false;
    // this.coinType = this.$route.query.name || "";
    // this.getAddrList();
    // this.getList();
    this.getAddress()
    this.getMoneyback();
    // console.log(this.$store.getters.member);
  },
  computed: {
    member: function() {
      console.log(this.$store.getters.member);
      return this.$store.getters.member;
    },
    tableColumnsWithdraw() {
      let columns = [],
        filters = [];
      if (this.coinList.length > 0) {
        this.coinList.forEach(v => {
          filters.push({
            label: v.unit,
            value: v.unit
          });
        });
      }
      columns.push({
        title: this.$t("uc.finance.withdraw.time"),
        width: 180,
        key: "createTime"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.symbol"),
        key: "symbol",
        filters,
        filterMultiple: false,
        filterMethod(value, row) {
          return row.coin.unit === value;
        },
        render: function(h, params) {
          return h("span", params.row.coin.unit);
        }
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.address"),
        key: "address"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.num"),
        key: "totalAmount"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.fee"),
        key: "fee"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.txid"),
        key: "transactionNumber"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.status"),
        key: "status",
        render: (h, params) => {
          let text = "";
          if (params.row.status == 0) {
            text = this.$t("uc.finance.withdraw.status_1");
          } else if (params.row.status == 1) {
            text = this.$t("uc.finance.withdraw.status_2");
          } else if (params.row.status == 2) {
            text = this.$t("uc.finance.withdraw.status_3");
          } else if (params.row.status == 3) {
            text = this.$t("uc.finance.withdraw.status_4");
          }
          return h("div", [h("p", text)]);
        }
      });
      return columns;
    }
  }
};
</script>
<style lang="scss">
.withdraw-form-inline {
  padding: 20px 40px 0 40px;
  .ivu-input {
    height: 40px;
    line-height: 40px;
  }
}

.vertical-center-modal{
        display: flex;
        align-items: center;
        justify-content: center;

        .ivu-modal{
            top: 0;
        }
    }
</style>

<style scoped lang="scss">
.ivu-slider-wrap{
	top: 75px !important;
	z-index: 999 !important;
	width: 100% !important;
}

/deep/ .ivu-input {
  background: transparent;
  border: 0;
  border-radius: 25px;

  color: #898989;
}

/deep/ .ivu-input-wrapper {
  border: 0.1ch;
}


.btnSub{
  width:75%; 
  height: 40px; 
  font-size: 14px; 
  border: none;  
  color: #553a25; 
  font-weight: 600;
  border-radius: 25px;
  background: url(../assets/me/logout-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  letter-spacing: 2px;
}

.celllall{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}

.celllall1{
  width: 100%;
  height: 40px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  align-items: flex-start;
  // background: url(../assets/netmage/qqqy.png);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  font-size: 18px;
  margin-top: 50px;
  
}

.headerinfo{
  width: 100%;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
 
}


.withdraw{
   background: #000;
  background-size: cover;
  height: 800px;
  position: relative;
  overflow: hidden;
}
.celllall2 {
  width: 100%;
 
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 color: #a2a2a1;
 font-size: 18px;
 margin-top: 20px;
}
#sendCode {
  position: absolute;
  border: none;
  background: none;
  top: 10px;
  outline: none;
  right: 0;
  width: 30%;
  color: #f0ac19;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  border-left: 1px solid #dddee1;
}
  .rightarea {
  width: 100%;
  height: auto;
 
  margin-top: 100px;
  padding: 20px;
    }
.nav-rights {
  .nav-right {
    height: auto;
    overflow: hidden;
    padding: 0 15px;
  
  }
}
.ivu-slider-button-wrap {
  top: -6px;
}
#withdrawAddressList {
  position: absolute;
  height: 0;
  transition: height 0.3s;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 245px;
  overflow: auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  background: #fff;
}

.rightarea /deep/ .ivu-select-selection {
   
    background-color: #e5b932;
    border-radius: 15px;
    color: #000;
    font-weight: bolder;
    
}

#withdrawAddressList .address-item {
  padding: 0 20px;
  display: flex;
  line-height: 48px;
  border-bottom: 1px solid transparent;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  z-index: 99;
}

#withdrawAddressList .address-item:hover {
  background: #f5f5f5;
  cursor: pointer;
}

#withdrawAddressList .notes {
  position: absolute;
  bottom: 0;
  right: 20px;
  height: 48px;
  line-height: 48px;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

p.describe {
  font-size: 16px;
  font-weight: 600;
}

.acb-p1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
}

.acb-p2 {
  font-size: 13px;
  line-height: 24px;
  color: #8c979f;
}

.action-content.pt10 {
  padding-top: 10px;
}

.action-content {
  width: 100%;
  margin-top: 20px;
  // overflow: hidden;
  display: table;
}

.action-content .action-body {
  display: table-cell;
  vertical-align: top;
  line-height: 20px;
  font-size: 12px;
  color: #ccc;
}

.action-foot {
  text-align: center;
  padding: 40px 170px 0;
}

.hb-night .btn.btn-primary,
.hb-night .btn.btn_submit {
  background-color: #7a98f7;
  color: white;
}

.action-inner {
  width: 100%;
  display: table;
}

.action-inner .inner-box {
  display: table-cell;
  width: 80%;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
}

.controlAddress {
  line-height: 50px;
}

.form-group label {
  max-width: 100%;
  font-weight: 600;
}

.control-input-group {
  position: relative;
}

.control-input-group.open .select-list {
  height: auto;
}

.form-group-container {
  display: table;
  width: 100%;
}

.form-group-container .form-amount {
  width: 100%;
}

.form-group-container .form-group {
  display: table-cell;
}

.form-group-container .form-group span.addon-tag:last-child {
  padding: 0;
  border: none;
  background: none;
  cursor: default;
  position: absolute;
  right: 26px;
  top: 6px;
}

.form-group-container .form-group span.addon-tag:last-child.firstt {
  top: 8px;
}

.form-group-container2 {
  padding-top: 20px;
}

.form-group-container .form-fee {
  width: 50%;
  padding: 0 20px 0 0;
}

.label-amount .label-fr {
  float: right;
  color: #aaa;
  font-size: 14px;
}

.label-amount .label-fr span {
  margin-left: 2px;
}

.form-group-container .form-group {
  display: table-cell;
}

.hb-night table.table .table-inner {
  margin: -4px -20px;
  position: relative;
  background-color: #181b2a;
  border-radius: 3px;
}

.hb-night table.table .table-inner {
  margin: -4px -20px;
  position: relative;
  background-color: #181b2a;
  border-radius: 3px;
}

.hb-night table.table .table-inner {
  margin: -4px -20px;
  position: relative;
  background-color: #181b2a;
  border-radius: 3px;
}

table.table .table-inner.action-box {
  margin: -1px -10px;
}

.merchant-top .tips-word {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  text-align: left;
}

// .rightarea .rightarea-tabs {
//   border: none;
// }

.order_box {
  width: 100%;
  background: #fff;
  height: 56px;
  line-height: 56px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccf2ff;
  position: relative;
  text-align: left;
}

.order_box a {
  color: #8994a3;
  font-size: 16px;
  padding: 0 30px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 54px;
  display: inline-block;
}

.order_box .active {
  border-bottom: 2px solid #f0a70a;
}

.order_box .search {
  position: absolute;
  width: 300px;
  height: 32px;
  top: 12px;
  right: 0;
  display: flex;
  /* border: #c5cdd7 solid 1px; */
}

.ivu-btn-primary {
  background-color: #f0a70a;
  border-color: #f0a70a;
}
#pages {
  margin: 10px;
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.nav-rights {
  .nav-right {
    // .rightarea {
    //   // .action-box {
    //   //   .action-inner {
    //   //     .inner-left,
    //   //     .inner-box {
    //   //       .ivu-select-dropdown .ivu-select-item {
    //   //         padding: 6px 16px;
    //   //       }
    //   //     }
    //   //   }
    //   //   // .form-group-container {
    //   //   //   .form-group {
    //   //   //     .input-group {
    //   //   //       .ivu-poptip-rel {
    //   //   //         display: block;
    //   //   //         .ivu-input-number {
    //   //   //           width: 100%;
    //   //   //         }
    //   //   //       }
    //   //   //       .ivu-input-number {
    //   //   //         width: 100%;
    //   //   //       }
    //   //   //     }
    //   //   //   }
    //   //   // }
    //   // }
    // }
    .table-inner.action-box {
      .action-content .action-body {
        /*分页*/
        .order-table .ivu-table-wrapper .ivu-table-header {
          thead .ivu-table-cell {
            .ivu-poptip .ivu-poptip-rel .ivu-table-filter {
              i.ivu-icon.ivu-icon-funnel.on {
                color: #f0ac19;
              }
            }
          }
        }
      }
    }
  }
}
</style>

