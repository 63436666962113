<template>
  <div class="login_form">
    <div class="headBox">
      <span>{{ }}</span>
      <span style="color: #e4c87b; font-size: 20px;">
        {{ $t("uc.finance.pickup") }}
      </span>
      <!-- <span>{{ }}</span> -->
      <span>{{ }}</span>
    </div>
    <!-- <div class="celllall1">
      <div class="headerinfo">
        <span style="font-size: 20px; color: #a2a2a1; margin-left: 10px">{{ $t("uc.finance.tradetail") }}</span>
        <div style="margin-left: 10%; margin-top: 0px; display: flex; align-items: center; color: #eae8df">
          <img src="../assets/newImage/coin.png" alt="" style="width: 25px; margin-right: 5px" />{{ usdtAmount }}
        </div>
      </div>

    </div> -->

    <div class="bill_box">
      <!-- <Select v-model="model1" style="width:80%;margin-top: 30px;color: #000;">
            <Option v-for="item in coinList" :value="item.bankName" :key="item.id">{{item.acctName}}</Option>
          </Select> -->
      <Tabs type="card" @on-click="switchAddress">
        <TabPane v-for="(tab, index) in tabList" :key="index" :label="tab.label" :name="tab.name">
          <section style="margin-top: 10px" v-if="tab.name == 'USDT'">
            <div style="color: #eae8df;margin-left: 10px;color: #907633;  width: 100%;text-align: left;">* 网络:TRC20</div> 

            <div class="celllall2">
              <!-- <span style="margin-left: 20px">{{ $t("uc.forget.walletaddress") }}</span> -->
              <Input
                v-model="inputAddress"
                disabled
                type="text"
                size="large"
                style="color: #e4c87b;border-radius: 10px; margin-top: -10px; border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  "
                clearable
                :placeholder="$t('uc.forget.addresstip')"
              />
            </div>
            <div class="celllall2">
              <!-- <span style="margin-left: 20px">{{ $t("uc.finance.invitingmining") }}</span> -->
              <!-- <input type="text" @change="onKeyYHKNameInput" v-model="withdrawAmount" style="background: #000;color: #898989; width:80%;padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px;border-style:ridge;margin-left: 20px;" clearable  :placeholder="$t('uc.finance.invitingmining')"> -->
              <Input
                v-model="withdrawAmount"
                type="text"
                @on-change="onKeyYHKNameInput"
                size="large" style="color: #e4c87b;border-radius: 10px;  border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  "
                clearable
                :placeholder="$t('uc.finance.invitingmining')"
              />
              <!-- <div style="margin-left: 10%;margin-top: 10px;display: flex; align-items: center; color: #f0ac19;">
        $$   ≈≈ {{ exchangeAmount }}
          </div> -->
            </div>

           
      
        <div style="width: 100%; text-align:right; color: #907633; padding: 10px;">
         余额 : {{ usdtAmount }}
        </div>

           
            <div style="margin-top: 80px">
              <Button
                class="btnSub"
                @click="firstModel"
                >{{ $t("uc.forget.save") }}</Button
              >
            </div>
<!-- 
            <div style="margin-top: 20px; margin-bottom: 20px">
              <span style="font-size: 12px; color: #f60">{{ $t("uc.finance.notice") }}</span>
            </div>
            <div>
              <span style="font-size: 12px; color: #f60">{{ $t("uc.finance.transfee") }} : 1 USDT</span>
            </div>
            <div>
              <span style="font-size: 12px; color: #f60"> {{ $t("uc.finance.notice34") }}</span>
            </div> -->
          </section>
          <section v-else-if="tab.name == 'HUIONE'" style="margin-top: 10px;">
            <div style="color: #eae8df;margin-left: 10px;color: #907633;  width: 100%;text-align: left;">* 提现信息</div>

            <div class="celllall2">
              <!-- <span style="margin-left: 20px">{{ $t("uc.forget.huioneaddress") }}</span> -->
              <Input
                v-model="inputAddress1"
                type="text"
                disabled
                size="large" style="color: #e4c87b;border-radius: 10px; margin-top: -10px; border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  "
                clearable
                :placeholder="$t('uc.forget.addresstip221')"
              />
            </div>

            <div class="celllall2">
              <!-- <span style="margin-left: 20px">{{ $t("uc.forget.addresstip311") }}</span> -->
              <Input
                v-model="payname"
                type="text"
                disabled
                size="large" style="color: #e4c87b;border-radius: 10px;  border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  "
                clearable
                :placeholder="$t('uc.forget.addresstip321')"
              />
            </div>

            <div class="celllall2">
              <!-- <span style="margin-left: 20px">{{ $t("uc.finance.invitingmining") }}</span> -->
              <!-- <input type="text" @change="onKeyYHKNameInput" v-model="withdrawAmount" style="background: #000;color: #898989; width:80%;padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px;border-style:ridge;margin-left: 20px;" clearable  :placeholder="$t('uc.finance.invitingmining')"> -->
              <Input
                v-model="withdrawAmount1"
                type="text"
                @on-change="onKeyYHKNameInput1"
                size="large" style="color: #e4c87b;border-radius: 10px;  border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  "
                clearable
                :placeholder="$t('uc.finance.invitingmining')"
              />
              <!-- <div style="margin-left: 10%;margin-top: 10px;display: flex; align-items: center; color: #f0ac19;">
        $$   ≈≈ {{ exchangeAmount }}
          </div> -->
            </div>
            <div style="width: 100%; text-align:right; color: #907633; padding: 10px;">
         余额 : {{ usdtAmount }}
        </div>
            <div style="margin-top: 80px">
              <Button
              class="btnSub"
                @click="firstModel1"
                >{{ $t("uc.forget.save") }}</Button
              >
            </div>

            <div style="color: #907633;  width: 100%;text-align: center;margin-top: 40px;padding: 10px;letter-spacing: 1px;">
如遇长时间未到账，请联系客服
                      </div>

           <!--   <div style="margin-top: 20px; margin-bottom: 20px">
              <span style="font-size: 12px; color: #f60">{{ $t("uc.finance.notice34") }}</span>
            </div> -->
          </section>

          <section v-else-if="tab.name == 'chinaBank'">
            <div class="celllall2">
              <span style="margin-left: 20px">
                {{ $t("uc.forget.bankaddress") }}
              </span>
              <Input
                v-model="inputAddress2"
                type="text"
                disabled
                style="
                  color: #898989;
                  border-radius: 25px;
                  border-color: #eae8df;
                  border-inline-width: 1px;
                  border-style: ridge;
                  width: 80%;
                  margin-left: 20px;
                "
                clearable
                :placeholder="$t('uc.forget.addresstip421')"
              />
            </div>

            <div class="celllall2">
              <span style="margin-left: 20px">{{ $t("uc.forget.addresstip3114") }}</span>
              <Input
                v-model="payname1"
                type="text"
                disabled
                style="
                  color: #898989;
                  border-radius: 25px;
                  border-color: #eae8df;
                  border-inline-width: 1px;
                  border-style: ridge;
                  width: 80%;
                  margin-left: 20px;
                "
                clearable
                :placeholder="$t('uc.forget.addresstip321')"
              />
            </div>

            <div class="celllall2">
              <span style="margin-left: 20px">{{ $t("uc.forget.addresstip3112") }}</span>
              <Input
                v-model="inputAddress21"
                type="text"
                disabled
                style="
                  color: #898989;
                  border-radius: 25px;
                  border-color: #eae8df;
                  border-inline-width: 1px;
                  border-style: ridge;
                  width: 80%;
                  margin-left: 20px;
                "
                clearable
                :placeholder="$t('uc.forget.addresstip3215')"
              />
            </div>

            <div class="celllall2">
              <span style="margin-left: 20px">{{ $t("uc.forget.addresstip3213") }}</span>
              <Input
                v-model="inputAddress22"
                type="text"
                disabled
                style="
                  color: #898989;
                  border-radius: 25px;
                  border-color: #eae8df;
                  border-inline-width: 1px;
                  border-style: ridge;
                  width: 80%;
                  margin-left: 20px;
                "
                clearable
                :placeholder="$t('uc.forget.addresstip3213')"
              />
            </div>

            <div class="celllall2">
              <span style="margin-left: 20px">{{ $t("uc.finance.invitingmining") }}</span>
              <!-- <input type="text" @change="onKeyYHKNameInput" v-model="withdrawAmount" style="background: #000;color: #898989; width:80%;padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px;border-style:ridge;margin-left: 20px;" clearable  :placeholder="$t('uc.finance.invitingmining')"> -->
              <Input
                v-model="withdrawAmount2"
                type="text"
                @on-change="onKeyYHKNameInput12"
                style="
                  color: #898989;
                  border-radius: 25px;
                  border-color: #eae8df;
                  border-inline-width: 1px;
                  border-style: ridge;
                  width: 80%;
                  margin-left: 20px;
                "
                clearable
                :placeholder="$t('uc.finance.invitingmining')"
              />
            </div>
            <div style="margin-top: 80px">
              <Button
                style="
                  width: 40%;
                  height: 40px;
                  background-color: #e5b932;
                  font-size: medium;
                  border: none;
                  color: #434240;
                  font-weight: 600;
                "
                @click="firstModel2"
                >{{ $t("uc.forget.save") }}</Button
              >
            </div>

            <div style="margin-top: 20px; margin-bottom: 20px">
              <span style="font-size: 12px; color: #f60">{{ $t("uc.finance.notice1111") }}</span>
              <Button type="text" style="color: rgb(255, 0, 255); margin-left: -15px" size="large" @click="gotoBank">{{
                $t("uc.finance.notice11111")
              }}</Button>
              <br />
              <span style="font-size: 12px; color: #f60">{{ $t("uc.finance.notice11") }}</span>
              <Button type="text" style="color: blue; margin-left: -15px" size="large" @click="gotoService">{{
                $t("uc.finance.notice111")
              }}</Button>
            </div>
          </section>
        </TabPane>
      </Tabs>
    </div>

    <Modal v-model="modal2" width="450">
      <p slot="header" style="color: #f60; text-align: center">
        <span>{{ $t("uc.forget.emailcodetip1") }}</span>
      </p>
      <Form class="withdraw-form-inline1" ref="formInline" :model="formInline">
        <FormItem>
          <Input
            type="password"
            password
            v-model="formInline.fundpwd"
            :placeholder="$t('uc.forget.emailcodetip')"
          ></Input>
        </FormItem>
        <FormItem>
          <Input
            type="password"
            password
            v-model="formInline.refundpwd"
            :placeholder="$t('uc.forget.confirmpwd')"
          ></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" size="large" long @click="submitCode">{{ $t("uc.forget.save") }}</Button>
      </div>
    </Modal>

    <Modal v-model="modal22" width="450">
      <p slot="header" style="color: #f60; text-align: center">
        <span>{{ $t("uc.forget.emailcodetip11") }}</span>
      </p>
      <Form class="withdraw-form-inline1" inline>
        <FormItem>
          <Input type="text" clearable v-model="inputAddress" :placeholder="$t('uc.forget.emailcodetip11')"></Input>
        </FormItem>
        <div style="margin-top: 20px">
          <span style="font-size: 12px; color: #f60">{{ $t("uc.finance.notice12") }}</span>
        </div>
      </Form>
      <div slot="footer">
        <Button type="primary" size="large" long @click="addAddress">{{ $t("uc.forget.save") }}</Button>
      </div>
    </Modal>

    <Modal  v-model="modal9" width="450">
      <!-- <P style="color:red;font-weight: bold;">
        {{$t('uc.finance.withdraw.fundpwdtip')}}<br/>
        <Input type="password" v-model="fundpwd" :placeholder="$t('otc.chat.msg7')"></Input>
      </p> -->
      <p slot="header">
        {{ $t("uc.forget.emailcodetip") }}
      </p>
      <Form class="withdraw-form-inline" ref="formInline" :model="formInline" inline>
        <FormItem>
          <Input
            type="password"
            password
            v-model="formInline.fundpwd"
            :placeholder="$t('uc.forget.emailcodetip')"
            ref="passwordinput"
            style="width: 300px;margin-left: -20px;"
          ></Input>
        </FormItem>
      </Form>

      <div slot="footer">
        <span style="margin-right: 50px" @click="cancel">{{ $t("common.close") }}</span>
        <span
          style="
            background: #f0ac19;
            color: #fff;
            width: 80px;
            border-radius: 30px;
            display: inline-block;
            text-align: center;
            height: 30px;
            line-height: 30px;
          "
          @click="checkpp"
          >{{ $t("common.ok") }}</span
        >
      </div>
    </Modal>

    <Modal v-model="modal10" width="450">
      <!-- <P style="color:red;font-weight: bold;">
        {{$t('uc.finance.withdraw.fundpwdtip')}}<br/>
        <Input type="password" v-model="fundpwd" :placeholder="$t('otc.chat.msg7')"></Input>
      </p> -->
      <p slot="header">
        {{ $t("common.tibifirst") }}
      </p>
      <p>{{ $t("uc.forget.walletaddress1") }} : {{ inputAddress }}</p>
      <br />
      <p>{{ $t("uc.forget.walletaddress2") }} : {{ withdrawOutAmount - 1 }}</p>
      <div slot="footer">
        <span style="margin-right: 50px" @click="cancel">{{ $t("common.close") }}</span>
        <span
          style="
            background: #f0ac19;
            color: #fff;
            width: 80px;
            border-radius: 30px;
            display: inline-block;
            text-align: center;
            height: 30px;
            line-height: 30px;
          "
          @click="okfirst"
          >{{ $t("common.ok") }}</span
        >
      </div>
    </Modal>

    <Modal v-model="modal11" width="450">
      <!-- <P style="color:red;font-weight: bold;">
        {{$t('uc.finance.withdraw.fundpwdtip')}}<br/>
        <Input type="password" v-model="fundpwd" :placeholder="$t('otc.chat.msg7')"></Input>
      </p> -->
      <p slot="header">
        {{ $t("common.tibifirst") }}
      </p>
      <p>{{ $t("uc.forget.walletaddress1") }} : {{ inputAddress1 }}</p>
      <br />
      <p>{{ $t("uc.forget.walletaddress2") }} : {{ withdrawAmount1 }}</p>
      <div slot="footer">
        <span style="margin-right: 50px" @click="cancel">{{ $t("common.close") }}</span>
        <span
          style="
            background: #f0ac19;
            color: #fff;
            width: 80px;
            border-radius: 30px;
            display: inline-block;
            text-align: center;
            height: 30px;
            line-height: 30px;
          "
          @click="okfirst"
          >{{ $t("common.ok") }}</span
        >
      </div>
    </Modal>


    <Modal
        title="设置提款地址"
        v-model="modal8"
        :closable="false"
        :mask-closable="false"
        @on-ok="gotoSet"
       @on-cancel='gotoHome'
        
       >
       <div style="text-align:center">
            <p>请先设置提款地址，否则无法完成提款</p>
        </div>
    </Modal>

    <Modal
        title="设置资金密码"
        v-model="modal7"
        :closable="false"
        :mask-closable="false"
        @on-ok="gotoPassword"
       @on-cancel='gotoHome'
        
       >
       <div style="text-align:center">
            <p>为了保证客户资金安全，请先设置资金密码</p>
        </div>
    </Modal>

    <div
      v-if="loading == true"
      v-load="true"
      load-background="rgba(0, 0, 0, .7)"
      load-type="cube-grid"
      style="height: 200vh; width: 100%"
    ></div>
    <div class="demo-tab-bar">
      <Main-tab-bar></Main-tab-bar>
    </div>
  </div>
</template>

<script>
import MainTabBar from "../components/Tabbar/MainTabBar"

export default {
  components: {
    MainTabBar,
  },
  data() {
    return {
      user: {},
      codeIsSending: false,
      sendcodeValue: this.$t("uc.regist.sendcode"),
      countdown: 60,
      formInline: {
        code: "",
        fundpwd: "",
        refundpwd: "",
      },
      exchangeAmount: 0,
      modal11: false,
      inputAddress1: "",
      inputAddress2: "",
      inputAddress21: "",
      withdrawAmount1: '0',
      withdrawAmount2: 0,
      value14: "",
      modal9: false,
      modal10: false,
      modal: false,
      modal2: false,
      modal22: false,
      modal7: false,
      modal8: false,
      fundpwd: "",
      currentCoin: {},
      transaction: {
        page: 0,
        pageSize: 10,
        total: 0,
      },
      haveUdun: false,
      haveHuione: false,
      tabList: [
        // {
        //     label: '银行卡提现',
        //     name: 'chinaBank'
        // },
        {
          label: "汇旺提现",
          name: "HUIONE",
        },
        {
          label: "USDT提现",
          name: "USDT",
        },
      ],
      loading: false,
      withdrawAdress: "",
      inputAddress: "", //用户输入的地址
      withdrawAmount: '0',
      withdrawFee: 0,
      withdrawOutAmount: 0,
      coinType: "",
      coinList: [],
      tableWithdraw: [],
      allTableWithdraw: [],
      withdrawAddressArray: [],
      model1: "HUIONE",
      moneybab: "",
      model21: "",
      usdtAmount: "",
      usdAmount: '',
      tempmember: "",
      payname: "",
      payname1: "",
      currentIndex: "",
      inputAddress22: "",
    }
  },
  watch: {
    currentCoin: function () {
      this.withdrawFee = this.currentCoin.minTxFee + (this.currentCoin.maxTxFee - this.currentCoin.minTxFee) / 2
    },
  },
  methods: {
    gotoBank() {
      this.$router.push("/cart")
    },
    gotoService() {
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push("/customservice")
    },

    gotoPassword(){
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push('/set')
    },

    exchange(){

this.$router.push("/exchange");

},

    gotoSet(){
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push('/cart')
    },

    gotoHome(){
      this.$router.replace({name: "About"});
    },
    addAddress() {
      //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
      //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）
      var self = this
      var params = {}

      params["addressChannel"] = 0
      params["walletAddress"] = this.inputAddress
      params["ccy"] = "USDT"
      params["ccySymbol"] = "TRC20"

      this.$http.post(self.host + this.api.uc.identification, params).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          // self.user = resp.data;
          // self.usernameS = this.user.username.slice(0,1);
          // self.tableWithdraw = resp.data

          // self.tableWithdraw.forEach(element => {
          //   if (element.ccy == 'USDT') {

          //     self.usdtAmount = element.amount

          //   }
          // });
          // if (this.isTg) {
          //   this.changeCoin()
          //   this.sliderSellMarketPercent = Number(this.$store.getters.amount)
          // }
          self.$Message.success(resp.mesg)
        } else {
          self.$Message.error(resp.mesg)
          // this.$Message.error(this.$t('common.logintip'));
        }
      })
    },
    okfirst() {
      // this.getMemberInfo()

      if (this.tempmember.jbPassword) {
        this.getAddrList()
      } else {
        // this.$Message.error(this.$t('uc.forget.emailcodetip1'));
        this.modal2 = true
      }
    },
    onKeyYHKNameInput() {
      let value = "" + this.withdrawAmount
      let value1 = 0
      // console.log(this.body.ccyScale,"币种精度")
      let data = 2 //币种精度
      value = value
        .replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), "$1$2.$3") // 只能输入data个小数
      if (value.indexOf(".") < 0 && value != "") {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value)
        value1 = Math.round(value / 7)
      }
      this.$nextTick(function () {
        this.withdrawOutAmount = value
        this.withdrawAmount = Number(value).toLocaleString()
        this.exchangeAmount = Number(value1).toLocaleString()
      })
    },
    onKeyYHKNameInput1() {
      let value = "" + this.withdrawAmount1
      let value1 = 0
      // console.log(this.body.ccyScale,"币种精度")
      let data = 2 //币种精度
      value = value
        .replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), "$1$2.$3") // 只能输入data个小数
      if (value.indexOf(".") < 0 && value != "") {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value)
        value1 = Math.round(value / 7)
      }
      this.$nextTick(function () {
        this.withdrawOutAmount = value
        this.withdrawAmount1 = Number(value).toLocaleString()
        this.exchangeAmount = Number(value1).toLocaleString()
      })
    },
    onKeyYHKNameInput12() {
      let value = "" + this.withdrawAmount2
      // console.log(this.body.ccyScale,"币种精度")
      let data = 2 //币种精度
      value = value
        .replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), "$1$2.$3") // 只能输入data个小数
      if (value.indexOf(".") < 0 && value != "") {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value)
      }
      this.$nextTick(function () {
        this.withdrawOutAmount = value
        this.withdrawAmount2 = Number(value).toLocaleString()
      })
    },
    cancel() {
      this.modal9 = false
      this.modal10 = false
      this.formInline.code = ""
      this.formInline.fundpwd = ""
    },
    sendCode() {
      this.$http.post(this.host + "/uc/mobile/withdraw/code").then((response) => {
        var resp = response.body
        if (resp.code == 0) {
          this.settime()
          this.$Notice.success({
            title: this.$t("common.tip"),
            desc: resp.message,
          })
        } else {
          this.$Notice.error({
            title: this.$t("common.tip"),
            desc: resp.message,
          })
        }
      })
    },
    settime() {
      this.sendcodeValue = this.countdown
      this.codeIsSending = true
      let timercode = setInterval(() => {
        this.countdown--
        this.sendcodeValue = this.countdown
        if (this.countdown <= 0) {
          clearInterval(timercode)
          this.sendcodeValue = this.$t("uc.regist.sendcode")
          this.countdown = 60
          this.codeIsSending = false
        }
      }, 1000)
    },
    changePage(index) {
      this.transaction.page = index - 1
      this.getList()
    },
    onAddressChange(data) {
      this.inputAddress = data
    },
    clearValues() {
      if (this.$refs.address) {
        this.$refs.address.setQuery(" ")
      }
      this.withdrawAdress = ""
      this.withdrawAmount = '0'
      this.withdrawAmount1 = '0'
      // this.withdrawFee= 0;
      this.withdrawOutAmount = 0
    },
    getCurrentCoinRecharge() {
      if (this.coinType != "") {
        var temp = []
        for (var i = 0; i < this.allTableWithdraw.length; i++) {
          //   if (this.allTableWithdraw[i].symbol == this.coinType) {
          if (this.allTableWithdraw[i].coin.unit == this.coinType) {
            temp.push(this.allTableWithdraw[i])
          }
        }
        this.tableWithdraw = temp
      } else {
        this.tableWithdraw = this.allTableWithdraw
      }
    },

    getMemberInfo() {
      //获取个人安全信息
      var self = this

      this.$http.post(self.host + this.api.uc.memberInfo).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          self.tempmember = resp.data
          if (resp.data.jbPassword) {
            self.modal7 = false
          } else {
            // this.$Message.error(this.$t('uc.forget.emailcodetip1'));
            self.modal7 = true
          }
        } else {
          self.$Message.error(resp.mesg)
        }
      })
    },
    getMemberInfo1() {
      //获取个人安全信息
      var self = this

      this.$http.post(self.host + this.api.uc.memberInfo).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          self.tempmember = resp.data
          if (resp.data.jbPassword) {
            self.modal9 = true
            self.$nextTick(() => {
              self.$refs.passwordinput.focus()
              // document.querySelector('password').focus()

            }

            )
          } else {
            // this.$Message.error(this.$t('uc.forget.emailcodetip1'));
            self.modal2 = true
          }
        } else {
          self.$Message.error(resp.mesg)
        }
      })
    },
    getMember() {
      //获取个人安全信息
      var self = this
      // var params = {
      //     };

      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0

      this.$http.post(self.host + this.api.uc.personalWallet).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          // self.user = resp.data;
          // self.usernameS = this.user.username.slice(0,1);
          self.tableWithdraw = resp.data

          self.tableWithdraw.forEach((element) => {
            if (element.ccy == "USDT") {
              self.usdtAmount = element.amount
            }
          })
        } else {
          self.$Message.error(resp.mesg)
          // this.$Message.error(this.$t('common.logintip'));
        }
      })
    },

    checkpp() {
      // let params = {};

      // params['password']= this.formInline.fundpwd
      // this.$http
      //         .post(this.host + this.api.uc.mylistrecord, params)
      //         .then(response => {
      //           this.fundpwd = "";
      //           var resp = response.body;
      //           if (resp.code == "0000") {

      //             // this.$Message.success(resp.mesg);
      //             if (resp.data == true) {
      if (this.model1 == "HUIONE") {
        this.huioneSubmit(this.formInline.fundpwd)
      } else this.ok(this.formInline.fundpwd)
      //             }else
      //              this.$Notice.error({
      //                     title: this.$t("common.tip"),
      //                     desc: this.$t("uc.regist.emailerr")
      //                   });

      //           } else {
      //             this.$Message.error(resp.mesg);
      //           }
      //         });
    },

    submitCode() {
      let that = this
      if (this.formInline.fundpwd != this.formInline.refundpwd) {
        this.$Notice.error({
          title: this.$t("common.tip"),
          desc: this.$t("uc.forget.pwdvalidate2"),
        })
        return
      }

      let params = {}

      params["jbPassword"] = this.formInline.fundpwd
      this.$http.post(this.host + this.api.uc.activitylist, params).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          that.modal2 = false
          if (that.model1 == "HUIONE") {
            that.huioneSubmit(this.formInline.fundpwd)
          } else {
            // this.modal22 = true
            // that.getAddrList()
            that.ok(this.formInline.fundpwd)
          }
          // that.ok()
        } else {
          that.$Message.error(resp.mesg)
        }
      })
    },

    //withdraw
    ok(password) {
      let that = this

      let param = {}
      param.ccy = "USDT"
      var num = this.withdrawAmount.replace(/\D/g, "")
      console.log("string without commas", num)

      var num2 = parseInt(num)
      console.log("numeric", num2)
      param.amount = num2
      param.account = this.inputAddress
      param.withdrawalName = ""
      param.bankCode = "USDT"
      param.pass = password
      param.payChannal = "udun"

      this.$http.post(this.host + this.api.uc.getfreecard, param).then((response) => {
        that.formInline.fundpwd = ""
        var resp = response.body
        if (resp.code == "0000") {
          that.modal9 = false

          // that.usdtAmount = resp.data.accountBalance
          // this.transaction.page = 0;
          // this.getList();
          
          that.clearValues()
          that.getMember()
          that.$Message.success(resp.mesg)
        } else {
          that.$Message.error(resp.mesg)
        }
      })
    },

    switchAddress(index) {
      this.model1 = index
      if (index == "HUIONE") {
        this.withdrawAddressArray.forEach((element) => {
          if (this.haveHuione == false && this.haveUdun == false) {
          this.modal8 = true
              return
        }
          if (element.addressChannel == 1) {
            
            this.inputAddress1 = element.walletAddress
            this.payname = element.walletName
          }
        })
      } else if (index == "USDT") {
        if (this.haveUdun == false) {
          this.modal8 = true
              return
        }
        this.withdrawAddressArray.forEach((element) => {
          if (element.addressChannel == 0) {
            this.inputAddress = element.walletAddress
          }
        })
      }
    },

    getAddress() {
      //获取个人安全信息
      var self = this
      // var params = {
      //     };

      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
      this.loading = true
      this.$http.post(self.host + this.api.uc.wallet).then((response) => {
        var resp = response.body
        self.loading = false
        if (resp.length > 0) {
          self.withdrawAddressArray = resp
          self.withdrawAddressArray.forEach(element => {
  if (element.addressChannel == 1) {
    self.haveHuione = true
  }else if (element.addressChannel == 0) {
    self.haveUdun = true
  }
});
          self.switchAddress("HUIONE")
        } else {
          // self.$Message.error(resp.mesg)
          if (!self.modal7) {
            self.modal8 = true
          }
        }
      })
    },

    huioneSubmit(password) {
      if (!this.inputAddress1 || !this.withdrawAmount1) {
        this.$Message.error(this.$t("uc.finance.notice123"))
        return
      }

      var self = this
      let param = {}
      param.ccy = "USDT"
      var num = this.withdrawAmount1.replace(/\D/g, "")
      console.log("string without commas", num)

      var num2 = parseInt(num)
      console.log("numeric", num2)
      param.amount = num2
      param.account = this.inputAddress1
      param.withdrawalName = this.payname
      param.bankCode = "汇旺"
      param.pass = password
      param.payChannal = "huione"

      this.$http.post(self.host + this.api.uc.getfreecard, param).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          this.modal9 = false
          this.formInline.fundpwd = ""
          self.$Message.success(this.$t("uc.finance.notice1234"))
          self.clearValues()
          self.getMember()
        } else {
          self.$Message.error(resp.mesg)
        }
      })
    },

    // initwithdraw
    getAddrList() {
      if (this.withdrawAmount < 0) {
        this.$Message.error(this.$t("uc.forget.walletaddress21"))
        return
      }
      let params = {}
      params["acctNo"] = this.inputAddress
      params["amount"] = this.withdrawOutAmount
      params["ccy"] = "USDT"
      params["ccySymbol"] = "TRC20"
      params["userId"] = this.$store.getters.member.id
      params["userNo"] = this.$store.getters.member.userNo
      this.$http.post(this.host + this.api.uc.attendActivity, params).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          this.model21 = resp.data
          this.modal10 = false
          this.modal9 = true
          this.$Message.success(resp.mesg)
        } else {
          this.$Message.error(resp.mesg)
        }
      })
    },

    accSub(arg1, arg2) {
      var r1, r2, m, n
      try {
        r1 = arg1.toString().split(".")[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split(".")[1].length
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2))
      //last modify by deeka
      //动态控制精度长度
      n = r1 >= r2 ? r1 : r2
      return ((arg1 * m - arg2 * m) / m).toFixed(n)
    },
    round(v, e) {
      var t = 1
      for (; e > 0; t *= 10, e--);
      for (; e < 0; t /= 10, e++);
      return Math.round(v * t) / t
    },
    computerAmount() {
      this.withdrawOutAmount = this.round(
        this.accSub(this.withdrawAmount, this.withdrawFee),
        this.currentCoin.withdrawScale
      )
    },
    computerAmount2() {
      this.withdrawAmount = (this.withdrawAmount + "").replace(/([0-9]+\.[0-9]{6})[0-9]*/, "$1") - 0
      this.withdrawOutAmount = this.round(
        this.accSub(this.withdrawAmount, this.withdrawFee),
        this.currentCoin.withdrawScale
      )
    },
    getList() {
      //获取tableRecharge
      this.loading = true
      let memberId = 0
      !this.$store.getters.isLogin && this.$router.push("/login")
      this.$store.getters.isLogin && (memberId = this.$store.getters.member.id)
      console.log("hahah" + memberId)
      this.$http.post(this.host + this.api.uc.paydividends).then((response) => {
        var resp = response.body
        this.loading = false

        if (resp.code == "0000") {
          if (resp.data) {
            console.log("xwe")
            this.coinList = resp.data
            let lang = localStorage.getItem("LANGUAGE") || "zh_CN"
            if (lang == "zh_HK") {
              lang = "zh_TW"
            }

            resp.data.forEach((element) => {
              let xxy = JSON.parse(element.bankLang)
              element.acctName = xxy[lang]
            })
          }
        } else {
          this.$Message.error(resp.message)
        }
      })
    },
    valid() {
      this.withdrawAdress = this.withdrawAdress || this.inputAddress
      if (this.coinType == "") {
        this.$Message.error(this.$t("uc.finance.withdraw.symboltip"))
        return false
      } else if (this.withdrawAdress == "") {
        this.$Message.error(this.$t("uc.finance.withdraw.addresstip"))
        return false
      } else if (
        this.withdrawAmount == "" ||
        this.withdrawAmount == 0 ||
        this.withdrawAmount - 0 < this.currentCoin.minAmount
      ) {
        this.$Message.error(this.$t("uc.finance.withdraw.numtip2") + this.currentCoin.minAmount)
        return false
      } else if (this.withdrawAmount - 0 < this.withdrawFee) {
        this.$Message.error(this.$t("uc.finance.withdraw.numtip3"))
        return false
      } else if (
        this.withdrawFee == "" ||
        this.withdrawFee == 0 ||
        this.withdrawFee - 0 > this.currentCoin.maxTxFee ||
        this.withdrawFee - 0 < this.currentCoin.minTxFee
      ) {
        this.$Message.error(
          this.$t("uc.finance.withdraw.feetip1") +
            this.currentCoin.minTxFee +
            " , " +
            this.$t("uc.finance.withdraw.feetip2") +
            this.currentCoin.maxTxFee
        )
        return false
      } else {
        return true
      }
    },
    apply() {
      if (this.valid()) {
        this.modal = true
        let timercode = setInterval(() => {
          if (this.countdown <= 0) {
            clearInterval(timercode)
            this.sendcodeValue = this.$t("uc.regist.sendcode")
            this.codeIsSending = false
          }
        }, 1000)
      }
    },

    firstModel() {
      //  this.modal10 = true;
      if (this.inputAddress == "") {
        this.$Message.error(this.$t("uc.finance.personalInfo6"))
        return
      }

      if (this.withdrawAmount <= 1) {
        this.$Message.error("取款金额需大于1USD/USDT")
        return
      }

      this.getMemberInfo1()
    },

    firstModel1() {
      //  this.modal10 = true;
      if (this.inputAddress1 == "" || this.payname == "") {
        this.$Message.error(this.$t("uc.finance.personalInfo6"))
        return
      }

      if (this.withdrawAmount1 <= 1) {
        this.$Message.error("取款金额需大于1USD/USDT")
        return
      }

      this.getMemberInfo1()
    },
    firstModel2() {
      //  this.modal10 = true;
      if (this.inputAddress2 == "" || this.payname1 == "" || this.inputAddress21 == "") {
        this.$Message.error(this.$t("uc.finance.personalInfo6"))
        return
      }
      this.getMemberInfo1()
    },

    getMoneyback() {
      //  var self = this;
      //     this.$http
      //         .post(this.host + this.api.uc.apply)
      //         .then(response => {
      //           var resp = response.body;
      //           if (resp.code == "0000") {
      //             this.getMember();
      //           } else {
      //             self.$Message.error(resp.mesg);
      //           }
      //         });
    },
  },
  created() {
    this.getMember()
    // this.$http.options.emulateJSON = false;
    // this.coinType = this.$route.query.name || "";
    // this.getAddrList();
    // this.getList();
    this.getMemberInfo()
    this.getAddress()
    // this.getMoneyback();
    this.currentIndex = 0
    // console.log(this.$store.getters.member);
  },
  computed: {
    member: function () {
      console.log(this.$store.getters.member)
      return this.$store.getters.member
    },
    tableColumnsWithdraw() {
      let columns = [],
        filters = []
      if (this.coinList.length > 0) {
        this.coinList.forEach((v) => {
          filters.push({
            label: v.unit,
            value: v.unit,
          })
        })
      }
      columns.push({
        title: this.$t("uc.finance.withdraw.time"),
        width: 180,
        key: "createTime",
      })
      columns.push({
        title: this.$t("uc.finance.withdraw.symbol"),
        key: "symbol",
        filters,
        filterMultiple: false,
        filterMethod(value, row) {
          return row.coin.unit === value
        },
        render: function (h, params) {
          return h("span", params.row.coin.unit)
        },
      })
      columns.push({
        title: this.$t("uc.finance.withdraw.address"),
        key: "address",
      })
      columns.push({
        title: this.$t("uc.finance.withdraw.num"),
        key: "totalAmount",
      })
      columns.push({
        title: this.$t("uc.finance.withdraw.fee"),
        key: "fee",
      })
      columns.push({
        title: this.$t("uc.finance.withdraw.txid"),
        key: "transactionNumber",
      })
      columns.push({
        title: this.$t("uc.finance.withdraw.status"),
        key: "status",
        render: (h, params) => {
          let text = ""
          if (params.row.status == 0) {
            text = this.$t("uc.finance.withdraw.status_1")
          } else if (params.row.status == 1) {
            text = this.$t("uc.finance.withdraw.status_2")
          } else if (params.row.status == 2) {
            text = this.$t("uc.finance.withdraw.status_3")
          } else if (params.row.status == 3) {
            text = this.$t("uc.finance.withdraw.status_4")
          }
          return h("div", [h("p", text)])
        },
      })
      return columns
    },
  },
}
</script>

<style lang="scss" scoped>
.withdraw-form-inline {
  padding: 20px 40px 0 40px;
  .ivu-input {
    height: 35px;
    line-height: 35px;
  }
}
.withdraw-form-inline1 {
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0 40px;
  .ivu-input {
    height: 35px;
    line-height: 35px;
  }
}

.inputssy {
  width: 80%;
  height: 100px;
  background: url(../assets/netmage/black1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 5%;
}

.bill_box {
  width: 96%;
  height: 90vh;
  // background: url(../assets/netmage/hhss.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 100% 100% ;
  margin-left: 2%;
  margin-top: 70px;
  // overflow: hidden;
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
</style>

<style scoped lang="scss">
.ivu-slider-wrap {
  top: 75px !important;
  z-index: 999 !important;
  width: 100% !important;
}

.celllall {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}

.celllall2 {
  width: 100%;
 
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 color: #a2a2a1;
 font-size: 18px;
 margin-top: 20px;
}

.celllall1 {
  width: 100%;
  height: 40px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  align-items: flex-start;
  // background: url(../assets/netmage/qqqy.png);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  font-size: 18px;
  margin-top: 70px;
}

.headerinfo {
  width: 100%;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
}

.withdraw {
  background: url("../assets/bg.png") no-repeat;
  // background: rgb(51, 49, 49);
  background-size: cover;
  // height: 800px;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

#sendCode {
  position: absolute;
  border: none;
  background: none;
  top: 10px;
  outline: none;
  right: 0;
  width: 30%;
  color: #f0ac19;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  border-left: 1px solid #dddee1;
}
.rightarea {
  width: 100%;
  height: auto;
  background: url(../assets/netmage/hhss.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 95% 100%;
  margin-top: 50px;
  padding: 20px;
}
.nav-rights {
  .nav-right {
    height: auto;
    overflow: hidden;
    padding: 0 15px;
  }
}
.ivu-slider-button-wrap {
  top: -6px;
}
#withdrawAddressList {
  position: absolute;
  height: 0;
  transition: height 0.3s;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 245px;
  overflow: auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  background: #fff;
}

.rightarea /deep/ .ivu-select-selection {
  background-color: #e5b932;
  border-radius: 15px;
  color: #000;
  font-weight: bolder;
}

#withdrawAddressList .address-item {
  padding: 0 20px;
  display: flex;
  line-height: 48px;
  border-bottom: 1px solid transparent;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  z-index: 99;
}

#withdrawAddressList .address-item:hover {
  background: #f5f5f5;
  cursor: pointer;
}

#withdrawAddressList .notes {
  position: absolute;
  bottom: 0;
  right: 20px;
  height: 48px;
  line-height: 48px;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

p.describe {
  font-size: 16px;
  font-weight: 600;
}

.acb-p1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
}

.acb-p2 {
  font-size: 13px;
  line-height: 24px;
  color: #8c979f;
}

.action-content.pt10 {
  padding-top: 10px;
}

.action-content {
  width: 100%;
  margin-top: 20px;
  // overflow: hidden;
  display: table;
}

.action-content .action-body {
  display: table-cell;
  vertical-align: top;
  line-height: 20px;
  font-size: 12px;
  color: #ccc;
}

.action-foot {
  text-align: center;
  padding: 40px 170px 0;
}

.hb-night .btn.btn-primary,
.hb-night .btn.btn_submit {
  background-color: #7a98f7;
  color: white;
}

.action-inner {
  width: 100%;
  display: table;
}

.action-inner .inner-box {
  display: table-cell;
  width: 80%;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
}

.controlAddress {
  line-height: 50px;
}

.form-group label {
  max-width: 100%;
  font-weight: 600;
}

.control-input-group {
  position: relative;
}

.control-input-group.open .select-list {
  height: auto;
}

.form-group-container {
  display: table;
  width: 100%;
}

.form-group-container .form-amount {
  width: 100%;
}

.form-group-container .form-group {
  display: table-cell;
}

.form-group-container .form-group span.addon-tag:last-child {
  padding: 0;
  border: none;
  background: none;
  cursor: default;
  position: absolute;
  right: 26px;
  top: 6px;
}


/deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
  color: #d2b965;
}


/deep/ .ivu-tabs.ivu-tabs-card{
  .ivu-tabs-bar .ivu-tabs-tab-active {
   
    border-color: #3e3e3f;
    background: #e4c87b;
}

.ivu-tabs-bar  .ivu-tabs-tab{
  background: #e4c87b;
  border-color: #3e3e3f;
  // padding: 12px 16px;
}
}


.form-group-container .form-group span.addon-tag:last-child.firstt {
  top: 8px;
}

.form-group-container2 {
  padding-top: 20px;
}

.form-group-container .form-fee {
  width: 50%;
  padding: 0 20px 0 0;
}

.label-amount .label-fr {
  float: right;
  color: #aaa;
  font-size: 14px;
}

.label-amount .label-fr span {
  margin-left: 2px;
}

.form-group-container .form-group {
  display: table-cell;
}

.hb-night table.table .table-inner {
  margin: -4px -20px;
  position: relative;
  background-color: #181b2a;
  border-radius: 3px;
}

.hb-night table.table .table-inner {
  margin: -4px -20px;
  position: relative;
  background-color: #181b2a;
  border-radius: 3px;
}

.hb-night table.table .table-inner {
  margin: -4px -20px;
  position: relative;
  background-color: #181b2a;
  border-radius: 3px;
}

table.table .table-inner.action-box {
  margin: -1px -10px;
}

.merchant-top .tips-word {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  text-align: left;
}

// .rightarea .rightarea-tabs {
//   border: none;
// }

.order_box {
  width: 100%;
  background: #fff;
  height: 56px;
  line-height: 56px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccf2ff;
  position: relative;
  text-align: left;
}

.order_box a {
  color: #8994a3;
  font-size: 16px;
  padding: 0 30px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 54px;
  display: inline-block;
}

.order_box .active {
  border-bottom: 2px solid #f0a70a;
}

.order_box .search {
  position: absolute;
  width: 300px;
  height: 32px;
  top: 12px;
  right: 0;
  display: flex;
  /* border: #c5cdd7 solid 1px; */
}

.ivu-btn-primary {
  background-color: #f0a70a;
  border-color: #f0a70a;
}
#pages {
  margin: 10px;
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.nav-rights {
  .nav-right {
    // .rightarea {
    //   // .action-box {
    //   //   .action-inner {
    //   //     .inner-left,
    //   //     .inner-box {
    //   //       .ivu-select-dropdown .ivu-select-item {
    //   //         padding: 6px 16px;
    //   //       }
    //   //     }
    //   //   }
    //   //   // .form-group-container {
    //   //   //   .form-group {
    //   //   //     .input-group {
    //   //   //       .ivu-poptip-rel {
    //   //   //         display: block;
    //   //   //         .ivu-input-number {
    //   //   //           width: 100%;
    //   //   //         }
    //   //   //       }
    //   //   //       .ivu-input-number {
    //   //   //         width: 100%;
    //   //   //       }
    //   //   //     }
    //   //   //   }
    //   //   // }
    //   // }
    // }
    .table-inner.action-box {
      .action-content .action-body {
        /*分页*/
        .order-table .ivu-table-wrapper .ivu-table-header {
          thead .ivu-table-cell {
            .ivu-poptip .ivu-poptip-rel .ivu-table-filter {
              i.ivu-icon.ivu-icon-funnel.on {
                color: #f0ac19;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="less">
// class="ivu-input ivu-input-default"
/deep/ .ivu-input {
  background: transparent;
  border: 0;
  border-radius: 25px;

  color: #898989;
}

/deep/ .ivu-input-wrapper {
  border: 0.1ch;
}



.btnSub{
  width:75%; 
  height: 40px; 
  font-size: 14px; 
  border: none;  
  color: #553a25; 
  font-weight: 600;
  border-radius: 25px;
  background: url(../assets/me/logout-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  letter-spacing: 2px;
}
</style>
