<template>
  <div class="login_form game-details" :style="{ height: `${windowHeight}px` }">
    <div class="headBox">
      <img src="../assets/newImage/back.png" alt="" style="width: 20px; height: 20px" @click="goback" />
      <span>
        {{ $t("uc.finance.gamedetail") }}
      </span>
      <div style="display: flex; align-items: center">
        <img src="../assets/newImage/ma11.png" width="20" alt="" @click="popdata" />
        <!-- <img src="../assets/newImage/date.png" width="20" alt="" @click="popdata"> -->
      </div>
    </div>

    <div>
      <div        
        v-if="datalist1.length <= 0"
        style="display: flex;flex-direction: column; width: 100%;align-items: center;">
        <img src="../assets/newImage/norec.png" width="100" alt="" style="margin-top: 50%;"/>
        <span style="font-size: x-small; color: #fff">暂无记录</span>
      </div>

      <Scroll v-else :on-reach-bottom="nextPage" :height="realHeight" :distance-to-edge="10" style="margin-top: 50px;">
        <div dis-hover v-for="(item, index) in datalist1" :key="index" :class="index % 2 == 0 ? 'active' : 'notactive'">
          <div style="width: 100%; display: flex; flex-direction: row">
            <div class="xxx1">
              <div>
                <span style="font-size: x-small; color: #fff">游戏名称:{{ item.thirdPlatform }}</span>
              </div>
              <div>
                <span style="font-size: x-small; color: #fff">{{ item.startTime }}</span>
              </div>
            </div>
            <div class="xxx2">
              <div>
                <span style="color: #fff; font-size: x-small">{{ $t("uc.finance.validBet") }} </span>
                <span style="color: #8d8d8d; margin-right: 5px; font-size: x-small">{{ item.bet }}</span>
                <span style="color: #fff; font-size: x-small">{{ item.ccy }} </span>
              </div>
              <div style="font-size: x-small">期数：{{ item.gamePlatform }}</div>
            </div>
            <div class="xxx3">
              <div v-if="Number(item.payout) - Number(item.bet) < 0">
                <span style="color: #fff; font-size: x-small; margin-right: 1px">输</span>
                <span style="color: #0db76d; font-size: x-small">{{
                  (Number(item.payout) - Number(item.bet) - Number(item.commission)).toFixed(2)
                }}</span>
              </div>
              <div v-else>
                <span style="color: #fff; font-size: x-small; margin-right: 1px">赢</span>
                <span style="color: #b7240d; font-size: x-small">
                  {{ (Number(item.payout) - Number(item.bet) - Number(item.commission)).toFixed(2) }}</span
                >
              </div>

              <div>
                <span style="color: #fff; font-size: x-small">开奖结果 : </span>
                <span style="color: #8d8d8d; margin-right: 5px; font-size: x-small">{{ item.betDetail }}</span>
              </div>
            </div>
            <!-- <span>订单号:{{ item.thirdNo }}</span> -->
          </div>
          <span style="display: flex; align-content: flex-start">订单号 : {{ item.thirdNo }}</span>
          <span class="spanStyle">详情 : {{ item.gameresult }}</span>
        </div>
      </Scroll>
    </div>

    <calendar
      :show.sync="calendarShow"
      :defaultDate="defaultDate"
      mode="during"
      @change="onChange"
      style="color: #0073ee"
    />
  </div>
</template>

<script>
import dayjs from "dayjs"

export default {
  name: 'GameDetails',
  data() {
    return {
      datalist: [],
      datalist1: [],
      startDate: "",
      endDate: "",
      defaultDate: [dayjs(), dayjs().add(-7, "day")],
      totalregist: "",
      totolwin: 0,
      totalbet: 0,
      totalValidBet: 0,
      calendarShow: false,
      currentPage: 1,
      pageSize: 20,
      // totalPage: 0,
      isActive: 0,
      iSportTouched: false,
      isGameTouched: false,
      iSportSubActive: 0,
      isGameSubActive: 2,
      rootHeight: 0,
      realHeight: 0,
      isReachBottom: false,
      sportTab: "sp1",
      gameTab: "g1",
      isSport: false,
      currentName: "",
      value8: false,
      rechargestatus: "",
      withdrawstatus: "",
      commonstatus: "",
      currentab: "",
      showxxy: "",
      windowHeight: 0,
    }
  },
  created() {
    const windowHeight = document.documentElement.clientHeight;
    this.realHeight = (Number(windowHeight)) * (1 - (100/windowHeight))

    this.endDate = this.defaultDate[0].format("YYYY-MM-DD")
    this.startDate = this.defaultDate[1].format("YYYY-MM-DD")
    this.initGtCaptcha()
  },
  mounted() {
    this.renderResize()
    window.addEventListener('resize', this.renderResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.renderResize)
  },
  methods: {
    renderResize() {
      this.windowHeight = document.documentElement.clientHeight
      this.realHeight = Number(this.windowHeight) - 50 - 51 // marginTop: 50, tab bar: 51
    },
    nextPage() {
      let that = this
      let params = {}

      this.currentPage += 1
      params.pageNum = this.currentPage
      params.pageSize = this.pageSize

      params.beginDateStr = this.startDate
      params.endDateStr = this.endDate

      params.ccy = "USDT"
      params.thirdPlatform = "四名"

      this.$http.post(this.host + this.api.uc.gameRecorder, params).then((response) => {
        const resp = response.body

        if (resp.code == "0000") {
          const xyz = resp.data.list

          if (xyz.length <= 0) {
            // this.$Notice.info({
            //   title: that.$t("uc.finance.xx14"),
            // })
          }
          xyz.forEach((element) => {
            try {
              let aabb = { A: 0, B: 0, C: 0, D: 0, E: 0 }
              element.gameresult = aabb
              element.gameresult = that.handleResult(JSON.parse(element.sonDetail))
            } catch (e) {
              return false
            }

            that.datalist1.push(element)
          })
        } else
          this.$Notice.error({
            title: this.$t("common.tip"),
            desc: resp.message,
          })
      })
    },

    initGtCaptcha() {
      let that = this
      let params = {}
      params.beginDateStr = this.startDate
      params.endDateStr = this.endDate
      params.pageNum = 1
      this.currentPage = 1
      params.pageSize = this.pageSize
      params.ccy = "USDT"
      params.thirdPlatform = "四名"

      this.$http.post(this.host + this.api.uc.gameRecorder, params).then((response) => {
        var resp = response.body

        if (resp.code == "0000") {
          that.datalist1 = resp.data.list
          that.datalist1.forEach((element) => {
            try {
              let aabb = { A: 0, B: 0, C: 0, D: 0 }
              element.gameresult = aabb

              element.gameresult = that.handleResult(JSON.parse(element.sonDetail))
            } catch (e) {
              return false
            }
          })
          this.totalbet = resp.data.totalBet
          this.totolwin = resp.data.totalWin
          this.totalValidBet = resp.data.totalValidBet
        } else
          this.$Notice.error({
            title: this.$t("common.tip"),
            desc: resp.message,
          })
      })
    },

    handletabs(data) {
      this.currentab = data
      if (data == "cmd") {
        this.getsubData("sp1")
      } else if (data == "leyou") {
        this.getsubData("g1")
      }
    },

    otherpicke() {
      this.value8 = true
    },

    getLeagueName(item) {
      let params = {}
      let param = JSON.parse(item.betDetail)
      params.ID = "" + param.LeagueId
      params.Type = 1
      let xx = ""
      let that = this
      this.$http.get(this.host + this.api.uc.cmdLang, { params: params }).then((response) => {
        var resp = response.body

        if (resp.code == 0) {
          let jss = JSON.parse(resp.data)
          xx = jss[that.$i18n.locale]
          item.league = xx
          item.hasDetail = 1
          item.matchTime = item.MatchDate
          that.$forceUpdate()
        } else
          that.$Notice.error({
            title: that.$t("common.tip"),
            desc: resp.message,
          })
      })
    },
    getMatchName(match) {
      let params = {}
      params.ID = "" + match
      params.Type = 2
      let xx = ""
      let that = this
      this.$http.get(this.host + this.api.uc.cmdLang, { params: params }).then((response) => {
        var resp = response.body

        if (resp.code == 0) {
          xx = resp.data[that.$i18n.locale]
          return xx
        } else
          that.$Notice.error({
            title: that.$t("common.tip"),
            desc: resp.message,
          })
      })
    },

    getsubData(name) {
      switch (name) {
        case "sp1":
          this.rechargestatus = ""
          this.withdrawstatus = ""
          this.commonstatus = ""
          this.currentName = "CMD体育"
          this.subCmd()
          break
        case "sp2":
          this.rechargestatus = ""
          this.withdrawstatus = ""
          this.commonstatus = ""
          this.currentName = "saba体育"
          this.subSaba()
          break
        case "g1":
          this.rechargestatus = ""
          this.withdrawstatus = ""
          this.commonstatus = ""
          this.currentName = "乐游"
          this.subLeyou()

          break
        default:
          break
      }
    },

    subCmd() {
      this.endDate = this.defaultDate[0].format("YYYY-MM-DD")
      this.startDate = this.defaultDate[1].format("YYYY-MM-DD")
      this.currentPage = 1
      this.initGtCaptcha("CMD体育")
      this.iSportSubActive = 0
    },
    subSaba() {
      this.endDate = this.defaultDate[0].format("YYYY-MM-DD")
      this.startDate = this.defaultDate[1].format("YYYY-MM-DD")
      this.currentPage = 1
      // this.initGtCaptcha('saba体育')
      this.iSportSubActive = 1
    },

    subLeyou() {
      this.endDate = this.defaultDate[0].format("YYYY-MM-DD")
      this.startDate = this.defaultDate[1].format("YYYY-MM-DD")
      this.currentPage = 1
      this.initGtCaptcha("乐游")
      this.isGameSubActive = 0
    },

    subAll() {
      this.iSportSubActive = 0
    },
    subSingle() {
      this.iSportSubActive = 1
    },
    subMore() {
      this.iSportSubActive = 2
    },
    subChamp() {
      this.iSportSubActive = 3
    },

    goback() {
      //获取个人安全信息
      this.$router.back(-1)
    },
    onChange(date) {
      if (date[0] != null && date[1] != null) {
        this.startDate = date[0].format("YYYY-MM-DD")

        this.endDate = date[1].format("YYYY-MM-DD")

        this.initGtCaptcha()
      }
    },

    handleResult(jsob) {
      let resut = ""
      for (const i in jsob) {
        switch (i) {
          case "A1":
            resut += "单调1:" + jsob[i] + ","
            break
          case "A2":
            resut += "单调2:" + jsob[i] + ","
            break
          case "A3":
            resut += "单调3:" + jsob[i] + ","
            break
          case "A4":
            resut += "单调4:" + jsob[i] + ","
            break
          case "B1":
            resut += "1带2:" + jsob[i] + ","
            break
          case "B2":
            resut += "2带1:" + jsob[i] + ","
            break
          case "B3":
            resut += "2带3:" + jsob[i] + ","
            break
          case "B4":
            resut += "3带2:" + jsob[i] + ","
            break
          case "B5":
            resut += "3带4:" + jsob[i] + ","
            break
          case "B6":
            resut += "4带3:" + jsob[i] + ","
            break
          case "B7":
            resut += "4带1:" + jsob[i] + ","
            break
          case "B8":
            resut += "1带4:" + jsob[i] + ","
            break
          case "B9":
            resut += "1带3:" + jsob[i] + ","
            break
          case "B10":
            resut += "2带4:" + jsob[i] + ","
            break
          case "B11":
            resut += "3带1:" + jsob[i] + ","
            break
          case "B12":
            resut += "4带2:" + jsob[i] + ","
            break
          case "C1":
            resut += "12角:" + jsob[i] + ","
            break
          case "C2":
            resut += "23角:" + jsob[i] + ","
            break
          case "C3":
            resut += "34角:" + jsob[i] + ","
            break
          case "C4":
            resut += "14角:" + jsob[i] + ","
            break
          case "D1":
            resut += "单:" + jsob[i] + ","
            break
          case "D2":
            resut += "双:" + jsob[i] + ","
            break
          case "E1":
            resut += "1保23:" + jsob[i] + ","
            break
          case "E2":
            resut += "1保24:" + jsob[i] + ","
            break
          case "E3":
            resut += "1保34:" + jsob[i] + ","
            break
          case "E4":
            resut += "2保13:" + jsob[i] + ","
            break
          case "E5":
            resut += "2保14:" + jsob[i] + ","
            break
          case "E6":
            resut += "2保34:" + jsob[i] + ","
            break
          case "E7":
            resut += "3保12:" + jsob[i] + ","
            break
          case "E8":
            resut += "3保14:" + jsob[i] + ","
            break
          case "E9":
            resut += "3保24:" + jsob[i] + ","
            break
          case "E10":
            resut += "4保12:" + jsob[i] + ","
            break
          case "E11":
            resut += "4保13:" + jsob[i] + ","
            break
          case "E12":
            resut += "4保23:" + jsob[i] + ","
            break
          default:
            break
        }
      }
      return resut.substr(0, resut.length - 1)
    },

    popdata() {
      this.calendarShow = !this.calendarShow
    },

    billType(status) {
      let xyz = ""
      switch (status) {
        case 100:
          xyz = this.$t("uc.finance.xx71")
          break
        case 101:
          xyz = this.$t("uc.finance.xx70")
          break
        case 102:
          xyz = this.$t("uc.finance.xx72")
          break
        default:
          break
      }
      return xyz
    },
    getPokeGameList() {
      let param = {}

      param["gameType"] = 2
      param["productID"] = 1020
      param["languageCode"] = 3
      param["operatorCode"] = "E503"
      param["platform"] = 0

      this.$http.post(this.host + this.api.uc.mdboGameList, param).then((response) => {
        let resp = response.body

        if (resp.ErrorCode == 0) {
          let temp = JSON.parse(resp.data)

          console.log(temp)
        } else {
          // self.$Message.error(resp.mesg);
          this.$Notice.error({
            title: this.$t("common.tip"),
            desc: resp.mesg,
          })
          // this.$Message.error(this.$t('common.logintip'));
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.box {
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  color: $color-text-tag;
  z-index: 200;
  width: 100%;
  background-color: $color-background;
}

.head_box {
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: small;
  height: auto;
  // padding-top: 10px;
}

.body_box {
  display: flex;
  color: #fff;
  font-size: 15px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // margin-left: 5%;
  // margin-top: 20px;
  height: auto;
}

.body_box1 {
  display: flex;
  color: #fff;
  font-size: 15px;
  width: 100%;
  align-items: center;

  height: auto;
}

/deep/ .ivu-card-body {
  padding: 10px 10px;
}

.head2 {
  color: #898989;
  display: flex;
  justify-content: space-around;
  margin-top: 60px;
  width: 100%;
}
.bodyhead {
  color: #898989;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/deep/ .ivu-card-bordered {
  border: none;
}

/deep/ .ivu-btn {
  border: none;
}

.head22 {
  display: flex;
  flex-direction: column;
  font-size: medium;
}

.sports {
  margin-top: 45px;
  height: 100vh;
}
.gamerecords {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: baseline;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
/deep/ .ivu-tabs-bar {
  border-bottom: 0px solid #dcdee2;
  margin-bottom: 10px;
  background-color: #1a1a1a;
}

.active {
  height: 100px;
  padding: 8px 10px;
  background-color: #1a1a1a;
  border-radius: 5px;
}

.notactive {
  height: 100px;
  padding: 8px 10px;
  background-color: transparent;
  border-radius: 5px;
}

/deep/ .ivu-tabs-nav-container {
  font-size: 12px;
}

/deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
  color: #d2b965;
}

/deep/ .ivu-tabs-tab {
  color: #fff;
  padding: 12px 16px;
}

.spanStyle {
  display: inline-block;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50%;
  text-align: left;
}

/deep/ .ivu-tabs-ink-bar {
  background-color: #d2b965;
}

.xxx1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 34%;
}

.xxx2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1px;
  width: 45%;
}
.xxx3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 20%;
}
</style>
