<template>
  <div class="login_form">
    <div class="headBox">
   <img src="../assets/newImage/back.png" alt="" style="width:20px;height:15px;" @click="goback">
  <span>
    {{$t('uc.finance.personalInfo')}}
  </span>
  <span>{{  }}</span>
</div>
<div class="body">
  
        

       
        <Input :placeholder="$t('uc.finance.personalInfo1')" v-model="formItem.input" style="width: 100%;">
            <Icon type="ios-contact" slot="prefix" />
        </Input>
       
       
            <Select v-model="formItem.select" prefix="ios-contacts" style="width: 100%;margin-top: 20px;">
                <Option value='1'>{{$t('common.male')}}</Option>
                <Option value="0">{{$t('common.female')}}</Option>
            </Select>
            <DatePicker type="date" :placeholder="$t('uc.finance.personalInfo3')" style="width: 100%;margin-top: 20px;" v-model="formItem.date">
              <Icon type="ios-contact" slot="prefix" />
            </DatePicker>
        
        
            <Input :placeholder="$t('uc.finance.personalInfo4')" v-model="formItem.time" style="width: 100%;margin-top: 20px;">
            <Icon type="ios-phone-portrait" slot="prefix" />
        </Input>
       
        <p style="margin-top: 40px;color: #898989;width: 90%;margin-left: 5%;">{{ $t('uc.finance.personalInfo5') }}
        <Button type="text" style="color: blue;margin-left: -15px; " size="large" @click="gotoService">{{ $t('uc.finance.notice111') }}</Button></p>
            <Button  class="login_btn" @click="submit">{{ $t('uc.forget.save') }}</Button>
       
</div>


  </div>
</template>



<script>


export default {
 
 data() {
return{
  formItem: {
                    input: '',
                    select: '',
                    radio: 'male',
                    checkbox: [],
                    switch: true,
                    date: '',
                    time: '',
                    slider: [20, 50],
                    textarea: ''
                }
}
 },


created: function() {
    // this.initGtCaptcha();
  },


methods: {


goback() {
      //获取个人安全信息
     this.$router.back(-1)
     
    },
submit(){

},

gotoService(){
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push('/customservice')
    },

}


}
</script>

<style lang="scss" scoped>

// .login_form {
//   background: #0b1520;
//   height: 56rem;
//   position: relative;
//   overflow: hidden;
//   }
.login_btn.ivu-btn {
        width: 300px;
        background: url(../assets/netmage/usdtxy.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        outline: none;
        color: #0b1520;
        font-weight: 600;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 18px;
        border: none;
        margin-top: 20px;
        &:focus {
          -moz-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
          -webkit-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
          box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
        }
      }
.body {
  margin-top: 100px;
  
  width: 80%;
  margin-left: 10%;
  
}

  .login_form{
  width: 100%;
  height: 100vh;
  overflow: hidden auto;
  background-color: black;
}

</style>



