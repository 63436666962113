<template>
  <div class="todo-list">
    <!-- 待办 -->
   
      <section v-show="message === '1'" ref="slide-todo-wrap" class="todo" style="margin-top:60px">
        <div v-for="item in todoList" :key="item.id" class="todo-item">
          
          <div class="head_box">
              <span style="font-weight: 600;">{{ parseTrans(item.tranCode) }}</span>
              <span>{{ item.tradeAmount }}</span>
            <!-- <div class="edit" @click="handleEdit(item.id, item.content)"><i class="iconfont icon-edit" /></div>
            <div class="delete" @click="handleDelete(item.id)"><i class="iconfont icon-delete" /></div> -->
          </div>
          <section style="margin-top:5px;">
            <div class="body_box_1">
               <span>{{$t('uc.finance.xx29')}} : {{item.tradeNo}}</span>
               <span v-if="item.oppositeUserNo">{{$t('common.date10')}} {{item.oppositeUserNo}}</span>
               <span>{{$t('uc.finance.xx30')}} : {{item.tradeTime}}</span>
               <span>{{$t('uc.finance.xx31')}} : {{item.accountBalance}}</span>
              <!-- <span>{{ $t('common.amount') }}</span>
              <span class="amountxx">{{ item.accountBalance }}</span> -->
            <!-- <div class="edit" @click="handleEdit(item.id, item.content)"><i class="iconfont icon-edit" /></div>
            <div class="delete" @click="handleDelete(item.id)"><i class="iconfont icon-delete" /></div> -->
          </div>
           <!-- <div style="font-size: 12px;color: #989898;margin-top: 10px;margin-left: -50%;">
            <span>{{item.tradeTime}}</span>
           </div> -->
          </section>
          
        </div>
       
      </section>

    

      <!--  {
        "id": "155",
        "userId": "122",
        "depositNo": "1155915397745082368",
        "ccy": "USDT",
        "payChannel": "manual",
        "merchantId": "0",
        "depositAddress": null,
        "depositAmount": "50.0000000000",
        "handlingFee": "0E-10",
        "depositTime": 1695633307000,
        "createTime": 1695633307000,
        "status": 1, 0支付中；1完成支付；2支付失败
        "operator": "admin",
        "remark": "测试",
        "thirdOrderNo": null,
        "userNo": "213172",
        "remark2": null,
        "tranCode": "ZSLJ",
        "depositType": 1,   0线上充值1手动充值
        "balance": "50.0000000000"
    }
]-->
      <section v-if="message == '31'" ref="slide-todo-wrap" class="todo">
        <div v-for="item in records" :key="item.id" class="todo-item">
          
          <div class="head_box">
              <span style="font-weight: 600;" v-if="item.depositType == '0'">{{ $t('uc.finance.xx19') }}</span>
              <span style="font-weight: 600;" v-else>{{ $t('uc.finance.xx20') }}</span>
              <span style="font-weight: 600;" >{{ $t('uc.finance.invitingmining') }} : {{ Number(item.depositAmount).toFixed(2)}}</span>
              <span>{{ parseStatus(item.status) }}</span>
          
          </div>
        
            <div class="body_box1">
<div >
  <!-- <span style="margin-right:20px">{{ $t('uc.finance.xx13') }} : {{ item.userNo }}</span> -->
              <!-- <span v-if="item.orderType == '0'">{{ $t('uc.finance.xx7') }}--{{item.paymentName}}</span>
              <span v-else>{{ $t('uc.finance.xx9') }}--{{item.walletName}}</span> -->
</div>
             
              <!-- <span v-if="item.orderType == '0'">{{ $t('uc.finance.xx8') }}--{{item.paymentAddress}}</span>
              <span v-else>{{ $t('uc.finance.xx10') }}--{{item.walletAddress}}</span> -->
              
              <!-- <span>{{ $t('uc.finance.xx12') }} : {{ item.createTime | datefomt('YYYY-MM-DD HH:mm:ss') }}</span> -->
             
              <div style="display: flex;justify-content: space-between;width: 100%;">
                <!-- <span >{{ $t('uc.finance.xx67') }} : {{ parseTrans(item.tranCode) }} </span> -->
                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
              </span>
              
              </div>
              <span>{{ $t('uc.finance.tradetail') }} : {{ Number(item.balance).toFixed(2)}}</span>
              <!-- <span class="spanStyle">{{ $t('uc.finance.xx11') }} : {{ item.remark }}</span> -->
              <div style="display: flex; justify-content: flex-end; width: 100%; font-size: small;color: gray;">
                <span> {{ item.depositTime | datefomt('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>
              
          </div>
           
         
          
        </div>
       
      </section>
<!-- 
      {
    "id": "600",
    "userId": "122",
    "userNo": "213172",
    "withdrawalNo": "1156196067599974400",
    "withdrawalAmount": "2.0000000000",
    "withdrawalAccount": null,
    "handlingFee": "0.0000",
    "realAmount": "0.0000",
    "operator": "admin",
    "remark": "1111",
    "status": 1,
    "createTime": 1695700224000,
    "updateTime": null,
    "payChannal": "manual",
    "merchantId": 0,
    "ccy": "USDT",
    "remark2": null,
    "thirdOrderNo": null,
    "withdrawTime": 1695700224000,
    "tranCode": "YXKF",
    "withdrawType": 1,
    "balance": "48.0000000000"
} -->
      <section v-else-if="message == '32'" ref="slide-todo-wrap" class="todo">
        <div v-for="item in records" :key="item.id" class="todo-item">
          
          <div class="head_box">
              <span style="font-weight: 600;" v-if="item.withdrawType == '0'">{{ $t('uc.finance.xx19') }}</span>
              <span style="font-weight: 600;" v-else>{{ $t('uc.finance.xx22') }}</span>
              <span style="font-weight: 600;" >{{ $t('uc.finance.invitingmining') }} : {{ Number(item.withdrawalAmount).toFixed(2)}}</span>
              <span>{{ parseStatus(item.status) }}</span>
          
          </div>
            <div class="body_box1">
<div >
  <!-- <span style="margin-right:20px">{{ $t('uc.finance.xx13') }} : {{ item.userNo }}</span> -->
              <!-- <span v-if="item.orderType == '0'">{{ $t('uc.finance.xx7') }}--{{item.paymentName}}</span>
              <span v-else>{{ $t('uc.finance.xx9') }}--{{item.walletName}}</span> -->
</div>
             
              <!-- <span v-if="item.orderType == '0'">{{ $t('uc.finance.xx8') }}--{{item.paymentAddress}}</span>
              <span v-else>{{ $t('uc.finance.xx10') }}--{{item.walletAddress}}</span> -->
              <!-- <span class="spanStyle">{{ $t('uc.finance.xx11') }} : {{ item.remark }}</span> -->
              <!-- <span>{{ $t('uc.finance.xx12') }} : {{ item.createTime | datefomt('YYYY-MM-DD HH:mm:ss') }}</span> -->
              <div style="display: flex;justify-content: space-between;width: 100%;">
                <!-- <span >{{ $t('uc.finance.xx69') }} : {{ parseTrans(item.tranCode) }} </span> -->
                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
              </span>
              
              </div>
              <span>{{ $t('uc.finance.tradetail') }} : {{ Number(item.balance).toFixed(2)}}</span>
              <div style="display: flex; justify-content: flex-end; width: 100%; font-size: small;color: gray;">
                <span> {{ item.withdrawTime | datefomt('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>

          </div>
           
          
        </div>
       
      </section>
      <!-- {
            "id": "5433",
            "userId": "121",
            "userNo": "139216",
            "oppositeUserNo": "284539",
            "tradeAmount": "-11",
            "accountBalance": "289",
            "tranCode": "HYZZ",
            "tradeNo": "1157768120316723200",
            "tradeTime": "2023-09-30 18:57:10",
            "paymentType": 2
        }, -->
      <section v-else-if="message == '33'" ref="slide-todo-wrap" class="todo">
        <div v-for="item in records" :key="item.id" class="todo-item">
          
          <div class="head_box">
              <span style="font-weight: 600;" v-if="item.paymentType == '0'">{{ $t('uc.finance.xx19') }}</span>
              <span style="font-weight: 600;" v-else>{{ $t('uc.finance.xx22') }}</span>
              <span style="font-weight: 600;" >{{ $t('uc.finance.invitingmining') }} : {{ Number(item.tradeAmount).toFixed(2)}}</span>
          
          </div>
            <div class="body_box1">
<div style="display: flex;flex-direction:column;">
  <!-- <span style="margin-right:20px">{{ $t('uc.finance.xx13') }} : {{ item.userNo }}</span> -->
  <!-- <span >{{ $t('common.date10') }}  {{ item.oppositeUserNo }}</span> -->
              <!-- <span v-if="item.orderType == '0'">{{ $t('uc.finance.xx7') }}--{{item.paymentName}}</span>
              <span v-else>{{ $t('uc.finance.xx9') }}--{{item.walletName}}</span> -->
</div>
             
              <!-- <span v-if="item.orderType == '0'">{{ $t('uc.finance.xx8') }}--{{item.paymentAddress}}</span>
              <span v-else>{{ $t('uc.finance.xx10') }}--{{item.walletAddress}}</span> -->
              <!-- <span>{{ $t('uc.finance.xx12') }} : {{ item.tradeTime }}</span> -->
              <div style="display: flex;justify-content: space-between;width: 100%;">
                <!-- <span >{{ $t('uc.finance.xx69') }} : {{ parseTrans(item.tranCode) }} </span> -->
                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
              </span>
              
              </div>
              <span>{{ $t('uc.finance.tradetail') }} : {{ Number(item.accountBalance).toFixed(2)}}</span>


              <div style="display: flex; justify-content: flex-end; width: 100%; font-size: small;color: gray;">
                <span> {{ item.tradeTime | datefomt('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>


          </div>
           
          
        </div>
       
      </section>
  
<!-- 
      {
    "id": "24399120488",
    "refNo": "T48U14632716857053206",
    "site": "DG",
    "product": "LC",
    "member": "146327",
    "gameId": "1",
    "startTime": "2023-09-25 03:15:24",
    "endTime": "2023-09-25 03:15:43",
    "matchTime": "2023-09-25 03:15:43",
    "betDetail": "{\"player\":10.0,\"playerW\":10.0}-{\"result\":\"9,1,8\",\"poker\":{\"banker\":\"21-37-0\",\"player\":\"13-47-0\"}}-BetNo:16857053206-PlayID:230925B011012-Table:10101",
    "ccy": "USD",
    "turnover": "0.00",
    "bet": "10.00",
    "payout": "10.00",
    "commission": "0.00",
    "status": "1",
    "pshare": "0.00",
    "pwin": "0.00"
} -->

      <section v-show="message === '0'" ref="slide-done-wrap" class="done">
      
          <div v-for="item in doneList" :key="item.id" class="todo-item" >
          <div class="head_box">
              <span style="font-weight: 600;">{{$t('uc.finance.gametype')}}:{{ gameType(item.product) }}</span>
              <span style="font-weight: 600;">{{$t('uc.finance.platform')}}:{{ item.site}}</span>
          </div>
          <div style="margin-top:5px;">
            <div class="body_box"> 
              <div style="display:flex;align-items: flex-start;">
                <span>{{$t('uc.finance.bet')}} : {{ item.bet }}</span>
                <span style="margin-left: 20px;">{{$t('uc.finance.validBet')}} : {{ item.turnover }}</span>
              </div>
              
              <div style="display:flex;align-items: flex-start;">
                 <span v-if="Number(item.payout) - Number(item.bet) < 0" style="color: #0db76d;">{{$t('uc.finance.profit')}} : {{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                 <span v-else style="color: #b7240d;">{{$t('uc.finance.profit')}} : {{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>

                 <!-- <p>{{$t('common.amount')}} : {{ item.balance }}</p> -->
                <span style="margin-left: 20px;">{{$t('uc.finance.bitStatus')}} : {{ billType(item.status) }}</span>
              </div>

             <div style="display: flex;align-items: flex-start;font-size: small;flex-direction: column;">
              <span v-if="item.site == 'IB'"> {{ $t('uc.finance.xx96') }} : {{ item.LeagueName }}</span>
              <span v-if="item.site == 'DG'">{{ $t('uc.finance.xx76') }} : {{ item.betNo }}</span>
              <span v-else-if="item.site == 'AG'">{{ $t('uc.finance.xx80') }} : {{ item.Playerbillno }}</span>
              <span v-else-if="item.site == 'IB'">{{ $t('uc.finance.xx97') }} : {{ item.HomeIDName }} : {{ $t('uc.finance.xx99') }} : {{ item.HomeScore }}</span>

                <span v-if="item.site == 'DG'">{{ $t('uc.finance.xx77') }} : {{ item.playID }}</span>
                <span v-else-if="item.site == 'AG'">{{ $t('uc.finance.gametype') }} : {{ item.sport }}</span>
                <span v-else-if="item.site == 'IB'">{{ $t('uc.finance.xx98') }} : {{ item.AwayIDName }} : {{ $t('uc.finance.xx99') }} : {{ item.AwayScore }}</span>

                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}</span>
                <!-- <span>{{ $t('uc.finance.xx79') }} : {{ item.result }}</span> -->
                <!-- <div :v-if="item.poker != undefined">
                  <span :v-if="item.poker != undefined && item.poker.banker != undefined">{{ item.poker.banker }} :: {{ item.poker.player }}</span>
                  <span :v-else-if="item.poker != undefined && item.poker.black != undefined">{{ item.poker.black }} :: {{ item.poker.red }}</span>
                  <span :v-else-if="item.poker != undefined && item.poker.tiger != undefined">{{ item.poker.dragon }} :: {{ item.poker.tiger }}</span>
                </div> -->
             </div>
            
          </div>
           <div style="font-size: 12px;color: #989898;margin-top: 10px; display: flex;flex-direction: column;align-content: flex-start;align-items: flex-start;">
            <span>{{$t('uc.finance.xx73')}}:{{item.startTime}}</span>
            <span>{{$t('uc.finance.xx74')}}:{{item.endTime}}</span>
           </div>
          </div>
        </div>
   
       
      </section>

      <section v-show="message === '2'" ref="slide-data-wrap" class="empty">
        <div v-for="item in dataList" :key="item.userNo" class="done-item" @click="gotoSub(item.userNo)">
       
          <section style="margin-top:5px;">
            <div class="body_box"> 
              <div style="display:flex;flex-direction: row;justify-content: space-around; font-size: small;">
                <p>
                  <img v-if="item.isAgent==1" src="../assets/netmage/agent.png" width="20px" height="20px" alt="">
                </p>
                <p>{{ item.userNo }}</p>
                <p>{{ Number(item.totalBet).toFixed(2)  }}</p>
                <p>{{ Number(item.totalValidBet).toFixed(2)  }}</p>
                <p>{{ Number(item.totalWin).toFixed(2)  }}</p>
              </div>
              
          </div>
          
          </section>
        </div>
      </section>
 
 
  </div>
</template>

<script>

export default {
  name: 'TodoList',
  props:{
    message:String,
    dataList:Array,
    doneList:Array,
    records:Array
  },
  data() {
    return {
      inputActive: false, // 输入框是否激活
      todoList: [{ id: 1, content: 'asdfasd' }], // 代办事项
      // doneList: [], // 已完成事项
      
      updateId: '', // 要更新的id
      status:'1',
     
    }
  },
  computed: {
   
  },
  watch: {
   
  },
  created() {
    if (this.message == '1') {
      this.getTodoList()
    }
    // else if (this.message == '3') {
    //   this.getDetail()
    // } 
    // console.log('sdfsdf' + this.dataList[0])
  },
  methods: {
    // 0支付中；1完成支付；2支付失败
    parseStatus(status){
      let xyz = ''
      switch (status) {
        case 0:
          xyz = this.$t('uc.finance.xx63')
          break;
          case 1:
          xyz = this.$t('uc.finance.xx5')
          break;
          case 2:
          xyz = this.$t('uc.finance.xx6')
          break;
        default:
          break;
      }

      return xyz
    },


    billType(status){
let xyz = ''
switch (status) {
        case '0':
          xyz = this.$t('uc.finance.xx71')
          break;
          case '1':
          xyz = this.$t('uc.finance.xx70')
          break;
          case '-1':
          xyz = this.$t('uc.finance.xx72')
          break;
        default:
          break;
      }
return xyz
    },
   
    parseTrans(code){
      let xyz = ''
switch (code) {
  case 'YXJS':
    xyz = '游戏结算'
    break
  case 'YXXZ':
    xyz = '游戏下注'
    break
  case 'HOCZ':
    xyz = '汇旺充值'
    break
    case 'HOTX':
    xyz = '汇旺提现'
    break
    case 'UDTX':
    xyz = 'USDT提现'
    break
    case 'SDCZ':
    xyz = '手动充值'
    break
    case 'TXSB':
      xyz = '提现失败'
      break
    
  case 'HYZZ':
    xyz = this.$t('uc.finance.xx15')
    break;
    case 'YXKF':
    xyz = this.$t('uc.finance.xx27')
    break
    case 'ZCHB':
    xyz = this.$t('uc.finance.xx28')
      break
    case 'ZZCZ':
    xyz = this.$t('uc.finance.xx18')
      break
      case 'ZZTX':
    xyz = this.$t('uc.finance.xx17')
      break
      case 'CKBP':
    xyz = this.$t('uc.finance.xx19')
      break
      case 'XXCZ':
    xyz = this.$t('uc.finance.xx20')
      break
      case 'TXBP':
    xyz = this.$t('uc.finance.xx21')
      break
      case 'XXTX':
    xyz = this.$t('uc.finance.xx22')
      break
      case 'YXSF':
    xyz = this.$t('uc.finance.xx23')
      break
      case 'YXXF':
    xyz = this.$t('uc.finance.xx24')
      break
      case 'ZSLJ':
    xyz = this.$t('uc.finance.xx25')
      break
    case 'YXBF':
    xyz = this.$t('uc.finance.xx26')
      break
      case 'YXFS':
    xyz = this.$t('uc.finance.xx32')
      break
      case 'CAMCZ':
    xyz = this.$t('uc.finance.xx61')
      break
      case 'CAMTX':
    xyz = this.$t('uc.finance.xx62')
      break
      case 'CGMY':
    xyz = this.$t('common.exchange')
      break
  default:
    break;
}

return xyz

    },

    handleReachBottom () {
                return new Promise(resolve => {
                    setTimeout(() => {
                      this.$emit('nextPage', 'next')
                        resolve();
                    }, 2000);
                });
            },
    // 编辑
    gameType(type) {

      if (type == 'LC') {
        return this.$t('uc.finance.game81')
      }else if (type == 'CB') {
        return this.$t('uc.finance.game82')
      } else if (type == 'ES') {
        return this.$t('uc.finance.game83')
      }  else if (type == 'SB') {
        return this.$t('uc.finance.game84')
      }  else if (type == 'SL') {
        return this.$t('uc.finance.game85')
      }  else if (type == 'LK') {
        return this.$t('uc.finance.game86')
      }  else if (type == 'FH') {
        return this.$t('uc.finance.game87')
      }  else if (type == 'PK') {
        return this.$t('uc.finance.game88')
      }  else if (type == 'MG') {
        return this.$t('uc.finance.game89')
      }  else if (type == 'OT') {
        return this.$t('uc.finance.game90')
      }  else if (type == '91') {
        return this.$t('uc.finance.game91')
      }  else if (type == '92') {
        return this.$t('uc.finance.game92')
      }   else if (type == '100') {
        return this.$t('uc.finance.game100')
      } 
      return this.$t('uc.finance.game0')
     
    },
    // 删除
   
    // 从leancloud获取todoList
    getTodoList() {
      let param = {}
      param.pageNum = 1;
      param.pageSize = 10
      this.$http.post(this.host + this.api.uc.memberactivity, param).then(response => {
       var resp = response.body;
                    if (resp.code == "0000") {
              this.todoList = resp.data.list
                    }else
                        this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },
     getDetail() {
     
      this.$http.post(this.host + "/mdbo/getGameRecordList").then(response => {
       var resp = response.body;
                    if (resp.code == "0000") {
              this.todoList = resp.data
              this.doneList = resp.data
              // this.doneList = this.doneList.reverse()
                    }else
                        this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

    gotoSub(id){
    
      this.$router.push({path:'/subdetail', query: { gpage: id }})
    }

  }
}
</script>

<style lang='scss' scoped>
.todo-list {
  font-size: 16px;
  background-color: $color-background;
  // height: 85%;
  // min-height: calc(100vh - 50px - 60px);
  position: relative;
  overflow: auto;
  // height: 100vh;
  
  .spanStyle {
  display: inline-block;
  white-space: normal;
  width: 100%;
  overflow: hidden; /*超出的文本隐藏*/
  text-overflow: ellipsis; /* 溢出用省略号*/
  text-align: start;
  white-space: nowrap;
}


  section {
    width: 90%;
    // min-height: calc(100vh - 50px - 60px);
    position: absolute;
    // padding-bottom: 70px;
    // transition: all 0.3s ease;
    .todo-item{
  
    background: $color-box-back;
    width: 100%;
    height: 140px;
    margin-top: 10px;
    margin-left: 5%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

    .head_box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      color: #1a1306;
      font-size: medium;
      height: auto;
      // padding-top: 10px;
    }
    .head_box_1{
      display: flex;
      justify-content: space-between;
      align-items: left;
      width: 80%;
      margin-left: 10%;
      // color: #1a1306;
      font-size: 20px;
      // padding-top: 10px;
      margin-top: -10px;
    }
    .body_box{
       display: flex;
       flex-direction: column;
      color: $color-text-tag;
      font-size: 15px;
      width: 90%;
      align-items: flex-start;
      // margin-left: 5%;
      // margin-top: 20px;
      height: auto;
    
    }
    .body_box1{
       display: flex;
       flex-direction: column;
      justify-content: space-between;
      align-items:flex-start;
      color: $color-text-tag;
      font-size: medium;
      height: auto;
   
    }

    .body_box_1{
       display: flex;
       flex-direction: column;
      justify-content: space-between;
      align-items:flex-start;
      color: #2b1d04;
      font-size: 15px;
      align-items: left;
      width: 90%;
      height: auto;
      // margin-left: 5%;
      // margin-top: 20px;
    
    }

    >span{
        // display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }

    }
    .done-item {
      position: relative;
      font-size: 20px;
      color: #898988;
      width: 95%;
      height: 55px;
      line-height: 55px;
      // margin: 0 auto;
     
      // background-color: transparent;
      border-radius: 5px;
      // box-shadow: 1px 1px 5px 0 #f3f3f3;
      >span{
        // display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      &:active{
        background-color: #f0f0f0;
      }
     
    }

    .empty{
      text-align: center;
      font-size: 18px;
      color: #898989;
      // margin-top: -45px;
    }
  }
}
</style>
