<template>
<!--        <vue-aliplayer-v2-->
<!--            :source="source"-->
<!--            ref="VueAliplayerV2"-->
<!--            :options="options"-->
<!--            :forbidFastForward="forbidFastForward"-->
<!--        />-->
    <div :style="{height: height}" style="overflow: hidden">
        <div v-if="loading == true"
             v-load='true'
             load-background="rgba(0, 0, 0, .7)"
             load-type="cube-grid"
             style="height:100%;width:100%;"
        ></div>
        <video id="videoPlayer" playsinline style="width: 120%;height: 100%;margin-left: -10%;" :style="{transform: `scale(${scale})`}"></video>
    </div>


</template>

<script>
// import Hls from 'hls.js';
import {transform} from "vuejs-loadmore/jest.config";

export default {
    computed: {
        transform() {
            return transform
        }
    },


    data() {
        return {
            loading: true
        }
    },
    props: {
        source: {
            type: String,
            default: 'http://154.207.104.4:8080/live/SMcaiyuanguangjin888.flv',
        },
        height:{
            type:String,
            default: '0px'
        },
        options: {
            type: [() => {
            }],
            default: {}
        },
        forbidFastForward: {
            type: [String,Boolean],
            default: false
        },
        scale: {
            type: Number,
            default: 1
        },
    },
    mounted() {
        // if (Hls.isSupported()) {
        //     const video = document.getElementById('videoPlayer');
        //     const hls = new Hls();
        //     // hls.loadSource('webrtc://154.207.104.4/live/SMcaiyuanguangjin888.flv');
        //     hls.loadSource('webrtc://154.207.104.4/live/SMcaiyuanguangjin888.flv');
        //     hls.attachMedia(video);
        //     hls.on(Hls.Events.MANIFEST_PARSED, function () {
        //         video.play();
        //     });
        // }

        let video = document.getElementById('videoPlayer');
		// let url = 'webrtc://v.tg86.org/live/SMcaiyuanguangjin888.flv';
		let player = new JSWebrtc.Player(this.source, { video: video, autoplay: true, onPlay: (obj) => {
            this.loading = false
        } });
        player.autoplay = true
    }


}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
