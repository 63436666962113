<template>
  <div class="nav-rights">
    <!-- <List item-layout="vertical">
        <ListItem v-for="item in datalist" :key="item.id">
            <ListItemMeta  :title="item.tradeNo" :description="item.description" />
            <template slot="extra">
                <img src="../assets/netmage/lisitemback.png" width="90%" height="100%">
            </template>
        </ListItem>
    </List> -->
    <div style="height:100%">
          <iframe style="width:100%; height:100%;" :src="outurl" frameborder="0" id="gameframe"></iframe>
        </div>
   
  </div>
</template>



<script>
import {getQueryVariable} from '@/config/index'

export default {
 
 data() {
return{
outurl:''
}
 },


created: function() {
    this.initGtCaptcha();

  },


methods: {




goback() {
      //获取个人安全信息
     this.$router.back(-1)
     
    },

 initGtCaptcha() {
  document.getElementById('baidu').style.display = 'none'
  let that = this
  let tgid =  getQueryVariable("tgId")
  // let amount = getQueryVariable("amount")
  let gameid = getQueryVariable("gameId")
  let time = getQueryVariable("time")
  let sign = getQueryVariable("sign")

  // http://test.jb88.bet/?tgId=5402264229&amount=12333&gameId=80&time=1670931645&sign=6b41cdca24f6f259eb69a21743f1b95d
  let params ={}
  params.tgId = tgid
  // params.amount = Number(amount)
  params.time = Number(time)
  params.sign = sign
  params.gameId = Number(gameid)
       this.$http.post(this.host + '/tg/register', params).then(response => {

       var resp = response.body;
                    if (resp.code == "0000") {
                      if (gameid == null || gameid == '') {
                        that.$router.replace("/about");
                      }else
                        // that.outurl  = resp.data

                        this.$store.commit("setMember", resp.data);
                localStorage.setItem('TOKEN', resp.data.token);
               

                        that.goToGame()
                        
                        return
                    }else
                    that.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

    goToGame(){
      var self = this;

  
      var params = {
          };
        
          // let amount = getQueryVariable("amount")
          // params['score'] = Number(amount)
          params['ccy'] = 'USDT'
          
         
      this.$http
        .post(self.host + this.api.uc.myInnovationMinings, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            self.getGameUrl()
            // this.$router.push({ path: '/GamePage', query: { gpage: this.gameUrl } })
          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },


    getGameUrl(){
      let that = this
      let param = {}
      let gameid = getQueryVariable("gameId")
param.gameId = gameid
      this.$http
        .post(this.host + this.api.uc.myInnovationLocked, param)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            // self.user = resp.data;
            // self.usernameS = this.user.username.slice(0,1);
            that.outurl = resp.data
           
            // console.log("sdfsj11111" + self.hallUrl)

            //  this.$router.push({ path: '/GamePage', query: { gpage: self.gameUrl, gid:gameid } })
          } else {
            // self.$Message.error(resp.mesg);
            that.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    }

}


}
</script>

<style lang="scss" scoped>
.nav-rights {
  background: transparent;
  height: 100vh;
  position: relative;
  overflow: hidden;
.box222{
  position: fixed;
  top: 5px;
  right: 10px;
  width: 40px;
  height: 40px;
  z-index: 200;
  background: url(../assets/netmage/back22.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
}
</style>