<template>
  <div  class="login_form">
    <div class="headBox">
      <span>{{  }}</span>
      <span style="color:#e4c87b;font-size:20px;">
        {{$t('uc.finance.charge')}}
      </span>
       <span>{{ }}</span>
    </div>
      <div class="bill_box">
          <!-- <Select v-model="model1" style="width:80%;margin-top: 30px;color: #000;">
            <Option v-for="item in coinList" :value="item.bankName" :key="item.id">{{item.acctName}}</Option>
          </Select> -->
          <Tabs type="card" @on-click="switchAddress">
          <TabPane v-for="(tab, index) in tabList" :key="index" :label="tab.label" :name="tab.name">
        
      <section style="margin-top:10px;" v-if="tab.name == 'USDT'">
          <!-- <img :src="model1.imgPath" style="width:20px;height:20px;margin-bottom: -5px;">  {{panel1.acctName}} -->

                    <!-- <div>
                        <span style="font-size: 14px;color: #a2a2a1;">{{$t('uc.finance.personalassets')}}</span>
                    </div> -->
                    <div style="color: #eae8df;margin-left: 10px;color: #907633;  width: 100%;text-align: left;">* 网络:TRC20</div> 
                    <!-- <div>
                      <Button  style="background-color:#e5b932;border-radius: 25px; height: 40px; width: 100px; font-weight: bold; border: none;margin: 10px;margin-top: 0%;">* 网络:TRC20</Button>
                    </div> -->
                    <div class="uclass">
                        <!-- <span>{{$t('uc.finance.swapassets')}}:</span> -->
                      
                      <Button disabled style="border-radius: 10px;margin-top: 10px; background-color: transparent; width: 99%; color: #e4c87b; height: 40px;">{{panel1.acctNo}}</Button>                       
                 
                     
                        <Button style="font-size: 14px;color: #eae8df;margin-left: 60%;  font-weight: 600; letter-spacing: 2px; width: 100px; height: 40px; border-radius: 10px; margin-top: 15px;  background-color: #907633; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

                   
                    
                    </div>
                    
                    <!-- <div class="celllall">
    
     
    <Input v-model="usdtamount" type="text"  @on-change="onKeyYHKNameInput3" style="color: #898989;border-radius: 10px;  border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:85%;  margin-left: 2%; " clearable   :placeholder="$t('uc.finance.notice12345')" />
         
         
  </div> -->
                    <!-- <vue-qr :logo-src="logoSrc"
                          :size="191"
                          :margin="10"
                          :auto-color="true"
                          :dot-scale="1"
                          :text="panel1.acctNo"
                          colorDark="black"
                          colorLight="white"
                          style="background-color:azure;border-radius: 15px;margin-top: 20px;margin-bottom: 20px;"
                      />
                      <br> -->
                      <!-- <img id="frontCardImg" style="width: 180px;height: 120px;margin-top: 20px;" :src="frontCardImg">
          <div class="acc_sc">

              <Upload ref="upload1" :before-upload="beforeUpload" :on-success="frontHandleSuccess" :headers="uploadHeaders" :action="uploadUrl">
                  <Button icon="plus-round">{{$t('uc.forget.upload')}}</Button>
              </Upload>
          </div> -->
                      
                      <!-- <div style="width:60%;margin-left: 20%;margin-bottom: 20px;margin-top: 20px;">
<span style="font-size: 12px;color:#f60;">{{$t('uc.finance.notice1')}}</span>
                      </div> -->
                      <!-- <div style="margin-top:20px; margin-bottom: 20px; padding-bottom: 20px;">
 <Button style="width:80%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="usdtSubmit">{{$t('uc.forget.save')}}</Button>
</div> -->
                      <div style="color: #eae8df;;color: #907633;  width: 100%;text-align: left;margin-top: 10px;padding: 10px;letter-spacing: 1px;">
* 温馨提示：{{ $t('uc.finance.notice1') }}
                      </div>
                      
                    
      </section>

      
      <section v-else-if=" tab.name == 'HUIONE'" style="margin-top:10px;">
        <div style="color: #eae8df;margin-left: 10px;color: #907633;  width: 100%;text-align: left;">* 存款信息</div>

        <div class="celllall">
   
          <Input v-model="huioneinfo.walletName" type="text" disabled size="large" style="color: #e4c87b;border-radius: 10px; margin-top: -10px; border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  " clearable   :placeholder="$t('uc.forget.addresstip31')" />

        </div>

        <div class="celllall">
    
     
          <Input v-model="huioneinfo.walletAddress" type="text" disabled size="large" style="color: #e4c87b;;border-radius: 10px;  border-inline-width: 1px; border-style:inset; width:99%; border-color: #eae8df #898989 #898989 #eae8df;  " clearable   :placeholder="$t('uc.forget.addresstip41')" />

        </div>

        <div class="celllall">
    
     
    <Input v-model="huioneamount" type="text"  @on-change="onKeyYHKNameInput" size="large" style="color: #e4c87b;;border-radius: 10px;  border-inline-width: 1px; border-style:inset; width:99%; border-color: #eae8df #898989 #898989 #eae8df;  " clearable   :placeholder="$t('uc.finance.notice12345')" />
          <!-- <label style="margin-left: 10%;" for="exchange">≈≈ {{ exchangeAmount }}</label> -->
          <!-- <div style="margin-left: 10%;margin-top: 10px;display: flex; align-items: center;">
            <img src="../assets/newImage/coin.png" alt="" style="width: 25px;margin-right: 10px;">≈≈ {{ exchangeAmount }}
            
          </div> -->
         <span style="color: #907633;font-size: small;padding: 10px;">步骤：输入存款金额->上传转账截图->提交->等待存款到账</span>
  </div>

 
           

        <img id="frontCardImg" style="width: 180px;height: 120px;margin-top: 20px;" :src="frontCardImg">
          <div class="acc_sc" v-show="payway0">

              <Upload ref="upload1" :before-upload="beforeUpload" :on-success="frontHandleSuccess" :headers="uploadHeaders" :action="uploadUrl">
                  <Button  style="background: transparent; border: none; color: aliceblue; z-index: 100; margin-top: -90%"  icon="md-add">点击上传支付截图</Button>
              </Upload>
          </div>

      <div style="margin-top:20px; margin-bottom: 20px; padding-bottom: 20px;">
        <Button  class="btnSub"   @click="huioneSubmit">{{$t('uc.forget.save')}}</Button>
      </div>
      <!-- <div style="margin-top: 20px;margin-bottom: 20px;">
          <span style="font-size: 12px;color:#f60;">{{ $t('uc.finance.notice34') }}</span>
          
        </div> -->
    
      </section>

        <section v-else-if=" tab.name == 'chinaBank'" >
          <div class="celllall">
   
   <Input v-model="customName" type="text" disabled style="color: #898989;border-radius: 20px; border-color: #eae8df; border-inline-width: 1px; border-style:outset; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip312')" />

 </div>

 <div class="celllall">


   <Input v-model="customphone" type="text" disabled style="color: #898989;border-radius: 20px; border-color: #eae8df; border-inline-width: 1px; border-style:outset; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip412')" />

 </div>

 <div class="celllall">


<Input v-model="bankName" type="text" disabled style="color: #898989;border-radius: 20px; border-color: #eae8df; border-inline-width: 1px; border-style:outset; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip3121')" />

</div>

 <div class="celllall">


<Input v-model="rmbamount" type="text"  @on-change="onKeyYHKNameInput1" style="color: #898989;border-radius: 20px; border-color: #eae8df; border-inline-width: 1px; border-style:outset; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.finance.notice12345')" />

</div>

 <img id="frontCardImg" style="width: 180px;height: 120px;margin-top: 20px;" :src="frontCardImg">
   
       <Upload ref="upload1" :before-upload="beforeUpload" :on-success="frontHandleSuccess" :headers="uploadHeaders" :action="uploadUrl">
           <Button icon="plus-round">{{$t('uc.forget.upload')}}</Button>
       </Upload>
   

<div style="margin-top:20px; margin-bottom: 20px; padding-bottom: 20px;">
 <Button style="width:80%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="bankCardSubmit">{{$t('uc.forget.save')}}</Button>
</div>


  <p style="font-size: 12px;color:#f60;"> {{ $t('common.notice1') }}
  <Button type="text" style="color: blue;margin-left: -15px; " size="large" @click="gotoService">{{ $t('uc.finance.notice111') }}</Button> </p>

        </section>

        <section v-else-if=" tab.name == 'thirdPay'" >

<div style="padding-left: 20px;padding-right: 20px; margin-bottom: 80px;">
  <p style="float: left;">
    {{ $t('common.message1122') }}
  </p> 
  <Divider />

  <Row :gutter="16">
        <Col span="6" >
          <Card :class="payway0==true?'cardback':''" style="width: 80px;height: 80px;" shadow :padding="10">
        <div @click="payMethod(0)">
            <img src="../assets/newImage/real.png" width="45px">
            {{ $t('uc.finance.xx33') }}
        </div>
    </Card>
        </Col>
   
    </Row>

   
</div>
<div class="celllall">

<Input v-model="rmbamount1" type="text"  @on-change="onKeyYHKNameInput2" style="color: #898989;border-radius: 20px; border-color: #eae8df; border-inline-width: 1px; border-style:outset; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.finance.notice12345')" />

</div>

<div style="margin-top:20px; margin-bottom: 20px; padding-bottom: 20px;">
        <Button style="width:80%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="thirdPay">{{$t('uc.forget.save')}}</Button>
      </div>
      <div style="margin-top: 20px;margin-bottom: 20px;">
         
         <span style="font-size: 12px;color:#f60;">{{ $t('uc.finance.notice35') }}</span>
       </div>
        </section>
       
      </TabPane>

    
        </Tabs>
          <Main-tab-bar></Main-tab-bar>
      </div>
 
    <!-- model1 -->
    <Modal v-model="modal1" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-mail" size="20" color="#00b5f6;" />
        <span>{{$t('uc.finance.recharge.coderechargetip')}}</span>
      </p>
      <div style="text-align:center">
        <Form ref="formValidate" :label-width="0">
          <FormItem>
            <Input v-model="withdrawCode" :placeholder="$t('uc.finance.recharge.coderechargetip')"></Input>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button type="primary" size="large" long @click="getCodeInfo">{{$t('uc.finance.withdraw.submit')}}</Button>
      </div>
    </Modal>

    <!-- model2 -->
    <Modal v-model="modal2" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-mail" size="20" color="#00b5f6;" />
        <span>{{$t('uc.finance.recharge.rechargeconfirm')}}</span>
      </p>
      <div style="text-align:center">
        <p><span>{{$t('uc.finance.recharge.symbol')}}: </span><span>{{withdrawCodeInfo.coin.unit}}</span></p>

        <p><span>{{$t('uc.finance.recharge.amount')}}: </span><span>{{withdrawCodeInfo.withdrawAmount}}</span></p>

      </div>
      <div slot="footer">
        <Button type="primary" size="large" long @click="submitCode">{{$t('uc.finance.withdraw.submit')}}</Button>
      </div>
    </Modal>


    <Modal
        title="设置资金密码"
        v-model="modal7"
        :closable="false"
        :mask-closable="false"
        @on-ok="gotoPassword"
       @on-cancel='gotoHome'
        
       >
       <div style="text-align:center">
            <p>为了保证客户资金安全，请先设置资金密码</p>
        </div>
    </Modal>
    

    <div v-if="loading == true"
    v-load= 'true'
    load-background="rgba(0, 0, 0, .7)"
    load-type="cube-grid"
    style="height:200vh;width:100%;"
></div>

  </div>
</template>
<script>
// import Vue from "vue";
// import VueQriously from "vue-qriously";
// Vue.use(VueQriously);
import MainTabBar from '../components/Tabbar/MainTabBar'
// import VueQr from 'vue-qr'
import * as imageConversion from 'image-conversion'

export default {
    components: {
MainTabBar,
// VueQry
  },
  data() {
    return {
      modal1: false,
      modal2: false,
      withdrawCode: "",
      fundpwd: "",
      userNo: '',
      accountType: 0,
      memoCode: "",
      huioneName:'',
      huionephone:'',
      huioneamount : '0',
      exchangeAmount:0,
      customName:'',
      customphone:'',
      rmbamount : 0,
      bankName:'',
      minRechargeAmount:"0.001",
      isShowGetAddress: false,
      isShowEwm: false,
      dataCount: 0,
      loading: false,
      huioneinfo:{
        walletAddress :'',
           walletName :'',
           bankName:''
      },
      payway0:true,
      payway1:false,
      payway2:false,
      payway3:false,
      payway4:false,
      payway5:false,
      payway6:false,
      payway7:false,
      payway8:false,
      rmbamount1: '',
      
      tabList: [
      // {
      //                   label: '银行卡入款',
      //                   name: 'chinaBank'
      //               },
      //               {
      //                   label: '三方入款',
      //                   name: 'thirdPay'
      //               },
                    {
                        label: '汇旺存款',
                        name: 'HUIONE'
                    },
                    {
                        label: 'USDT存款',
                        name: 'USDT'
                    },
                    
                ],
      qrcode: {
        value: "",
        size: 220,
        coinName: "",
        unit: ""
      },
      usdtamount:0,
      paycode:0,
      huioneNameaddress:'',
      model1:'USDT',
      uploadUrl: this.host + '/upload/app/v1/uploadSingleImg',
       windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
panel1:{
  acctNo:''
},
panel2:'',
modal7: false,

      coinType: "",
      coinList: [
        {'tab': '银行卡入款'},{'tab': '三方入款'},{'tab': '汇旺存款'},{'tab': 'USDT存款'}
      ],
      tableRecharge: [],
      allTableRecharge: [],
      uploadHeaders: { "x-auth-token": localStorage.getItem("TOKEN") },
      frontCardImg: require("../assets/netmage/blackss.png"),
      
      withdrawCodeInfo: {
        coin: {
          unit: ""
        }
      },
      withdrawAddressArray:[],
      value1:'',

      tabBarValue: '1',
      
        logoSrc: '',


   tabBarItems: [
				{ title: '首页', icon: 'ly-icon ly-icon-home', value: 'about' },
				{ title: '充值', icon: 'ly-icon ly-icon-icon--', value: 'charge' },
				{
					title: '提现',
					icon: 'ly-icon ly-icon-sousuo',
					value: 'withdraw',
				},
				{ title: '我', icon: 'ly-icon ly-icon-wode', value: 'mine' }
			]
   
    };
  },
  methods: {
    gotoService(){
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push('/customservice')
    },
    gotoPassword(){
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push('/set')
    },

    gotoHome(){
      this.$router.replace({name: "About"});
    },
    clearAllPay(){
      this.payway0 = false
      this.payway1 = false
      this.payway2 = false
      this.payway3 = false
      this.payway4 = false
      this.payway5 = false
      this.payway6 = false
      this.payway7 = false
      this.payway8 = false
    
    },

    exchange(){

this.$router.push("/exchange");

},

    

    switchAddress(index){
      let self = this
      if (index == 'chinaBank') {
        this.withdrawAddressArray.forEach(element => {
          if (element.addressType == 0) {
            self.customName = element.walletName
            self.customphone = element.walletAddress
            self.bankName = element.bankName
          }
        });
      }else if (index == 'HUIONE') {
        this.withdrawAddressArray.forEach(element => {
          if (element&&element.addressType == 1) {
            self.huioneinfo.walletAddress = element.walletAddress
            self.huioneinfo.walletName = element.walletName
            self.huioneinfo.bankName = element.bankName
          }
        });
      }else if (index == 'USDT') {
        this.withdrawAddressArray.forEach(element => {
        
          if (element && element.ccy == 'USDT') {
            self.panel1.acctNo = element.walletAddress
          }
        });
      }
    },
    payMethod(num) {
      this.clearAllPay()
     let that = this
      switch (num) {
        case 0:
        that.payway0 = true
        that.paycode = 31888
          break;
          case 1:
          that.paycode = 306
          that.payway1 = true
          break;
          case 2:
          that.paycode = 212
          that.payway2 = true
          break;
          case 3:
          that.paycode = 401
          that.payway3 = true
          break;
          case 4:
          that.paycode = 701
          that.payway4 = true
          break;
          case 5:
          that.paycode = 402
          that.payway5 = true
          break;
          case 6:
          that.paycode = 222
          that.payway6 = true
          break;
          case 7:
          that.paycode = 223
          that.payway7 = true
          break;
          case 8:
          that.paycode = 205
          that.payway8 = true
          break;
      
        default:
          break;
      }
    },
    beforeUpload(data) {
      return new Promise((resolve) => {
        
        // console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
        imageConversion.compressAccurately(data, 100).then(res => { // console.log(res)
          res = new File([res], 'recharge', { type: res.type, lastModified: Date.now() })
          resolve(res)
        })
      })


      // if (data && data.size >= 1024000 * 2) {
      //   this.$Message.error("上传图片大小不能超过2M");
      //   return false;
      // }
    },
    getPayAddress(){
let self = this
let param = {}
param.status = 0
      this.$http
        .get(this.host + '/funds/getAddress', param)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
          // 銀行卡 0 匯旺 1 usdt 2 大象 3
self.withdrawAddressArray = resp.data
self.getUdunAddress()
          } else {
            self.$Message.error(resp.mesg);
          }
        });
     
    },

    getUdunAddress(){
let self = this
let param = {}
param.status = 0
      this.$http
        .get(this.host + '/funds/getUdunAddress', param)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
          // 銀行卡 0 匯旺 1 usdt 2 大象 3
self.withdrawAddressArray.push(resp.data)
self.switchAddress('HUIONE')  
          } else {
            self.$Message.error(resp.mesg);
          }
        });
     
    },

//     uploadUrl(){

//       let self = this
// let param = {}
//       this.$http
//        .post(self.host + '/upload/app/v1/uploadSingleImg', param)
//        .then(response => {
//          var resp = response.body;
//          if (resp.code == "0000") {
         
         
         
//          } else {
//            self.$Message.error(resp.mesg);
//          }
//        });
//     },
onKeyYHKNameInput3(){
  let value = '' + this.usdtamount
       let value1= 0
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
          value1 = value * 7
        }
        this.$nextTick(function(){
          this.usdtamount = Number(value).toLocaleString()
          this.exchangeAmount = Number(value1).toLocaleString()
        })
},

onKeyYHKNameInput() {
       let value = '' + this.huioneamount
       let value1= 0
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
          value1 = value * 7
        }
        this.$nextTick(function(){
          this.huioneamount = Number(value).toLocaleString()
          this.exchangeAmount = Number(value1).toLocaleString()
        })
     },

     onKeyYHKNameInput1() {
       let value = '' + this.rmbamount
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
        }
        this.$nextTick(function(){
          this.rmbamount = Number(value).toLocaleString()
          
        })
     },

     onKeyYHKNameInput2() {
       let value = '' + this.rmbamount1
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
        }
        this.$nextTick(function(){
          this.rmbamount1 = Number(value).toLocaleString()
          
        })
     },

     thirdPay(){

      // /funds/jbcn-deposit    String code   BigDecimal amount   String ccy  
        if (!this.rmbamount1 || this.paycode == 0) {
          this.$Message.error(this.$t('uc.finance.notice123'))
          return
        }
        let self = this;
     let param = {}
param.ccy = 'USDT'
param.code = this.paycode


var num = this.rmbamount1.replace(/\D/g,'');
    console.log('string without commas', num);  

    var num2 = parseInt(num);
   

param.amount = num2
if (param.amount &&  param.amount > 0) {
  console.log('numeric', num2);  
}else{
  this.$Message.error(this.$t('uc.finance.notice12345'))
  return
}

        this.$http
       .post(self.host + this.api.uc.recharge, param)
       .then(response => {
         var resp = response.body;
         if (resp.code == "0000") {
        
         self.$Message.success(this.$t('uc.finance.notice1234'))
         self.clearValues()
         } else {
           self.$Message.error(resp.mesg);
         }
       });
    },

    usdtSubmit(){

    },

     bankCardSubmit(){
      if (!this.customName || !this.customphone || !this.frontCardImg || !this.bankName || !this.rmbamount) {
        this.$Message.error(this.$t('uc.finance.notice123'))
        return
      }

      var self = this;
     let param = {}
param.ccy = 'UDST'
param.paymentName = this.customName
param.paymentAddress = this.customphone
param.imageUrl = this.frontCardImg
param.bankName = this.bankName

var num = this.rmbamount.replace(/\D/g,'');
    console.log('string without commas', num);  

    var num2 = parseInt(num);
    console.log('numeric', num2);  

param.amount = num2
if (param.amount &&  param.amount > 0) {
  console.log('numeric', num2);  
  
}else{
  this.$Message.error(this.$t('uc.finance.notice12345'))
  return
}


// {
//   "searchValue": null,
//   "createBy": null,
//   "createTime": "2022-11-14 07:42:07",
//   "updateBy": null,
//   "updateTime": "2022-11-18 03:04:09",
//   "remark": "老年人专属",
//   "params": {},
//   "id": 1,
//   "addressType": 1,
//   "bankName": "汇旺",
//   "walletAddress": "4365464767657",
//   "walletName": "顶顶顶",
//   "ccy": "USD",
//   "ccySymbol": null,
//   "status": 0
// }

     this.$http
       .post(self.host + '/funds/addDepositOrder', param)
       .then(response => {
         var resp = response.body;
         if (resp.code == "0000") {
        
         self.$Message.success(this.$t('uc.finance.notice1234'))
         self.clearValues()
         } else {
           self.$Message.error(resp.mesg);
         }
       });

     },

    huioneSubmit(){
      
      if (!this.huioneinfo.walletAddress || !this.huioneinfo.walletName || !this.frontCardImg || !this.frontCardImg.includes('https:')) {
        this.$Message.error(this.$t('uc.finance.notice123'))
        return
      }
      var self = this;
     let param = {}
param.ccy = 'USDT'
// param.paymentName = this.huioneName
// param.paymentAddress = this.huionephone
param.remark = this.frontCardImg
param.bankCode = "HUIONE"
param.payChannal = 'huione'
// {
//   "amount": 0,
//   "bankCode": "string",
//   "ccy": "string",
//   "depositAddress": "string",
//   "depositName": "string",
//   "pass": "string",
//   "payChannal": "string",
//   "remark": "string"
// }

var num = this.huioneamount.replace(/\D/g,'');
    console.log('string without commas', num);  

    var num2 = parseInt(num);
    console.log('numeric', num2);  

param.amount = num2
if (param.amount &&  param.amount > 0) {
  param.depositAddress = this.huioneinfo.walletAddress
param.depositName = this.huioneinfo.walletName
  
}else{
  this.$Message.error(this.$t('uc.finance.notice12345'))
  return
}


// {
//   "searchValue": null,
//   "createBy": null,
//   "createTime": "2022-11-14 07:42:07",
//   "updateBy": null,
//   "updateTime": "2022-11-18 03:04:09",
//   "remark": "老年人专属",
//   "params": {},
//   "id": 1,
//   "addressType": 1,
//   "bankName": "汇旺",
//   "walletAddress": "4365464767657",
//   "walletName": "顶顶顶",
//   "ccy": "USD",
//   "ccySymbol": null,
//   "status": 0
// }

     this.$http
       .post(self.host + '/funds/addDepositOrder', param)
       .then(response => {
         var resp = response.body;
         if (resp.code == "0000") {
        
         self.$Message.success(this.$t('uc.finance.notice1234'))
         self.clearValues()
         } else {
           self.$Message.error(resp.mesg);
         }
       });
    },

    clearValues(){
      this.huioneName = ''
this.huionephone = ''
this.frontCardImg = require("../assets/netmage/blackss.png")
this.huioneamount = 0
this.payway0 = true
    },

    isPicNull(val) {
      if (val == null) {
        return true
      }
      
      return false;
    },
    getMemberInfo1() {
      //获取个人安全信息
      var self = this

      this.$http.post(this.host + this.api.uc.memberInfo).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          self.tempmember = resp.data
          if (resp.data.jbPassword) {
            self.modal7 = false
          }else
          self.modal7 = true
        } else {
          self.$Message.error(resp.mesg)
        }
      })
    },

    handleTabBarChange(value) {
			console.log('LyTabBar change:', value);

              if (value == 'about') {
                this.$router.push("/About");
                
              }else if (value == 'withdraw') {
                this.$router.push("/Withdraw");
                
              }
             
		},

    frontHandleSuccess(res, file,fileList) {
      this.$refs.upload1.fileList=[fileList[fileList.length-1]];
      this.payway0 = false
      if (res.code == '0000') {
        this.frontCardImg = this.imgPreview = res.data.url;
      } else {
        this.$Message.error(res.message);
      }
    },
   
    changePage(index) {
      this.getList(index);
    },
    caclHeight() {
      // this.windowHeight += 200
      console.log("wwwe" + this.windowHeight)
      return `background-color: #0b1520`
    },
    getCurrentCoinRecharge() {
      if (this.coinType != "") {
        var temp = [];
        for (var i = 0; i < this.allTableRecharge.length; i++) {
          if (this.allTableRecharge[i].symbol == this.coinType) {
            temp.push(this.allTableRecharge[i]);
          }
        }
        this.tableRecharge = temp;
      } else {
        this.tableRecharge = this.allTableRecharge;
      }
    },
    showEwm() {
      this.isShowEwm = !this.isShowEwm;
    },
    onCopy(e) {
      this.$Message.success(
        this.$t("uc.finance.copysuccess") + e.text
      );
    },
    onError() {
      this.$Message.error(this.$t("uc.finance.copyfailed"));
    },
    changeCoin(value) {
      for (var i = 0; i < this.coinList.length; i++) {
        if (this.coinList[i].coin.unit == value) {
          this.qrcode.value = this.coinList[i].address;
          this.qrcode.coinName = this.coinList[i].coin.name.toLowerCase();
          this.qrcode.unit = this.coinList[i].coin.unit;
          this.minRechargeAmount = this.coinList[i].coin.minRechargeAmount;
          if(this.coinList[i].coin.accountType == 1) {
            this.qrcode.value = this.coinList[i].coin.depositAddress;
            this.memoCode = this.coinList[i].memo;
            this.accountType = 1;
          }else{
            this.accountType = 0;
          }

          if(this.qrcode.value == "" || this.qrcode.value == null || this.qrcode.value == undefined){
            this.isShowGetAddress = true;
          }else{
            this.isShowGetAddress = false;
          }
        }
      }
      this.getCurrentCoinRecharge();
    },
    openCodeModal(){
      this.modal1 = true;
      this.withdrawCode = "";
    },
    getCodeInfo(){
      if(this.withdrawCode == "") {
        this.$Message.warning(this.$t("uc.finance.recharge.coderechargetip"));
        return;
      }
      let param = {};
      param["withdrawCode"] = this.withdrawCode;

      this.$http
        .post(this.host + "/uc/withdrawcode/apply/info", param)
        .then(response => {
          var resp = response.body;
          if (resp.code == 0) {
            this.withdrawCodeInfo = resp.data;

            this.modal1 = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
      this.modal1 = false;
      this.modal2 = true;
    },
    submitCode(){
      if(this.withdrawCode == "") {
        this.$Message.warning(this.$t("uc.finance.recharge.coderechargetip"));
        return;
      }
      let param = {};
      param["withdrawCode"] = this.withdrawCode;

      this.$http
        .post(this.host + "/uc/withdrawcode/apply/recharge", param)
        .then(response => {
          var resp = response.body;
          if (resp.code == 0) {
            this.$Message.success(this.$t("uc.finance.recharge.rechargesuccess"));
            this.modal1 = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
      this.modal2 = false;
    },
    
    getMoney() {
      //获取
      this.$http.post(this.host + this.api.uc.wallet).then(response => {
        var resp = response.body;
        if (resp.code == 0) {
          for (let i = 0; i < resp.data.length; i++) {
            var coin = resp.data[i];
            if (coin.coin.canRecharge == 1) {
              this.coinList.push(coin);
              console.log(coin);
            }
          }
          this.changeCoin(this.coinType);
        } else {
          this.$Message.error(resp.message);
        }
      });
    },
    getList() {
      //获取tableRecharge
      let memberId = 0;
      console.log(memberId)
      !this.$store.getters.isLogin && this.$router.push("/login");
      this.$store.getters.isLogin && (memberId = this.$store.getters.member.id);
      this.$http.post(this.host + this.api.uc.paydividends).then(response => {
          var resp = response.body;
          if (resp.code == '0000') {
            if (resp.data) {
            
              this.coinList = resp.data
              let lang = localStorage.getItem('LANGUAGE') || 'zh_CN';
             
              if (lang == 'zh_HK') {
                lang = 'zh_TW'
              }
              
              resp.data.forEach(element => {
                if (element.acctName == 'USDT') {
                  this.panel1 = element
                } else {
                  this.panel2 = element
                }
                let xxy = JSON.parse(element.bankLang)
                element.acctName = xxy[lang]
              });
            }
            this.loading = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
    },
    getMoneyback(){
    // let self = this;
    // this.$http
    //     .post(this.host + this.api.uc.apply)
    //     .then(response => {
    //       var resp = response.body;
    //       if (resp.code == "0000") {
    //         // this.getMember();
    //         // self.$Message.success(resp.mesg);
    //       } else {
    //         self.$Message.error(resp.mesg);
    //       }
    //     });
  },
    // getMember() {
    //   let self = this;
    //   this.$http.post(this.host + "/uc/approve/security/setting").then(response => {
    //       var resp = response.body;
    //       if (resp.code == 0) {
    //         if (resp.data.realName == null || resp.data.realName == "") {
    //           // 判断是否实名认证，未认证跳转到实名认证页面；
    //           this.$Message.success(this.$t("otc.publishad.submittip1"));
    //           self.$router.push("/uc/safe");
    //         } else if (resp.data.phoneVerified == 0) {
    //           // 判断是否是手机号0，1，未认证跳转到实名认证页面；
    //           this.$Message.success(this.$t("otc.publishad.submittip2"));
    //           self.$router.push("/uc/safe");
    //         } else if (resp.data.fundsVerified == 0) {
    //           // 判断是否设置交易密码，未认证跳转到实名认证页面；
    //           this.$Message.success(this.$t("otc.publishad.submittip3"));
    //           self.$router.push("/uc/safe");
    //         }
    //       } else {
    //         this.$Message.error(resp.message);
    //       }
    //     });
    // }
  },
  created() {
    
    this.getMemberInfo1();
    this.getPayAddress();
    this.getUdunAddress();

    // this.getList();
    // this.getMoneyback();

  },
  computed: {
   
  }
};
</script>
<style scoped lang="scss">


.celllall{
  width: 100%;
 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  // margin-left: 20px;
}

.table-inner {
  position: relative;
  text-align: left;
  border-radius: 3px;
}
.uclass1{
  color:rgb(133, 128, 128);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  padding-right: 10%;
  padding-left: 10%;
}
.uclass {
  color:rgb(133, 128, 128);
  font-size: 16px;
  
  // background:url(../assets/netmage/usdt_bb.png); 
  // background-size: contain;
  // width: 100%;
}

.xyzbox {
 
  color: #000;



}

.action-inner {
  width: 100%;
  display: table;
}

// .demo-tab-bar {
// 	position: fixed;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// }

.action-inner .inner-box {
  display: table-cell;
  width: 100%;
}

.action-box .title .copy {
  user-select: text;
}

a.link-copy {
  font-size: 14px;
  margin-left: 20px;
  color: #f0a70a;
}

.hb-night a {
  text-decoration: none;
  color: #7a98f7;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.action-box .title a.link-qrcode {
  margin-left: 20px;
  font-size: 14px;
  position: relative;
  color: #f0a70a;
}

.cardback {
  background-image: url(../assets/newImage/selected.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.action-box .subtitle {
  font-size: 12px;
  margin-top: 30px;
}

.action-content {
  width: 100%;
  margin-top: 30px;
  /* overflow: hidden; */
  display: table;
  color: #ccc;
}

.action-box .title {
  margin-top: 20px;
  font-size: 20px;
  user-select: none;
}

.action-box .title .show-qrcode {
  position: absolute;
  top: -100px;
  left: 40px;
  padding: 10px;
  background: #FFF;
}

.action-inner .inner-box.deposit-address {
  width: 80%;
}

p.describe {
  font-size: 16px;
  font-weight: 600;
}
.merchant-icon {
  display: inline-block;
  margin-left: 4px;
  background-size: 100% 100%;
}

.merchant-icon.tips {
  width: 4px;
  height: 22px;
  margin-right: 10px;
  background: #f0a70a;
}

.bill_box {
  width: 96%;
  height: 90vh;
  // background: url(../assets/netmage/hhss.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 100% 100% ;
  margin-left: 2%;
  margin-top: 70px;
// overflow: hidden; 
}

.nav-right {
  height: auto;
  overflow: hidden;
  padding: 0 15px;
}

/deep/ .ivu-select-selection {
   
    // background-color: #e5b932;
    border-radius: 15px;
    margin-top: 70px;
    color: #000;
    font-weight: bolder;
    
}



/deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
  color: #d2b965;
}


/deep/ .ivu-tabs.ivu-tabs-card{
  .ivu-tabs-bar .ivu-tabs-tab-active {
   
    border-color: #3e3e3f;
    background: #e4c87b;
}

.ivu-tabs-bar  .ivu-tabs-tab{
  background: #e4c87b;
  border-color: #3e3e3f;
  // padding: 12px 16px;
}
}





.btnSub{
  width:75%; 
  height: 40px; 
  background-color: #e5b932; 
  font-size: 14px; 
  border: none;  
  color: #553a25; 
  font-weight: 600;
  border-radius: 25px;
  background: url(../assets/me/logout-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  letter-spacing: 2px;
}
 

.order_box a {
  color: #8994a3;
  font-size: 16px;
  padding: 0 30px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 54px;
  display: inline-block;
}

.order_box .search {
  position: absolute;
  width: 300px;
  height: 32px;
  top: 12px;
  right: 0;
  display: flex;
  /* border: #c5cdd7 solid 1px; */
}
a.withdrawcoderecharge{
font-weight:normal;line-height: 40px; color: #f0a70a;width: 160px;height: 40px;border: 1px solid #f0a70a;display:inline-block;text-align:center;
}
.withdraw {
  background: url("../assets/bg.png") no-repeat;
  //  background: rgb(51, 49, 49);
  background-size: cover;
  // height: 10rem;
  position: relative;
  overflow: hidden;
}
</style>

<style scoped lang="less">

// class="ivu-input ivu-input-default"
  /deep/ .ivu-input{
  background: transparent;
  border: 0;
  border-radius: 25px;
  
}
    /deep/ .ivu-input-wrapper{
      border: 0.1ch;
    }


</style>
