<template>

<div class="login_form ">

<div class="box">
  <img src="../assets/newImage/back.png" alt="" style="width:20px;height:15px;" @click="goback">
  <span style="color:aliceblue;font-size:20px;margin-left: 40px;">
    {{$t('uc.finance.promotion')}}
  </span>
</div>
<div class="login_right">
    <vue-qr :logo-src=logoSrc
     :size="300"
     :margin="10"
     :auto-color="true"
     :dot-scale="1"
     :text="appSrc"
     colorDark="black"
     colorLight="white"
     ref='Qrcode'
     style="background-color:azure;border-radius: 15px;margin-top: 20px;margin-bottom: 20px;"
/>

<div>
  <Button  @click="downloadImg">{{$t('uc.finance.promotion2')}}</Button>
</div>

<div style="margin-top:20px; color:aliceblue; font-size: larger;">
  <span>{{$t('uc.finance.promotion1')}}</span>
  <br>
  <span>{{membercode}}</span>
</div>


                      <div class="uclass">
                        <span>{{$t('uc.finance.promotion3')}}:</span>
                        <br>
                     
                      <Button  disabled style="border-radius: 25px;background-color: transparent;">{{appSrc}}</Button>                       
                   
                      <Button style="font-size: 14px;color: #000; font-weight: bold; border-radius: 25px;  background-color: #e5b932; border: none;" v-clipboard:copy="appSrc" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>
                    
                    </div>




  </div>


</div>

 
</template>

<script>
  import VueQr from 'vue-qr'
  export default {
    components: {VueQr},

      data () {
  return{
    logoSrc: require("../assets/netmage/1024.png"),
  
  appSrc: '',
  membercode: ''
  }
        
      },
  
     
      created() {
      this.membercode =  this.$store.getters.member.userNo

      this.appSrc = 'https://www.jb.bet/?agentId='+this.membercode
  // this.logoSrc = '../assets/netmage/1024.png'
  },

methods: {
  goback() {
      //获取个人安全信息
     this.$router.back(-1)
     
    },
  downloadImg (link_id) {
    console.log(link_id)
    const iconUrl = this.$refs['Qrcode'].$el.src
    const a = document.createElement('a')
    const event = new MouseEvent('click')
    a.download = link_id
    a.href = iconUrl
    a.dispatchEvent(event)
  },
  onCopy(e) {
      this.$Message.success(
        this.$t("uc.finance.copysuccess") + e.text
      );
    },
    onError() {
      this.$Message.error(this.$t("uc.finance.copyfailed"));
    },

  }


  }
  </script>

<style lang="scss" scoped>
.login_form {
   background: #0b1520;
  height: 800px;
  position: relative;
  overflow: hidden;

   .login_right {
    
     padding: 20px 30px 20px 30px;
    position: absolute;
    background: #17212e;
    width: 350px;
    height: 600px;
    left: 5%;
    top: 15%;
    border-top: 4px solid #f4c772;
    border-radius: 5px;
  
  }

  .box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;
  background: url(../assets/netmage/xxyz.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 200;
   width: 100%;
}

.uclass {
  color:rgb(133, 128, 128);
  font-size: 16px;
  // background:url(../assets/netmage/usdt_bb.png); 
  // background-size: contain;
  // width: 100%;
}
}
</style>