<template>
  <div class="me">
    <div class="me__user">
      <img src="../assets/me/avatar.png"  alt="Avatar">
      <div class="me__user--info">
        <div>{{ userNo }}</div>
        <div>欢迎来到金贝在线投注系统！</div>
      </div>
    </div>
    <div class="me__amount">
      <div>{{ moneybab.amount }}</div>
    </div>
    <div class="me__actions">
      <div class="me__actions--title">* 常用功能</div>
      <div class="me__actions--content">
        <div v-for="(action, key) in actions" :key="key" class="--action" @click="gotoPage(action.link)">
          <img :src="require(`../assets/me/${action.icon}.png`)" :alt="action.icon">
          <div>{{ action.title }}</div>
        </div>
      </div>
    </div>
    <div class="me__logout" :style="logoutStyle" @click="logout">
      <div>退出登入</div>
    </div>
    <Main-tab-bar />
  </div>
</template>

<script>
import MainTabBar from '../components/Tabbar/MainTabBar'

export default {
  components: {
    MainTabBar,
  },

  data() {
    return {
      theme1: 'dark',
      moneybab: '',
      isagent: '',
      userNo: '',
      logoutStyle: {},
      actions: [
        {
          title: '交易记录',
          icon: 'dwrecord',
          link: '/dwrecords',
        },
        {
          title: '游戏记录',
          icon: 'gamedetail',
          link: '/gamedetail',
        },
        {
          title: '账户管理',
          icon: 'cart',
          link: '/cart',
        },
        {
          title: '密码修改',
          icon: 'set',
          link: '/setpage',
        },
        // {
        //   title: '兑汇',
        //   icon: 'transfer',
        //   link: '/exchange',
        // },
      ],
    }
  },

  created() {
    // this.getMoneyback();
    this.getMember()
    this.getMemberInfo()
  },
  mounted() {
    this.getElementSize()
    window.addEventListener('resize', this.getElementSize)
  },
  destroyed() {
    window.removeEventListener('resize', this.getElementSize)
  },
  methods: {
    // changeItem(name) {
    //   if (name == '1') {
    //     this.$router.push('/Recharge')
    //   } else {
    //     this.$router.push('/Withdraw')
    //   }
    // },
    logout() {
      var self = this
      this.$http.post(self.host + this.api.uc.toppromotionmonth).then((response) => {
        var resp = response.body
        if (resp.code == '0000') {
          self.$Message.success(resp.mesg)
          self.$store.commit('setMember', null)
          localStorage.setItem('MEMBER', null)

          localStorage.setItem('TOKEN', null)
          localStorage.removeItem('USERKEY', null)

          self.$router.replace({path: '/'})
        } else {
          self.$Message.error(resp.mesg)
          // this.$Message.error(this.$t('common.logintip'));
        }
      })
    },
    gotoPage (link) {
      this.$router.push(link)
    },
    // gotocharge() {
    //   this.$router.replace('/Recharge')
    // },
    // gotoMoney() {
    //   this.$router.push('/dwrecords')
    // },
    // gotoWithdraw() {
    //   this.$router.replace('/Withdraw')
    // },
    // gotoBill() {
    //   this.$router.push('/finde')
    // },
    // gotoGameBill() {
    //   this.$router.push('/gamedetail')
    // },
    // addAddress() {
    //   this.$router.push('/cart')
    // },
    // gotoSet() {
    //   this.$router.push('/setpage')
    // },
    // agentData() {
    //   this.$router.push('/agentdata')
    // },
    // personalInfo() {
    //   this.$router.push('/personalInfo')
    // },
    // promotion() {
    //   // this.$router.push({ path: '/promotion', query: { gpage: this.$store.getters.member.userNo } })
    //   this.$router.push('/promotion')
    // },
    // getMoneyback() {
    //   //  var self = this;
    //   //     this.$http
    //   //         .post(this.host + this.api.uc.apply)
    //   //         .then(response => {
    //   //           var resp = response.body;
    //   //           if (resp.code == "0000") {
    //   //             this.getMember();
    //   //           } else {
    //   //             self.$Message.error(resp.mesg);
    //   //           }
    //   //         });
    // },
    getMemberInfo() {
      //获取个人安全信息
      var self = this

      this.$http.post(self.host + this.api.uc.memberInfo).then((response) => {
        var resp = response.body
        if (resp.code == '0000') {
          localStorage.setItem('MEMBER', null)
          self.$store.commit('setMember', resp.data)
          self.userNo = resp.data.userNo
          self.isagent = resp.data.userType
        } else {
          self.$Message.error(resp.mesg)
        }
      })
    },
    getMember() {
      //获取个人安全信息
      var self = this

      this.$http.post(self.host + this.api.uc.personalWallet).then((response) => {
        var resp = response.body
        if (resp.code == '0000') {
          resp.data.forEach((element) => {
            if (element.ccy == 'USDT') {
              self.moneybab = element
            }
          })
        } else {
          self.$Message.error(resp.mesg)
        }
      })
    },
    getElementSize() {
      const windowWidth = window.innerWidth
      const logoutHeight = Math.floor(windowWidth * 0.7 * 132 / 858) // 背景圖比例 858*132
      this.logoutStyle = { height: `${logoutHeight}px`, lineHeight: `${logoutHeight}px`}
    },
  },
}
</script>

<style lang="scss" scoped>

.covef{
  position: absolute;
  background: url(../assets/newImage/1213.png);
  background-position: center;
  background-size: 100% 112%;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
}

.me {
  height: 100vh;
  padding-top: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: url("../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;

  

  &__user {
    display: flex;
    padding: 20px;

    img {
      width: 70px;
      height: auto;
    }

    &--info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 12px;
      font-size: 18px;

      & > div:not(:first-child) {
        color: #b29959;
        font-size: 14px;
      }
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px;
    padding: 1% 0 1% 23%;
    color: #edd384;
    background: url("../assets/me/amount-bg.png") no-repeat;
    background-size: 100% 100%;
    font-size: 30px;
    height: 50px;
  }

  &__actions {
    margin-top: 20px;

    &--title {
      margin-left: 10px;
      font-size: 16px;
      text-align: left;
    }

    &--content {
      display: flex;
      flex-wrap: wrap;
      margin: 8px;
      margin-top: 1px;
      padding: 15px 0;
      background-color: #282828;
      border: 1px solid #666;
      border-radius: 8px;

      .--action {
        flex-basis: 25%;
        padding: 10px;

        div {
          margin: 8px 0;
        }
      }

      img {
        width: 48px;
        height: auto;
      }
    }
  }

  &__logout {
    position: absolute;
    bottom: 20%;
    left: 50%;
    width: 75%;
    height: 66px;
    background: url("../assets/me/logout-bg.png") no-repeat;
    background-size: 100% 100%;
    transform: translateX(-50%);

    div {
      color: #553a25;
      font-size: 20px;
      letter-spacing: 2px;
    }
  }
}



// .login_form {
//   background: rgb(51, 49, 49);
//   background-size: cover;
//   height: 100vh;
//   position: relative;
//   overflow: hidden;
// }

// .mc_card {
//   width: 95%;
//   height: 200px;
//   margin-left: 3%;
//   margin-top: 20px;
//   background: url(../assets/netmage/cardx.png);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   border: none;
// }

// .celllall {
//   width: 100%;
//   height: 50px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background: url(../assets/netmage/xxyz.png);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   color: #f4c46f;
//   font-size: 18px;
// }
</style>
