<template>
 
  <div class="login_form">

    <div class="headBox">
       <img src="../assets/newImage/back.png" alt="" style="width:20px;height:15px;" @click="goback">
      <span>
        {{$t('uc.finance.addaddress')}}
      </span>
      <span>{{  }}</span>
    </div>
   
      <div class="rightarea">
       
        <!-- <Select v-model="model1" style="width:80%;" disabled>
          <Option v-for="item in coinList" :value="item.bankName" :key="item.id">{{item.acctName}}</Option>
        </Select> -->

        <Tabs type="card" @on-click="switchAddress">
          <TabPane v-for="(tab, index) in tabList" :key="index" :label="tab.label" :name="tab.name">
      <section style="margin-top:10px;" v-if="tab.name == 'USDT'">
        <div style="color: #eae8df;margin-left: 10px;color: #907633;  width: 100%;text-align: left;">* 网络:TRC20</div> 
     <div class="celllall2">
      <!-- <span style="margin-left:20px">
        {{$t('uc.forget.walletaddress')}}
      </span> -->
      <!-- <input type="text" v-model="inputAddress" style=" background: #000;color: #898989; width:80%; padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px; border-style:ridge; margin-left: 20px;" clearable  :placeholder="$t('uc.forget.addresstip')"> -->
      <Input v-model="inputAddress"  type="text"   size="large" style="color: #e4c87b;border-radius: 10px; margin-top: -10px; border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;"  :clearable="isdisable" :readonly="!isdisable"  :placeholder="$t('uc.forget.addresstip')" />
 
    </div>
     
      <!-- <div style="margin-top:20px" >
        <span style="font-size: 14px;color: #a2a2a1;">{{$t('uc.finance.personalassets')}}</span>
      </div>
      <div>
        <Button  style="background-color:#e5b932;border-radius: 25px; height: 40px; width: 100px; font-weight: bold; border: none;margin: 10px;margin-top: 0%;">TRC20</Button>
      </div> -->
      <div style="margin-top:80px;" v-show="isdisable">
        <Button class="btnSub"   @click="firstModel">{{$t('uc.forget.save')}}</Button>
      </div>
      <div style="margin-top: 20px;">
          <span style="color: #907633;font-size: small;padding: 10px;">{{$t('uc.finance.notice33')}}</span>
          <!-- <p><Button type="text" style="color: blue;margin-left: -15px; " size="large" @click="gotoService">{{ $t('uc.finance.notice111') }}</Button> </p> -->
        </div>
      </section>
      <section v-else-if=" tab.name == 'HUIONE'">
        <div style="color: #eae8df;margin-left: 10px;color: #907633;  width: 100%;text-align: left;">* 存款信息</div>
<div class="celllall2">
<!-- <span style="margin-left:20px">
{{$t('uc.forget.huioneaddress')}}
</span> -->
<Input v-model="inputAddress1" type="text"  size="large" style="color: #e4c87b;border-radius: 10px; margin-top: -10px; border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;"   :clearable="isdisable1" :readonly="!isdisable1"  :placeholder="$t('uc.forget.addresstip221')" />

</div>

<div class="celllall2">
<!-- <span style="margin-left:20px">
{{$t('uc.forget.addresstip311')}}
</span> -->
<Input v-model="payname" type="text"  size="large" style="color: #e4c87b;border-radius: 10px;  border-inline-width: 1px; border-style:inset; border-color: #eae8df #898989 #898989 #eae8df; width:99%;  " :clearable="isdisable1" :readonly="!isdisable1"  :placeholder="$t('uc.forget.addresstip321')" />

</div>

<div style="margin-top:80px;" v-show="isdisable1">
<Button class="btnSub"   @click="firstModel2">{{$t('uc.forget.save')}}</Button>
</div>

<div style="margin-top: 20px;">
          <span style="color: #907633;font-size: small;padding: 10px;">{{$t('uc.finance.notice33')}}</span>
          <!-- <p><Button type="text" style="color: blue;margin-left: -15px; " size="large" @click="gotoService">{{ $t('uc.finance.notice111') }}</Button> </p> -->
        </div>


</section>
      <!-- <section v-else-if=" tab.name == 'chinaBank'">

<div class="celllall2">
<span style="margin-left:20px">
{{$t('uc.forget.bankaddress')}}
</span>
<Input v-model="inputAddress2" type="text"  style="color: #898989;border-radius: 25px; border-color: #eae8df; border-inline-width: 1px; border-style:ridge; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip421')" />

</div>

<div class="celllall2">
<span style="margin-left:20px">
{{$t('uc.forget.addresstip3114')}}
</span>
<Input v-model="payname1" type="text"  style="color: #898989;border-radius: 25px; border-color: #eae8df; border-inline-width: 1px; border-style:ridge; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip321')" />

</div>

<div class="celllall2">
<span style="margin-left:20px">
{{$t('uc.forget.addresstip3112')}}
</span>
<Input v-model="inputAddress21" type="text"  style="color: #898989;border-radius: 25px; border-color: #eae8df; border-inline-width: 1px; border-style:ridge; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip3215')" />

</div>


<div class="celllall2">
<span style="margin-left:20px">
{{$t('uc.forget.addresstip3213')}}
</span>
<Input v-model="inputAddress22" type="text" style="color: #898989;border-radius: 25px; border-color: #eae8df; border-inline-width: 1px; border-style:ridge; width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip3213')" />

</div>


<div style="margin-top:80px;" v-show="isdisable2">
<Button style="width:40%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="firstModel1">{{$t('uc.forget.save')}}</Button>
</div>

<div style="margin-top: 20px;">
          <span style="font-size: 12px;color:#f60;">{{$t('uc.finance.notice32')}}</span>
          <p><Button type="text" style="color: blue;margin-left: -15px; " size="large"  @click="gotoService">{{ $t('uc.finance.notice111') }}</Button> </p>
        </div>
</section> -->
 

     
       
      </TabPane>
      
    </Tabs>

      </div>


     
     </div>

</template>

<script>

export default {
  components: {

  },
  data(){
    return{
            model1:'USDT',
 coinList: [],
 customName:'',
      customphone:'',
      rmbamount : '',
      bankName:'',
      payname: '',
      payname1: '',
      inputAddress1:'',
      inputAddress2:'',
      inputAddress21:'',
      withdrawAmount1: 0,
      withdrawAmount2: 0,
      withdrawAddressArray:[],

 tabList: [
                    // {
                    //     label: '银行卡信息',
                    //     name: 'chinaBank'
                    // },
                    {
                        label: '汇旺账号',
                        name: 'HUIONE'
                    },
                    {
                        label: 'USDT地址',
                        name: 'USDT'
                    },
                    
                ],
 isdisable:true,
 isdisable1:true,
 isdisable2:true,

 inputAddress: "", //用户输入的地址
 inputAddress22: ''
    }
  },
created() {
    
    // this.getList();
    this.getMemberInfo();
    this.getAddress();
  },
  methods: {
    firstModel1(){
      this.addChinaBankAddress()
    },
    firstModel2(){
      this.addHuiOneAddress()
    },
    gotoService(){
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push('/customservice')
    },
    getMemberInfo() {
      //获取个人安全信息
      var self = this;
     
      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
        //  let xx = resp.data

          // if (xx.bindStatus == '0') {
          //   self.isdisable = false
          // }else{
          //   self.inputAddress = xx.bindAddress
          // }
          
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },

getList() {
      //获取tableRecharge
     let memberId = 0;
      !this.$store.getters.isLogin && this.$router.push("/login");
      this.$store.getters.isLogin && (memberId = this.$store.getters.member.id);
      console.log("hahahaaa"+ memberId)
      this.$http.post(this.host + this.api.uc.paydividends).then(response => {
          var resp = response.body;
          if (resp.code == '0000') {
            if (resp.data) {
            
              this.coinList = resp.data
              resp.data.forEach(element => {
                if (element.acctName == 'USDT') {
                  this.panel1 = element
                } else {
                  this.panel2 = element
                }
              });
            }
            this.loading = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
    },
firstModel(){
  //  this.modal10 = true;
  this.addAddress();
},
switchAddress(index){
  this.isdisable = true
  this.isdisable1 = true
  this.isdisable2 = true
  if (this.withdrawAddressArray.length <= 0) {
    return
  }
      if (index == 'chinaBank') {
        this.withdrawAddressArray.forEach(element => {
          if (element.addressChannel == 2) {
            this.inputAddress2 = element.walletAddress
            this.payname1 = element.walletName
            this.inputAddress21 = element.bankName
            this.inputAddress22 = element.bankAddress
            this.isdisable2 = false
          }
        });
      }else if (index == 'HUIONE') {
        this.withdrawAddressArray.forEach(element => {
          if (element.addressChannel == 1) {
            this.inputAddress1 = element.walletAddress
            this.payname = element.walletName
            this.isdisable1 = false
          }
        });
      }else if (index == 'USDT') {
        this.withdrawAddressArray.forEach(element => {
          if (element.addressChannel == 0) {
            this.inputAddress = element.walletAddress
            this.isdisable = false
          }
        });
      }
    },
getAddress() {
      //获取个人安全信息
      var self = this;
      // var params = {
      //     };
         
      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
         this.loading = true
      this.$http
        .post(self.host + this.api.uc.wallet)
        .then(response => {
          var resp = response.body;
          self.loading = false
          if (resp.length > 0) {
            self.withdrawAddressArray = resp
            self.switchAddress('HUIONE')
          }else{
            // self.$Message.error(resp.mesg)
          }
          
        });
    },

    addAddress() {
  //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
  //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）

  // 卡号 walletAddress        姓名  walletName   银行  bankName   银行开户地址  bankAddress   提现渠道（0：区块链，1：辉旺，2：银行卡）  addressChannel
      var self = this;
      if (self.inputAddress == '') {
          self.$Message.error(self.$t('uc.finance.personalInfo6'));
          return
         }
     var params = {
          };
         
          params['addressChannel'] = 0
          params['walletAddress'] = self.inputAddress
          params['ccy'] = 'USDT'
          params['ccySymbol'] = 'TRC20'
          

      this.$http
        .post(self.host + this.api.uc.identification, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
           
            self.$Message.success(resp.mesg);
            self.isdisable = false
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

    addChinaBankAddress() {
  //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
  //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）

  // 卡号 walletAddress        姓名  walletName   银行  bankName   银行开户地址  bankAddress   提现渠道（0：区块链，1：辉旺，2：银行卡）  addressChannel
      var self = this;
     var params = {
          };
         if (self.inputAddress2 == '' || self.payname1 == '' || self.inputAddress21 == '') {
          self.$Message.error(self.$t('uc.finance.personalInfo6'));
          return
         }
          params['addressChannel'] = 2
          params['walletAddress'] = self.inputAddress2
          params['ccy'] = 'USDT'
          params['walletName'] = self.payname1
          params['bankName'] = self.inputAddress21
          params['bankAddress'] = self.inputAddress22

      this.$http
        .post(self.host + this.api.uc.identification, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
           
            self.$Message.success(resp.mesg);
            self.isdisable = true
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

    addHuiOneAddress() {
  //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
  //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）

  // 卡号 walletAddress        姓名  walletName   银行  bankName   银行开户地址  bankAddress   提现渠道（0：区块链，1：辉旺，2：银行卡）  addressChannel
      var self = this;
     var params = {
          };
          if (self.inputAddress1 == '' || self.payname == '') {
          self.$Message.error(self.$t('uc.finance.personalInfo6'));
          return
         }
          params['addressChannel'] = 1
          params['walletAddress'] = self.inputAddress1
params['walletName'] = self.payname
          params['ccy'] = 'USD'
          

      this.$http
        .post(self.host + this.api.uc.identification, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
           
            self.$Message.success(resp.mesg);
            self.isdisable1 = false
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

goback() {
      //获取个人安全信息
     this.$router.back(-1)
     
    },
  }
}
</script>

<style lang="scss" scoped>

  .celllall{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}


.btnSub{
  width:75%; 
  height: 40px; 
  font-size: 14px; 
  border: none;  
  color: #553a25; 
  font-weight: 600;
  border-radius: 25px;
  background: url(../assets/me/logout-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  letter-spacing: 2px;
}

.celllall2{
  width: 100%;
 
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 color: #a2a2a1;
 font-size: 18px;
 margin-top: 20px;
}

  .celllall1{
  width: 100%;
  height: 40px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  align-items: flex-start;
  background: url(../assets/netmage/qqqy.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 18px;
  margin-top: 70px;
  
}

   .rightarea {
  width: 100%;
  height: 400px;
 
  margin-top: 60px;
  padding: 20px;

    }

    .rightarea /deep/ .ivu-select-selection {
   
    background-color: #e5b932;
    border-radius: 15px;
    color: #000;
    font-weight: bolder;
}


.bill_box{
  margin-top: 10%;
}

.bill_box /deep/ .ivu-select-selection {
   
    background-color: #e5b932;
    border-radius: 15px;
    color: #000;
    font-weight: bolder;
}

</style>


<style scoped lang="less">

// class="ivu-input ivu-input-default"
  /deep/ .ivu-input{
  background: transparent;
  border: 0;
  border-radius: 25px;
  
  color: #898989
}
    
/deep/ .ivu-input-wrapper{
      border: 0.1ch;
    }

</style>

