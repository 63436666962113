<template>
   <div class="login_form ">

    <div class="headBox">
      <img src="../assets/newImage/back.png" alt="" style="width:20px;height:15px;" @click="goback">
      <span>
        安全设置
      </span>
      <span>{{  }}</span>
    </div>


<div style="margin-top:40px;padding: 10px;">
  <section class="celllall" @click="gotoBill">
<span>
  忘记密码
</span>

<img src="../assets/netmage/rightarr.png" width="10px" height="15px">
     </section>
     
     <Divider style="margin-top: -10px;" />
     <section class="celllall" style="margin-top: -20px;" @click="gotoSet">
<span>
  {{$t('uc.forget.resetemailpwd')}}
</span>
<img src="../assets/netmage/rightarr.png" width="10px" height="15px">
     </section>
     <Divider style="margin-top: -10px;"/>
</div>

  </div>
</template>

<script>
export default {
methods: {
  goback() {
      //获取个人安全信息
     this.$router.back(-1)
     
    },
    gotoSet(){

    this.$router.push("/set");
  },
   gotoBill(){

    this.$router.push("/loginset");
  
  },
}
}
</script>

<style scoped>


.celllall{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: url(../assets/netmage/xxyz.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  color: #fff;
  font-size: 18px;
}
</style>
