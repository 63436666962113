<template>
    <div class="box">
        <div class="nav-rights" @contextmenu.prevent>
            <Spin v-if="loading" fix class="demo-spin-col">
                <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                <div style="color: #fff">台桌加载中…</div>
            </Spin>
            <div class="box_21">
                <div class="back-img">
                    <img src="../assets/netmage/arrowleft.png" alt="" style="width:20px;height:15px;" @click="goBack">
                </div>
                <div class="title">四名宝：0{{ id }}</div>
                <div class="balance"><img src="../assets/about/amount.png" alt="">
                    <span>{{ moneybab.amount }}</span>
                </div>
            </div>
            <!--        视频区-->
            <div class="box-video">
                <!--声音-->
                <div class="icon-voice" style="color: #fff" @click="changeVoice"
                     :class="{'icon-voice-line':voice === 0}">
                    🔊
                </div>
                <!--路图-->
                <!--            <Icon @click="setScale" class="icon-scale" type="md-search"/>-->
                <div class="icon-scale">
                    <img v-if="scale === 1" @click="setScale" class="icon-scale-icon" src="../assets/newImage/up.png"
                         alt="">
                    <img v-else @click="setScale" class="icon-scale-icon" src="../assets/newImage/down.png" alt="">
                </div>
                <div class="lu-tu" v-if="lu">
                    <div class="lu-list">
                        <div class="lu-col" v-for="(item,i) in luList" :key="i">
                            <div class="lu-item" :class="getClassLu(item,tem,i)" v-for="(tem,n) in item" :key="n">
                            </div>
                        </div>
                    </div>
                </div>
                <myVideo :scale="scale" :source="source" :forbidFastForward="false" :options="options"
                         :height="videoHeight"></myVideo>
<!--                                <div style="height: 300px"></div>-->
                <div class="desc-popup">
                    <div v-if="stage == 2 || stage == 3" class="tableStatus"> {{ ['', '', '停止下注', '结算'][stage] }}
                    </div>
                    <div v-else class="desc-num tableStatus">{{ descTime }}</div>
                </div>
                <div class="heguan">
                    {{ opener }}
                </div>
                <div class="person"><img src="../assets/newImage/icon_yhlb.png" alt="">{{ person }}</div>
            </div>
            <!--限红 局号，期号-->
            <div class="box-info">
                <!--            <div @click="del(3)">限红</div>-->
                <Icon @click="tableMove('l')" class="arr arrow-left"
                      type="md-arrow-round-back"/>
                <div>期数 {{ periods }}</div>
                <div>本局投注{{ total }}</div>
                <Icon @click="tableMove('r')" class="arr arrow-right"
                      type="md-arrow-round-forward"/>
            </div>
            <!--        投注区-->
            <div class="bet-box-box">

                <div class="box-bet" ref="tableScroll" @scroll="handleScroll">
                    <div class="box-bet-scroll">
                        <div class="bet-top bet-top-left">
                            <div class="bet-top-box">
                                <div class="col" :class="getClassNames(l)" v-for="(item,l) in betListSure.slice(0,26)"
                                     :key="l">
                                    <div class="wra" @click="betBegin(l)" :class="'bet' + l">
                                        <!--自己的下注 待确认-->
                                        <div class="my-bet"
                                             v-if="![6,8,17,19].includes(l) && betListWait[l] > 0"
                                             :class="l === 12 ? 'bet12w' : 'bet13w'">
                                            <img :src="getBetImageSrc('common1')" alt="">
                                            <span>{{ betListWait[l] }}</span>
                                        </div>
                                        <!--我方已经确认的下注 +特殊区域-->
                                        <div class="bet-show1" v-if="betListMySure[l] > 0"
                                             :class="{betShow12:l === 12,betShow13:l ===13}">
                                            <span>{{ betListMySure[l] }}</span>
                                            <img style="width: 20px" :src="getBetImageSrc('common1')" alt="">
                                        </div>
                                        <!--已经确认的下注-->
                                        <div class="bet-show , .my-bet" :class="{betShow12:l === 12,betShow13:l ===13}">
                                            <span v-if="![6,8,17,19].includes(l)">{{ item > 0 ? item : 0 }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col cla-last">

                                </div>
                                <div class="box-mid">
                                    <div class="wrap-top" :class="{breath: openR == 3 && openBetList.length}"
                                         @click.stop="clickMid(3)">
                                        <div class="wrap-top-l"></div>
                                        <div class="wrap-top-r"></div>
                                    </div>
                                    <div class="wrap-left" :class="{breath: openR == 4 && openBetList.length}"
                                         @click.stop="clickMid(4)">
                                        <div class="wrap-left-t"></div>
                                        <div class="wrap-left-b"></div>
                                    </div>
                                    <div class="wrap-right" :class="{breath: openR == 2 && openBetList.length}"
                                         @click.stop="clickMid(2)">
                                        <div class="wrap-right-t"></div>
                                        <div class="wrap-right-b"></div>
                                    </div>
                                    <div class="wrap-bottom" :class="{breath: openR == 1 && openBetList.length}"
                                         @click.stop="clickMid(1)">
                                        <div class="wrap-bottom-t"></div>
                                        <div class="wrap-bottom-b"></div>
                                    </div>
                                    <div class="left"
                                         :class="{breath: (openR == 2 || openR == 4) && openBetList.length}"
                                         @click="clickMid(5)"></div>
                                    <div class="right"
                                         :class="{breath: (openR == 1 || openR == 3) && openBetList.length}"
                                         @click="clickMid(6)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="bet-top bet-top-right">
                            <div class="bet-top-box">
                                <div class="col" :class="getClassNames(l+26)" v-for="(item,l) in betListSure.slice(-12)"
                                     :key="l">
                                    <div class="wra" @click="betBegin(l+26)" :class="'bet' + l">
                                        <!--自己的下注 待确认-->
                                        <div class="my-bet" v-if="betListWait[l+26] > 0">
                                            <img :src="getBetImageSrc('common1')" alt="">
                                            <span>{{ betListWait[l + 26] }}</span>
                                        </div>
                                        <!--我方已经确认的下注 +特殊区域-->
                                        <div class="bet-show1" v-if="betListMySure[l+26] > 0">
                                            <span>{{ betListMySure[l + 26] }}</span>
                                            <img style="width: 20px" :src="getBetImageSrc('common1')" alt="">
                                        </div>
                                        <!--已经确认的下注-->
                                        <div class="bet-show , .my-bet">
                                            <span>{{ betListSure[l + 26] > 0 ? item : 0 }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bet-coin-list">
                <Icon @click="clickArrow(1)" class="arr-r arr" type="ios-arrow-back"/>
                <Icon @click="clickArrow(2)" class="arr-l arr" type="ios-arrow-forward"/>
                <div class="box-list">
                    <div class="bet-coin-list-box" ref="list" :style="{ left: elementLeft + 'px' }">
                        <img
                            v-for="(item,i) in betList" :key="i"
                            class="bet-coin-item"
                            :class="{active:currentSelBet === item}" @click="clickBet(item)"
                            :src="getBetImageSrc(item)"
                            alt="">
                    </div>
                </div>
            </div>
            <div class="bot-btn-box">
                <Button @click="opt(1)" class="btn" :class="{'btn_active' : this.lu}" type="primary">
                    <Icon class="icon icon-1" type="ios-stats"/>
                </Button>
                <Button @click="opt(5)" class="btn" type="primary">
                    <div class="btn-box">
                        <Icon class="icon" style="color: #C02832" type="md-close-circle"/>
                        <span>取消</span>
                    </div>
                </Button>
                <Button @click="opt(4)" class="btn" type="primary">
                    <div class="btn-box">
                        <Icon class="icon" type="ios-sync"/>
                        <span>重复</span>
                    </div>
                </Button>
                <Button @click="opt(3)" class="btn" type="primary">
                    <div class="btn-box">
                        <Icon class="icon" style="color: #E09F4A" type="ios-checkmark-circle-outline"/>
                        <span>确定</span>
                    </div>
                </Button>
                <Button @click="opt(2)" class="btn" type="primary">
                    <Icon class="icon icon-5" type="md-grid"/>
                </Button>
            </div>
            <div v-if="showText" class="popup-txt">{{ showTextCon }}</div>
            <Modal v-model="modal2" width="360">
                <div style="text-align:center">
                    <div class="pop-box">
                        <div class="top">
                            <div class="t-l">游戏规则</div>
                            <Icon @click="del(2)" class="t-r" style="color: #fff" type="md-close"/>
                        </div>
                        <div class="con">
                            <div class="des">
                                <div class="t-title">简介 INTRODUCTION</div>
                                <div class="c-text">
                                    四名宝是中国古老的做庄博彩游戏，流行于中国沿海一带，当年四名宝只有一种玩法，就是猜数字玩家时至今日，四名宝玩法已改变不少，除了数字之外还多了念、角、通、门、单双这几种玩法
                                    。
                                </div>
                            </div>
                        </div>
                        <div class="con">
                            <div class="des">
                                <div class="t-title">游戏玩法 GAME INSTRUCTION</div>
                                <li class="c-text">
                                    游戏使用四张扑克牌，新局开始后，荷官从扑克牌(1,2,3,4)中随机抽出的一张扑克牌作为开奖结果，开始下注。
                                </li>
                                <li class="c-text">
                                    下注结束后，荷官即翻开扑克牌
                                </li>
                                <li class="c-text">扑克牌的点数对应桌面的数字猜对为赢，同时画面会显示玩家的输赢情况。</li>
                            </div>
                        </div>
                        <div class="con">
                            <div class="des">
                                <div class="t-title">派彩 PAYOFF</div>
                                <table class="table table-bordered text-middle">
                                    <thead>
                                    <tr>
                                        <th style="width: 25%" class="text-center col-md-2"><span
                                            class="mceNonEditable dict">下注组合</span>
                                        </th>
                                        <th style="width: 15%" class="text-center col-md-2"><span
                                            class="mceNonEditable dict">赔率</span></th>
                                        <th style="width: 68%;text-align: center" class="text-center col-md-8"><span
                                            class="mceNonEditable dict">说明</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item,i) in read" :key="i">
                                        <td class="text-center td-theme"><span class="mceNonEditable rule_txt"
                                                                               id="rule_txt[17]">{{ item.n }}</span>
                                        </td>
                                        <td class="text-center">{{ item.v }}</td>
                                        <td class="text-left" style="text-align: center;width: 68%"><span
                                            class="mceNonEditable rule_txt" id="rule_txt[9]">
                                            {{ item.c }}
                                           </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="con">
                            <div class="des des-ol">
                                <div class="t-title">其他补充说明 EXPLANATION</div>
                                <ol class="c-text">
                                    本游戏进行方式为线上线下同时进行，若有发生特殊情形将依本公司公告之办法处理。
                                </ol>
                                <ol class="c-text">
                                    如在游戏中发生系统故障或者其他不可不免的意外，该局判定为无效，并退还所有下注本金。
                                </ol>
                                <ol class="c-text">
                                    四名宝游戏进行每90局后，系统会自动清除牌路。
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal v-model="modal1" width="260" class="model-notice">
                <div style="text-align:center">
                    <div class="pop-box pop-box-notice">
                        <div class="top">
                            <div class="t-l">提示</div>
                            <Icon @click="del(1)" class="t-r" style="color: #fff" type="md-close"/>
                        </div>
                        <div class="con">
                            <p>
                                <Icon type="ios-warning-outline"/>
                                {{ modalText }}
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal v-model="modal4" width="260" class="model-notice">
                <div style="text-align:center">
                    <div class="pop-box pop-box-notice">
                        <div class="top">
                            <div class="t-l">提示</div>
                            <Icon @click="del(4)" class="t-r" style="color: #fff" type="md-close"/>
                        </div>
                        <div class="con">
                            <p>
                                开奖结果： {{ openR }}
                            </p>

                            <div v-if="total > 0" :style="{color:win >= 0 ?'rgba(173, 255, 47, 1' : 'red'}"
                                 class="popup-txt popup-txt-win">输赢 ：{{ win }}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal v-model="modal3" width="260" class="model-notice">
                <div style="text-align:center" v-if="limitConfig && limitConfig.limitBet">
                    <div class="pop-box pop-box-notice">
                        <div class="top">
                            <div class="t-l">提示</div>
                            <Icon @click="del(3)" class="t-r" style="color: #fff" type="md-close"/>
                        </div>
                        <div class="con1">
                            <div style="height: 30px;line-height: 30px">个人限注</div>
                            <div>
                                <Input disabled v-model="limitConfig.limitBet['A']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">A区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitBet['B']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">B区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitBet['C']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">C区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitBet['D']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">D区</span>
                                </Input>
                            </div>
                            <div style="height: 30px;line-height: 30px">游戏限红</div>
                            <div>
                                <Input disabled v-model="limitConfig.limitRed['A']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">A区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitRed['B']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">B区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitRed['C']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">C区</span>
                                </Input>
                                <Input disabled v-model="limitConfig.limitRed['D']"
                                       style="width: 150px;margin:4px auto;">
                                    <span slot="prepend">D区</span>
                                </Input>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div style="width: 0;height: 0;overflow: hidden">
                <audio ref="audioTagStart" controls>
                    <source src="../assets/sound/start.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTagStop" controls>
                    <source src="../assets/sound/stop.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag1" controls>
                    <source src="../assets/sound/1.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag2" controls>
                    <source src="../assets/sound/2.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag3" controls>
                    <source src="../assets/sound/3.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <audio ref="audioTag4" controls>
                    <source src="../assets/sound/4.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
            </div>

        </div>

    </div>
</template>

<script>
import myVideo from '../components/myVideo.vue'
import countDown from 'vue-canvas-countdown'

export default {
    name: 'gameTable',
    components: {
        myVideo,
        countDown
    },
    data() {
        return {
            loading: true,
            optDesc: 1800,  //用户一定时间不操作就会被踢到大厅
            optTimer: null, //用户操作时间
            ifCurrent: true, //是否在当前页面
            voice: 1,  //声音大小
            arrShow: 3, //1左  2两个 3右
            getSound: false,
            stage: 0,  //桌子状态 0开桌（基本不会有）  1下注 2停止下注 3结算
            canBet: true,  //防止重复
            lu: false,
            luList: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            historyList: [],
            read: [
                {n: '数字', v: '1:2.91', c: '投注单一号码，开出为赢，其余为输 如：压1，开1为赢，2、3、4为输'},
                {
                    n: '角',
                    v: '1:1.94',
                    c: '投注两个号码，一个为赢，一个为和，另外两个为输 如：压1带2，开1为赢，开2为和，3、4为输'
                },
                {
                    n: '连',
                    v: '1:0.97',
                    c: '投注两个号码，开出为赢，其余为输 如：1连2，开1、2为赢，3、4为输'
                },
                {
                    n: '保',
                    v: '1:0.95',
                    c: '例如压1保23，开1为赢 2，3退回本金 开4为输'
                },
                {n: '单', v: '1:0.97', c: '1、3为单'},
                {n: '双', v: '1:0.97', c: '2、4为双'},
            ],
            scale: 1,//放大镜
            modal1: false,
            modal2: false,
            modal3: false,
            modal4: false,
            win: 0,
            openR: 0,  //开奖结果
            showWin: false,
            audio: null,
            audio1: null,  //背景音乐
            showTextCon: '',
            showText: false,
            modalText: '',
            showTimer: null,
            total: 0,
            elementLeft: 0,
            person: 1, //房间人数
            periods: 0,
            tableInfo: {},
            betTimer: null,
            firstCome: true,
            user: localStorage.getItem('userinfo') && JSON.parse(localStorage.getItem('userinfo')),
            ifConnect: 0,
            timer: null,
            desc: 60,
            moneybab: 0,
            limitConfig: null, //限红设置
            socket: null,
            opener: '',
            bedtime: 0,
            descTime: 0,
            descTimer: null,
            ws: 'wss://sm.cham26.com/websocket/',
            lastBetInfo: {},
            ifCanBetAgain: false,   //是否可以续押
            betMoney: 0,
            betListWait: [  //待确认的投注
                0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
            ],
            betListMySure: [  //这一局自己确认的投注
                0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
            ],
            betListSureOnce: [  //这一轮已确认的投注
                0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
            ],
            betListSure: [  //所有已经确认的投注
                0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0, 0,
                0, '', 0, '', 0,
                0, 0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
                0, 0, 0, 0,
            ],
            cyyList: [
                'C3', 'B5', 'B11', 'B4', 'C2',
                'B6', '--', 'A3', '--', 'B3',
                'B12', 'A4', 'D1', 'D2', 'A2', 'B10',
                'B7', '--', 'A1', '--', 'B2',
                'C4', 'B8', 'B1', 'B9', 'C1',
                'E1', "E6", "E8", "E10",
                'E3', "E5", "E7", "E12",
                'E2', "E4", "E9", "E11",
            ],
            oddsList: [
                '0', '1', '2', '3', '4',
                '5', '--', '7', '--', '9',
                '10', '11', '12', '13', '14', '15',
                '16', '--', '18', '--', '20',
                '21', '22', '23', '24', '25',
                '26', '27', '28', '29',
                '30', '31', '32', '33',
                '34', '35', '36', '37',
            ],
            openBetList: [], //开奖结果列表
            betList: [],
            currentSelBet: 10, //当前选中的筹码
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
            message: '',
            id: '',
            videoHeight: 0,
            source: "",
            // source: "http://154.207.104.4:8080/live/SMcaiyuanguangjin888.m3u8",
            // source: "http://154.207.104.4:8080/players/rtc_player.html?vhost=__defaultVhost__&app=live&stream=SMcaiyuanguangjin888.flv&server=154.207.104.4&port=8080&autostart=true&schema=http",
            options: {
                isLive: true, // 是否开启直播
                language: "zh-custom", // 自定义语言包
                languageTexts: {
                    // 播放器自定义提示文字
                    "zh-custom": {
                        Video_Error: "视频播放异常", // 视频播放异常
                        Error_Load_M3U8_Timeout_Text: "获取m3u8文件超时", // 获取m3u8文件超时
                        Error_Load_M3U8_Failed_Text: "获取m3u8文件失败", //  获取m3u8文件失败
                        Live_Abrot: "直播信号中断", // 直播信号中断
                        Error_Not_Found: "播放地址不存在", // 播放地址不存在
                        Refresh_Text: "刷新",
                        Retry: "重试",
                        Detection_Text: "诊断"
                    }
                }
            }
        }
    },
    created() {
        this.getMember()
        // this.getTables(1)
        this.id = this.$route.query.gpage
        this.opener = this.$route.query.page1
        this.source = this.$route.query.pp
        // this.bedtime = this.$route.query.pp1
        // this.periods = this.$route.query.pp2
        this.playSound('bg.mp3')
        this.calcVideoHeight()
    },
    mounted() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
        // this.addWheelEventListener()
        // 创建防抖后的点击处理函数
        this.sureBet = this.debounce(this.sureBet, 300); // 这里的500是延迟时间，单位是毫秒
    },
    watch: {
        // stage(newVal, old) {  //桌面状态
        //     console.log('stage----桌面状态', newVal)
        // },
        // loading(newVal,old){
        //     if(!newVal){
        //         console.log( 1234 )
        //         if(this.descTimer) clearInterval(this.descTimer)
        //         this.getTables(1)
        //     }
        // }
        loading: {
            handler(newVal, old) {
                if (!newVal) {
                    if (this.descTimer) clearInterval(this.descTimer)
                    this.getTables(1)
                } else {
                    this.reConnect()
                }
            },
            // immediate: true
        },

    },
    methods: {
        addWheelEventListener() {
            window.addEventListener('touchmove', this.wheelEventHandler, {passive: false});
        },
        removeWheelEventListener() {
            window.removeEventListener('touchmove', this.wheelEventHandler,);
        },
        wheelEventHandler(event) {
            event.preventDefault();
        },
        /*进入桌面初始化*/
        getTables(type) {  //获取桌子
            this.historyList = []  //每次需要初始化
            this.$http.get(this.host + this.api.game.getTables).then(response => {
                let resp = response.body;
                if (resp.code == 200) {
                    this.loading = false
                    // 桌子当前状态
                    let tableId = this.$route.query.gpage
                    if (resp && resp.data[tableId] && type) {
                        let outId = this.ifOnTheOtherTable(resp.data)
                        if(outId) {
                            this.outTable(outId)
                            return
                        }
                        this.stage = resp.data[tableId].stage
                        this.tableInfo = resp.data[tableId]
                        let countDown = resp.data[tableId].countDown
                        this.bedtime = resp.data[tableId].config && resp.data[tableId].config.betTime
                        let data = resp.data[tableId]
                        // 获取桌面筹码
                        this.dealSureBet(data)
                        if (this.stage == 0) {
                            // console.log('台桌开始')
                        }
                        if (this.stage == 1) {
                            // console.log('开始下注')
                            this.descTime = countDown
                            this.descBetTime(1)
                        }
                        if (this.stage == 2) {
                            // console.log('结束下注')
                        }
                        if (this.stage == 3) {
                            // console.log('结算')
                            if (localStorage.getItem('myBetInfo' + this.id)) {
                                //已经结算就不需要展示我放下注
                                localStorage.removeItem('myBetInfo' + this.id)
                            }
                        }
                    }
                    this.tableData = resp.data
                    if (resp && resp.data[tableId] && resp.data[tableId].historyPeriod) {  //路图
                        const current = resp.data[tableId].currentPeriod
                        // 初始化期数
                        if (Number(current.period)) { //有期数
                            this.periods = Number(current.period)
                        } else { //年 月  日 + 0
                            this.periods = this.calcInitPeriods()
                        }
                        //初始化 确认期数后  请求我方已经下注的信息展示
                        if (localStorage.getItem('myBetInfo' + this.id)) {
                            let tableId = this.$route.query.gpage
                            let t = JSON.parse(localStorage.getItem('myBetInfo' + this.id))
                            if (this.periods == t.periods && tableId == t.tableId) {  //期数与保存的一致并且tableId相同
                                this.betListMySure = JSON.parse(JSON.stringify(t.list))
                                this.betListSureOnce = JSON.parse(JSON.stringify(t.list))
                                JSON.parse(JSON.stringify(t.list)).forEach(item => {
                                    if (Number(item) > 0) {
                                        this.total += Number(item)
                                        this.lastBetInfo.total = this.total
                                    }
                                })
                            }

                        }
                        let jon = resp.data[tableId].historyPeriod
                        for (let k in jon) {
                            this.historyList.push(jon[k])
                        }
                        this.dealLu()
                    }
                }
            });
        },
        // 判断是不是在其他的桌子里面
        ifOnTheOtherTable(data) {
            let id = null
            for (let key in data) {
                if (this.id != key) {
                    if (data[key].users.length) {
                        let userid = this.user && this.user.userNo
                        if (data[key].users.indexOf(userid) > 0) {
                            id = key
                        }
                    }
                }
            }
            return id
        },
        calcInitPeriods() {  //没有期数自己设定 年+月+日+0  24+06+12+0  2406120
            let y = String(new Date().getFullYear())
            let m = new Date().getMonth() + 1
            let d = new Date().getDate()
            // console.log(y, m, d)
            m = m < 10 ? '0' + m : m
            d = d < 10 ? '0' + d : d
            return `${y.slice(-2)}${m}${d}0`
        },
        getMember() {//获取个人安全信息  主要需要知道余额
            let self = this
            this.$http
                .post(self.host + this.api.uc.personalWallet)
                .then(response => {
                    let resp = response.body;
                    if (resp.code == "0000") {
                        self.moneyList = resp.data
                        self.moneyList.forEach(element => {
                            if (element.ccy == 'USDT') {
                                self.moneybab = element
                                self.ws = self.ws + element.userNo
                                // 初次进来需要初始化桌子 连接socket   后续只是刷新余额
                                if (self.firstCome) {
                                    this.jsonTable(element.uid) // 加入桌子
                                    self.connectSocket()
                                    self.firstCome = false
                                }
                            }
                        });
                    } else {
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },
        jsonTable(userId) { //加入桌子
            let params = {
                tableId: this.id,
                userId
            }
            this.$http.get(this.host + this.api.game.intoTable, {params}).then(response => {
                let resp = response.body;
                this.getGameConfig()
                if (resp.code == 200) {
                    this.showTextFn('成功加入桌子')
                } else {
                    if (resp.msg === '您已经在该桌子') {
                        this.showTextFn('成功加入桌子')
                    } else {
                        // this.$Message.error(resp.msg);
                        this.showModal(resp.msg)
                    }
                }
            });
        },
        getGameConfig() { //获取游戏配置
            let param = {}
            param.tableId = this.id
            this.$http.get(this.host + this.api.game.getConfig, {params: param}).then(response => {
                let resp = response.body;
                if (!response.ok) {
                    // this.$Message.error('获取游戏配置异常');
                    this.showModal('获取游戏配置异常')
                } else {
                    this.limitConfig = resp
                    if (resp.betChips && resp.betChips.length) {
                        resp.betChips.forEach((item, i) => {
                            if ([10, 20, 50, 100, 500, 1000, 5000, 10000, 20000, 50000].includes(Number(item))) {
                                this.betList.push(Number(item))
                            }
                        })
                    }
                }
            });
        },
        /*进入桌面初始化结束*/
        dealLu() {
            if (!this.historyList.length) return
            this.luList = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
            let c = 0
            let n = 0
            let last = this.historyList[0].result
            let ifpair = Number(last) % 2 === 0
            this.luList[0][0] = this.historyList[0].result
            this.historyList.forEach((item, i) => {
                ifpair = (item.result % 2 == last % 2)
                if (i !== 0) {
                    if ((item.result == last || ifpair) && this.luList[c].length != 6) {
                        this.luList[c].push(item.result)
                    } else {
                        n = 0
                        c += 1
                        if (c === 16) {
                            this.luList.shift()
                            this.luList.push([])
                            c -= 1
                        }
                        last = item.result
                        this.luList[c] && this.luList[c].push(last)
                    }
                }
            })
        },
        calcVideoHeight() {
            this.videoHeight = 100 * 9 / 16 + 'vw'
        },
        /*计时器相关事件*/
        userOptFun() {
            let desc = this.optDesc
            this.optTimer = setInterval(() => {
                desc--
                if (desc <= 0) {
                    this.$router.push({path: '/about'})
                }
            }, 1000)
        },
        onStatusChange(payload) {
            let t = null
            let num = 5
            if (payload == 3) { //还有5秒的提示
                t = setInterval(() => {
                    num--
                    this.playSound('desc.mp3')
                    if (num < 1) clearInterval(t)
                }, 1000)
            }
            if (payload == 0) {  //结束
                clearInterval(t)
            }
            // console.log('倒计时状态改变：', payload)
        },
        descBetTime(type = 1) {  //1开始  2结束
            if (type === 1) {
                this.descTimer = setInterval(() => {
                    if (this.descTime > 0) this.descTime--
                    if (this.descTime === 5) {  //还有5秒 提醒倒计时
                        this.playSound('warn.mp3')
                        this.onStatusChange(3)
                    }
                    if (this.descTime < 1) {
                        this.stage = 2  //前端时间先到停止下注阶段
                        this.onStatusChange(0)
                        clearInterval(this.descTimer)
                    }

                }, 1000)
            }
            if (type === 2) {
                this.stage = 2
                this.lastBetInfo.total = this.total //记录自己当前次数下注总额 计算输赢
            }
        },
        /*倒计时相关事件结束*/
        /*socket*/
        connectSocket() {
            const _this = this
            this.socket = new WebSocket(this.ws);
            // 连接建立时触发
            this.socket.addEventListener('open', function (event) {
                console.log('socket 连接成功!');
                _this.ifConnect = true
                _this.userOptFun()
                _this.pingFun()
                _this.loading = false
            });
            // 接收到消息时触发
            this.socket.addEventListener('message', function (event) {
                if (event.data == 1) _this.ifConnect = true
                if (event.data && JSON.parse(event.data)) {  //推送会推所有房间的 屏蔽其他房间信息
                    let d = JSON.parse(event.data)
                    if (d.tableId != _this.id) return
                }
                if (event.data.includes('startBet') || event.data.includes('endBet') || event.data.includes('settlement')) {  //开始下注和结束下注信息
                    if (event.data.includes('startBet')) {
                        _this.getStatusByOs(1)
                    }
                    if (event.data.includes('endBet')) {
                        _this.getStatusByOs(2)
                    }
                    if (event.data.includes('settlement')) { //结果推送
                        let t = JSON.parse(event.data) && JSON.parse(event.data).result
                        _this.getStatusByOs(3, t)
                    }
                } else if (typeof event.data === 'string') {
                    if (event.data.includes('betTotal')) {  //下注
                        let t = JSON.parse(event.data)
                        _this.dealSureBet(t)
                    } else if (event.data.includes('users')) { //获取用户
                        let t = JSON.parse(event.data)
                        _this.dealSureBet(t, 2)
                    } else if (event.data.includes('result')) {
                        // _this.dealSureBet(t, 3)
                        // _this.dealSureBet(JSON.parse(JSON.stringify(event.data)), 1)
                    } else if (event.data.includes('closeTable')) {
                        console.log('牌桌关闭')
                        _this.$router.push({path: '/about'})
                    }
                }
            });
            // 发生错误时触发
            this.socket.addEventListener('error', function (event) {
                // _this.outTable()
                console.error('WebSocket error:', event);
            });
        },
        pingFun() {
            if (this.timer) { /// 如果有已有的计时器，先清除它
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.ifConnect) {
                    console.log('心跳正常')
                    this.ifConnect = false
                    if(this.socket) this.socket.send('0');
                } else {
                    this.del(5)
                    console.log('socket已断开，正在重新连接')
                    if(this.socket) this.socket.close()
                    this.loading = true
                    this.connectSocket()
                }
            }, 5000)
        },
        getStatusByOs(n, t) { //服务器拿回的状态 1 2 3
            if (n === 1) {
                this.canBet = true
                this.modal4 = false
                this.stage = 1
                this.openR = 0 //开奖区域重置
                this.descTime = this.bedtime
                this.win = 0
                this.openR = 0
                if (this.modal4) this.modal4 = false
                this.total = 0
                if (localStorage.getItem('myBetInfo' + this.id)) {
                    localStorage.removeItem('myBetInfo' + this.id)
                }
                this.descBetTime(1)
                this.resetWaitSet(3)
                this.showTextFn('已开局 请下注', 2)
                this.playSound('start.mp3')
                this.getTables()

            }
            if (n === 2) {
                this.stage = 2
                this.resetWaitSet(1)
                this.showTextFn('停止下注', 3)
                this.playSound('stop.mp3')
                this.descBetTime(2)
                clearInterval(this.descTimer)
            }
            if (n === 3) {
                this.stage = 3
                this.resetWaitSet()
                this.resetWaitSet(2)
                // this.resetWaitSet(3)
                this.resetWaitSet(4)
                this.playSound('result.mp3')
                this.showResult(t)
                this.descBetTime(2)
                clearInterval(this.descTimer)
            }
        },
        reConnect() {  //重连初始化的事件
            this.win = 0
            this.openR = 0
            this.total = 0
            this.resetWaitSet(3)
            this.resetWaitSet()
            this.resetWaitSet(2)
            this.resetWaitSet(4)
            this.del(5)  //短线 所有弹窗关闭
        },
        /*筹码计算*/
        calcResult(r) {
            let mon = 0
            if (r == 1) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 18) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 23 || i === 22 || i === 24) mon += Number(item) * 2 * tax + +Number(item)
                    if (i === 20 || i === 16 || i === 2 || [31, 35, 28, 32, 29, 37].includes(i)) mon += Number(item)
                    if (i === 25 || i === 12 || i === 21 || [26, 30, 34].includes(i)) mon += Number(item) * tax + Number(item)
                })
                //[18,23,22,24,20,16,2,25,11,21,26,30,34,31,35,28,32,29,37]
            }
            if (r == 2) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 14) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 20 || i === 9 || i === 15) mon += Number(item) * 2 * tax + Number(item)
                    if (i === 23 || i === 3 || i === 10 || [26, 34, 32, 36, 29, 33].includes(i)) mon += Number(item)
                    if (i === 25 || i === 4 || i === 13 || [27, 31, 35].includes(i)) mon += Number(item) * tax + Number(item)
                })
                //[13,20,9,15,23,3,10,26,34,32,36,29,33,25,4,14,27,31,35]
            }
            if (r == 3) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 7) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 3 || i === 1 || i === 2) mon += Number(item) * 2 * tax + Number(item)
                    if (i === 9 || i === 5 || i === 24 || [26, 30, 27, 35, 33, 37].includes(i)) mon += Number(item)
                    if (i === 0 || i === 4 || i === 12 || [28, 32, 36].includes(i)) mon += Number(item) * tax + Number(item)
                })
                //[7,3,1,2,9,5,24,26,30,27,35,33,37,0,4,11,28,32,36]
            }
            if (r == 4) {
                this.betListSureOnce.forEach((item, i) => {
                    let tax = this.getTax(i)
                    if (i === 11) mon += Number(item) * 3 * tax + Number(item)
                    if (i === 5 || i === 16 || i === 10) mon += Number(item) * 2 * tax + Number(item)
                    if (i === 1 || i === 22 || i === 15 || [30, 34, 27, 31, 28, 36].includes(i)) mon += Number(item)
                    if (i === 0 || i === 21 || i === 13 || [29, 33, 37].includes(i)) mon += Number(item) * tax + Number(item)
                })  //[12,5,16,10,1,22,15,30,34,27,31,28,36,0,21,14,29,33,37]
            }
            // 开奖结果的特效
            mon = mon - this.lastBetInfo.total
            // debugger
            return this.formatNumber(Number(mon))
        },
        formatNumber(num) {  //输赢结果保留1位小数
            // 判断是否带有小数点
            if (num % 1 !== 0) {
                // 四舍五入并保留1位小数
                return Math.round(num * 10) / 10;
            } else {
                // 如果是整数，则直接返回
                return num;
            }
        },
        /*下注新增参数 需计算*/
        calcBetTotal(type = 1) {
            let total = {1: 0, 2: 0, 3: 0, 4: 0}
            let oddsTotal = {1: 0, 2: 0, 3: 0, 4: 0}
            this.betListWait.forEach((item, i) => {
                if (item > 0 && [18, 23, 22, 24, 25, 12, 21, 26, 30, 34, 20, 16, 2, 31, 35, 28, 32, 29, 37].includes(i)) {
                    // if(![31, 35, 28, 32, 29, 37].includes(i))  total[1] += item
                    if (i === 18) oddsTotal[1] += Number(item) * 4
                    if (i === 23 || i === 22 || i === 24) oddsTotal[1] += Number(item) * 3
                    if (i === 20 || i === 16 || i === 2 || [31, 35, 28, 32, 29, 37].includes(i)) oddsTotal[1] += Number(item)
                    if (i === 25 || i === 12 || i === 21 || [26, 30, 34].includes(i)) oddsTotal[1] += Number(item) * 2
                }
                if (item > 0 && [14, 20, 9, 15, 25, 4, 13, 27, 31, 35, 23, 3, 10, 26, 34, 32, 36, 29, 33].includes(i)) {
                    // if(![26, 34, 32, 36, 29, 33].includes(i))  total[2] += item
                    if (i === 14) oddsTotal[2] += Number(item) * 4
                    if (i === 20 || i === 9 || i === 15) oddsTotal[2] += Number(item) * 3
                    if (i === 23 || i === 3 || i === 10 || [26, 34, 32, 36, 29, 33].includes(i)) oddsTotal[2] += Number(item)
                    if (i === 25 || i === 4 || i === 13 || [27, 31, 35].includes(i)) oddsTotal[2] += Number(item) * 2
                }
                if (item > 0 && [7, 3, 1, 2, 0, 4, 12, 28, 32, 36, 9, 5, 24, 26, 30, 27, 35, 33, 37].includes(i)) {
                    // if(![26, 30, 27, 35, 33, 37].includes(i))  total[3] += item
                    if (i === 7) oddsTotal[3] += Number(item) * 4
                    if (i === 3 || i === 1 || i === 2) oddsTotal[3] += Number(item) * 3
                    if (i === 9 || i === 5 || i === 24 || [26, 30, 27, 35, 33, 37].includes(i)) oddsTotal[3] += Number(item)
                    if (i === 0 || i === 4 || i === 12 || [28, 32, 36].includes(i)) oddsTotal[3] += Number(item) * 2
                }
                if (item > 0 && [11, 5, 16, 10, 0, 21, 13, 29, 33, 37, 1, 22, 15, 30, 34, 27, 31, 28, 36].includes(i)) {
                    // if(![30, 34, 27, 31, 28, 36].includes(i))  total[4] += item
                    if (i === 11) oddsTotal[4] += Number(item) * 4
                    if (i === 5 || i === 16 || i === 10) oddsTotal[4] += Number(item) * 3
                    if (i === 1 || i === 22 || i === 15 || [30, 34, 27, 31, 28, 36].includes(i)) oddsTotal[4] += Number(item)
                    if (i === 0 || i === 21 || i === 13 || [29, 33, 37].includes(i)) oddsTotal[4] += Number(item) * 2
                }
            })
            return type === 1 ? total : oddsTotal

        },
        // 区域赔率
        getTax(index) {
            if (!this.tableInfo.config.tax) return
            let tax
            if (this.cyyList[index].includes('A')) tax = this.tableInfo.config.tax['A']
            if (this.cyyList[index].includes('B')) tax = this.tableInfo.config.tax['B']
            if (this.cyyList[index].includes('C')) tax = this.tableInfo.config.tax['C']
            if (this.cyyList[index].includes('D')) tax = this.tableInfo.config.tax['D']
            if (this.cyyList[index].includes('E')) tax = this.tableInfo.config.tax['E']
            return (100 - Number(tax)) / 100  //佣金
        },
        resetWaitSet(type = 1) {
            if (type === 1) {
                this.betListWait.forEach((item, i) => {
                    this.$set(this.betListWait, i, 0)
                })
            }
            if (type === 2) {
                this.betListSure.forEach((item, i) => {
                    this.$set(this.betListSure, i, 0)
                })
            }
            if (type === 3) {
                this.betListSureOnce.forEach((item, i) => {
                    // if (localStorage.getItem('myBetInfo' + this.id)) {
                    //     localStorage.removeItem('myBetInfo' + this.id)
                    // }
                    this.$set(this.betListSureOnce, i, 0)
                })
            }
            if (type === 4) {
                this.betListMySure.forEach((item, i) => {
                    this.$set(this.betListMySure, i, 0)
                })
            }
        },
        /*弹窗*/
        showTextFn(c, t = 1.5, type, r) { //1  2展示输赢
            this.showText = true
            this.showTextCon = c
            this.showTimer = setTimeout(() => {
                this.showText = false
                this.showWin = false
                clearTimeout(this.showTimer)
            }, t * 1000)
        },
        showModal(c, t = 1.5) { //1  2展示输赢
            this.modal1 = true
            this.modalText = c
            let timer = setTimeout(() => {
                this.modal1 = false
                clearTimeout(timer)
            }, t * 1000)
        },
        del(n) {
            if (n === 1) {
                this.modal1 = !this.modal1
            }
            if (n === 2) {
                this.modal2 = !this.modal2
            }
            if (n === 3) {
                this.modal3 = !this.modal3
            }
            if (n === 4) {
                this.modal4 = !this.modal4
            }
            if(n === 5){
                this.modal1 = false
                this.modal2 = false
                this.modal3 = false
                this.modal4 = false
            }
        },
        showResult(t) {
            this.win = this.calcResult(t)
            this.openR = t
            this.addClass(t)  //亮区
            this.historyList.push({result: t})
            this.modal4 = true
            this.dealLu()
            // this.periods = Number(this.periods) + 1  //请求桌子信息即可
            this.playSound(t + '.mp3')
            this.getMember()
        },
        /*用户操作*/
        changeVoice() {
            if (this.voice === 0) {
                this.voice = 1
            } else {
                this.voice = 0
            }
            this.playSound('bg.mp3')
        },
        tableMove(d) {
            this.$nextTick(() => {
                let table = this.$refs.tableScroll
                if (d === 'l') {
                    table.scrollLeft = 0;
                }
                if (d === 'r') {
                    table.scrollLeft = table.scrollWidth - table.clientWidth
                }
            })
        },
        handleScroll() {   // 滚动事件处理
            this.$nextTick(() => {
                let table = this.$refs.tableScroll
                if (table.scrollLeft === 0) {
                    this.arrShow = 3
                } else if (table.scrollLeft === table.scrollWidth - table.clientWidth) {
                    this.arrShow = 1
                } else {
                    this.arrShow = 2
                }
            })
        },
        addClass(r) {
            this.openBetList = []
            if (r == 1) {
                // col18 col12
                this.openBetList = ['col23', 'col22', 'col24', 'col20', 'col16', 'col2', 'col25', 'col21',
                    'col26', 'col30', 'col34',
                    'col31', 'col35',
                    'col28', 'col32',
                    'col29', 'col37',
                ]
            }
            if (r == 2) {
                // col14  col13
                this.openBetList = ['col20', 'col9', 'col15', 'col23', 'col3', 'col10', 'col25', 'col4',
                    'col26', 'col34',
                    'col31', 'col35', 'col27',
                    'col36', 'col32',
                    'col29', 'col33',
                ]
            }
            if (r == 3) {
                //col7  col12
                this.openBetList = ['col3', 'col1', 'col2', 'col9', 'col5', 'col24', 'col0', 'col4',
                    'col26', 'col30',
                    'col27', 'col35',
                    'col28', 'col32', 'col36',
                    'col33', 'col37',
                ]
            }
            if (r == 4) {
                //col11 col13
                this.openBetList = ['col5', 'col16', 'col10', 'col1', 'col22', 'col15', 'col0', 'col21',
                    'col34', 'col30',
                    'col31', 'col27',
                    'col28', 'col36',
                    'col29', 'col37', 'col33'
                ]
            }
            let timer = setTimeout(() => {
                this.openBetList = []
                clearTimeout(timer)
            }, 4000)
        },
        dealSureBet(data, type = 1) {
            let betTotal = data.betTotal
            let arr = []
            for (let k in betTotal) {
                arr.push({[k]: betTotal[k]})
            }
            if (type === 2) { //type = 2  统计房间人数
                this.tableInfo.p = data.users.length
                this.person = data.users.length
                return
            }
            this.cyyList.forEach((item, m) => {
                arr.forEach((tem, n) => {
                    if (item == Object.keys(tem)[0]) {
                        let r = tem[Object.keys(tem)[0]]
                        this.$set(this.betListSure, m, r)
                    }
                })
            })
        },
        clickArrow(type) {
            let single, dom
            this.$nextTick(() => {
                dom = this.$refs.list;
                single = dom.offsetWidth / this.betList.length
                let l = parseInt(dom.style.left)
                if (type === 1) {
                    if (l < 0 && Math.abs(l) <= (dom.offsetWidth - single)) {
                        this.elementLeft += single
                    }
                } else {  //l
                    if (Math.abs(l) < dom.offsetWidth - single * 6) {
                        this.elementLeft -= single
                    }
                }
            })
        },
        clickMid(num) { //1-6   5 双 6 单
            if (num === 1) {
                this.betBegin(18)
            }
            if (num === 2) {
                this.betBegin(14)
            }
            if (num === 3) {
                this.betBegin(7)
            }
            if (num === 4) {
                this.betBegin(11)
            }
            if (num === 5) {
                this.betBegin(13)
            }
            if (num === 6) {
                this.betBegin(12)
            }
        },
        opt(type) {
            if (type === 1) {
                this.playSound('lr.mp3')
                this.lu = !this.lu
                this.modal2 = false
            }
            if (type === 2) {
                this.playSound('lr.mp3')
                this.modal2 = !this.modal2
                this.lu = false
            }
            if (type === 3) {
                if (this.stage != 1) return
                this.playSound('sure_cancel.mp3')
                this.sureBet()
            }
            if (type === 4) {
                this.playSound('sure_cancel.mp3')
                this.betAgainOpt()

            }
            if (type === 5) {
                this.playSound('sure_cancel.mp3')
                this.resetWaitSet()
            }
        },
        sureBet() {
            clearInterval((this.optTimer))
            this.userOptFun()  //清除用户操作倒计时 重新开始
            this.canBet = false
            let betAmount = 0, betAreaTotal = {A: 0, B: 0, C: 0, D: 0, E: 0}, betArea = {}
            // let betNumTotal = this.calcBetTotal(1)
            let betNumOddTotal = this.calcBetTotal(2)
            this.betListWait.forEach((item, i) => {
                if (item > 0) {
                    betAmount += item
                }
                if (item > 0) {
                    let k = this.cyyList[i]
                    betArea[k] = item
                }
            })
            if (!betAmount > 0) return
            for (let key in betArea) {
                if (key.includes('A')) betAreaTotal['A'] += betArea[key]
                if (key.includes('B')) betAreaTotal['B'] += betArea[key]
                if (key.includes('C')) betAreaTotal['C'] += betArea[key]
                if (key.includes('D')) betAreaTotal['D'] += betArea[key]
                if (key.includes('E')) betAreaTotal['E'] += betArea[key]
            }
            let data = {
                betAmount,
                betAreaTotal,
                betArea,
                ccy: "USDT",
                tableId: this.id,
                // betNumTotal,
                betNumOddTotal
            }
            this.lastBetInfo = {
                betArea,
            }
            this.$http.post(this.host + this.api.game.bet, data).then(response => {
                let resp = response.body
                this.canBet = true
                if (resp.code == 200) {
                    this.betListWait.forEach((item, i) => {
                        if (item > 0) {
                            this.$set(this.betListMySure, i, this.betListMySure[i] + item)
                        }
                    })
                    let myBetInfo = {
                        list: this.betListMySure,
                        periods: this.periods,
                        tableId: this.$route.query.gpage
                    }
                    localStorage.setItem('myBetInfo' + this.id, JSON.stringify(myBetInfo))
                    this.$Message.success('下注成功');
                    this.playSound('move.mp3')
                    this.total += betAmount
                    // 下注成功记录到当前这一轮中
                    this.dealBetSure(betArea)
                    this.ifCanBetAgain = true
                    // 重置待确认bet
                    this.resetWaitSet()
                    // 下注成功 刷新余额
                    this.getMember()
                } else {
                    this.resetWaitSet()
                    if (resp.msg) this.showModal(resp.msg, 2)
                }
            })
        },
        dealBetSure(data) {
            this.cyyList.forEach((item, i) => {
                for (let k in data) {
                    if (k === item) {
                        this.betListSureOnce[i] += data[k]
                    }
                }
            })
        },
        clickBet(item) {
            this.playSound('bet.mp3')
            this.currentSelBet = item
        },
        betBegin(l) {
            if (this.stage != 1) {  //先判断状态  不可下注
                this.$Message.warning('当前不在下注时间')
                return;
            }
            if (!this.betLimitOpt(l)) return;  // 下注限制判断
            this.playSound('bet.mp3')
            this.$set(this.betListWait, l, this.betListWait[l] + this.currentSelBet)
        },
        betAgainOpt() {  //续压按钮
            if (this.stage != 1) {  //先判断状态
                return;
            }
            // 清空现在可能已经存在的
            this.resetWaitSet()
            this.cyyList.forEach((item, i) => {
                for (let k in this.lastBetInfo.betArea) {
                    if (item === k) {
                        this.$set(this.betListWait, i, this.lastBetInfo.betArea[k])
                    }
                }
            })
        },
        setScale() {
            if (this.scale === 1) {
                this.scale = 1.6
            } else {
                this.scale = 1
            }
        },
        /*其他*/
        outTable(id) { //离开桌子
            let params = {
                tableId: id || this.id,
                userId: this.moneybab.uid
            }
            this.$http.get(this.host + this.api.game.quitTable, {params}).then(response => {
                let resp = response.body;
                if (resp.code == 200) {
                    if(id) {
                        this.getTables(1)
                    }
                    // console.log('退出成功')
                    // if (this.$route.path !== '/GamePage') this.$router.push({path: '/GamePage'})
                } else {
                    // this.$Message.error(resp.msg);
                    this.showModal(resp.msg)
                }
            });
        },
        goBack() {
            //获取个人安全信息
            this.$router.back(-1)
        },
        getBetImageSrc(item) {
            return require(`../components/img/room/${item}.png`)
        },
        getClassNames(l) {
            return {
                ['col' + l]: true,
                ['col-s']: this.openBetList.includes('col' + l),
            };
        },
        getClassLu(item, tem, i) {
            return {
                [['', 'c1', 'c2', 'c3', 'c4'][tem]]: true,
                ['line' + tem]: (item.length > 5) || (this.luList[i - 1] && (this.luList[i - 1][0] % 2) === (item[0] % 2))
            };
        },
        handleVisibilityChange() {
            // this.$router.go(0)
            if (document.visibilityState === 'visible') {
                // 页面从后台切换到前台
                location.reload(); // 刷新页面
            }
            if (document.hidden) {
                // 页面切换到后台，暂停音频播放
                this.ifCurrent = false
            } else {
                // 页面回到前台，继续音频播放
                this.ifCurrent = true
            }
            this.playSound('bg.mp3')
        },
        playSound(s) {
            if (this.voice === 0) {
                if (this.audio1) {
                    this.audio1.pause()
                    this.audio1 = null; // 清空 Audio 对象
                }
                return
            }
            if (!this.ifCurrent) {
                // console.log('false')
                if (this.audio1) {
                    this.audio1.pause()
                    this.audio1 = null; // 清空 Audio 对象
                }
                return
            }
            if (this.getSound) {
                if (this.audio1 && this.audio1.paused) {
                    this.audio1.play()
                }
                if (s == 'bg.mp3') {
                    let p1 = require("../assets/sound/" + s)
                    this.audio1 = new Audio(p1);
                    // 设置音频循环播放
                    this.audio1.loop = true;
                    this.audio1 && this.audio1.play();
                } else if (['start.mp3', 'stop.mp3', '1.mp3', '2.mp3', '3.mp3', '4.mp3'].includes(s)) {
                    if (s == 'start.mp3') this.$refs.audioTagStart.play()
                    if (s == 'stop.mp3') this.$refs.audioTagStop.play()
                    if (s == '1.mp3') this.$refs.audioTag1.play()
                    if (s == '2.mp3') this.$refs.audioTag2.play()
                    if (s == '3.mp3') this.$refs.audioTag3.play()
                    if (s == '4.mp3') this.$refs.audioTag4.play()
                } else {
                    let p = require("../assets/sound/" + s)
                    this.audio = new Audio(p); // 替换为你的 MP3 文件路径
                    this.audio.volume = 1
                    this.audio && this.audio.play();
                }
            } else {
                let p1 = require("../assets/sound/" + s)
                this.audio1 = new Audio(p1);
                this.audio1.loop = true;  // 设置音频循环播放
                this.audio1 && this.audio1.play();
                this.getSound = true
            }
        },
        betLimitOpt(l) { //r1 ,r2 ,r3  当前选择的筹码  个人当前下注总筹码  当前区域总筹码 + 当前自己要下注的
            let loc  //先获取地区 ABCD    // 先判断余额
            if (this.cyyList[l].includes('A')) loc = 'A'
            if (this.cyyList[l].includes('B')) loc = 'B'
            if (this.cyyList[l].includes('C')) loc = 'C'
            if (this.cyyList[l].includes('D')) loc = 'D'
            let total = 0
            this.betListWait.forEach(item => {
                if (item > 0) total += item
            })
            if (this.moneybab.amount < total) {
                this.showModal('余额不足')
                return false;
            }
            if (!this.limitConfig.limitBet) return true
            let r2 = Number(this.betListSureOnce[l]) + Number(this.currentSelBet)
            let r3 = Number(this.betListSure[l]) + Number(this.betListSureOnce[l]) + this.currentSelBet
            if (this.moneybab.amount < this.currentSelBet) {
                // this.$Message.warning('余额不足')
                this.showModal('余额不足')
                return false;
            } else if (r2 > this.limitConfig.limitBet[loc]) {  //个人限制
                // this.$Message.warning('当前区个人下注不可超过' + this.limitConfig.limitBet[loc])
                this.showModal('当前区个人下注不可超过' + this.limitConfig.limitBet[loc])
                return false;
            } else if (r3 > this.limitConfig.limitRed[loc]) {
                // this.$Message.warning('当前区总下注不可超过' + this.limitConfig.limitRed[loc])
                this.showModal('当前区总下注不可超过' + this.limitConfig.limitRed[loc])
                return false;
            } else {
                return true
            }
        },
        // 防抖函数
        debounce(func, delay) {
            let timer;
            return function () {
                const context = this;
                const args = arguments;
                clearTimeout(timer);
                timer = setTimeout(() => {
                    func.apply(context, args);
                }, delay);
            };
        },
    },
    beforeDestroy() {
        clearInterval(this.betTimer)
        clearInterval(this.timer)
        clearInterval(this.showTimer)
        clearInterval(this.descTimer)
        clearInterval(this.optTimer)
        this.outTable()
        // this.removeWheelEventListener()
        this.socket && this.socket.close();
        if (this.audio) {
            this.audio.pause();
            this.audio = null; // 清空 Audio 对象
        }
        if (this.audio1) {
            this.audio1.pause();
            this.audio1 = null; // 清空 Audio 对象
        }

    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/deep/ .ivu-modal-header {
    display: none;
    height: 45px;
    //line-height: 45px;
    font-weight: bold;
    font-size: 16px;
    background-color: #3B413A;

    & > div {
        color: #fff !important;
    }
}

/deep/ .ivu-modal-content {
    .ivu-modal-close {
        display: none;
    }
}

/deep/ .ivu-modal-body {
    padding: 0;

    .pop-box {
        background-color: #13171E;
        display: flex;
        flex-direction: column;

        .top {
            display: flex;
            height: 45px;
            line-height: 45px;
            position: relative;
            background: linear-gradient(to bottom, #3d4864, #262834);

            .t-l {
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }

            .t-r {
                font-size: 20px;
                line-height: 45px;
                position: absolute;
                right: 0;
                top: 0;
                background: linear-gradient(to bottom, #4b5f86, #2f374d);
                -webkit-clip-path: polygon(31% 0, 100% 0, 100% 100%, 0 100%);
                clip-path: polygon(31% 0, 100% 0, 100% 100%, 0 100%);
                height: 100%;
                width: 13%;
            }
        }

        .con {
            width: 96%;
            margin: 0 auto 20px;
            color: #fff;
            padding-bottom: 40px;
            border-bottom: 1px solid #fff;
            display: flex;
            flex-direction: column;

            .t-title {
                font-weight: bold;
                text-align: center;
                font-size: 18px;
                height: 30px;
                line-height: 30px;
            }

            .des {
                text-align: left;
                font-size: 12px;

                table {
                    width: 100%;
                    border-collapse: collapse;
                    box-sizing: border-box;

                    & > tbody > tr > td {
                        text-align: center;
                        box-sizing: border-box;
                        border: 1px solid #ddd;
                    }

                    thead {
                        tr {
                            th {
                                border: 1px solid #ddd;
                            }
                        }
                    }
                }
            }
        }
    }

    .pop-box-notice {
        margin: auto;

        .con {
            border: none;
            line-height: 100px;
            font-size: 16px;

            i {
                font-size: 20px;
            }
        }
    }
}

/deep/ .ivu-modal-footer {
    display: none;
}

.model-notice {
    /deep/ .ivu-modal {
        width: 70% !important;
        margin: 100px auto;

        .ivu-modal-content {
            background-color: transparent;
        }

        .pop-box {
            background-color: transparent;
        }

        .con {
            color: #fff;
            background-color: rgba(17, 17, 1, .7);
            line-height: 90px;
            width: 100%;
        }

        .con1 {
            color: #fff;
            background-color: rgba(17, 17, 1, .7);
            line-height: 42px;
            height: auto;
            width: 100%;
            padding-bottom: 10px;

        }
    }
}

.box {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.nav-rights {
    //position: fixed;
    font-family: Arial, "Microsoft YaHei", "PingFang SC", "WenQuanYi Micro Hei", sans-serif;
    font-weight: bold;
    user-select: none;
    //max-height: 100vh; /* 最大高度为视口高度 */
    //overflow-y: auto; /* 根据内容高度自动显示滚动条 */
    //overscroll-behavior-y: none; /* 禁止垂直方向的橡皮筋效果 */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.popup-txt {
    position: fixed;
    top: 200px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 40px;
    z-index: 999;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    background-image: url("../components/img/room/popup_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.popup-txt-win {
    top: 150px;
    position: static;
}

.box_21 {
    height: 50px;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(8, 57, 42, .5);
    //align-items: baseline;
    // background: url(../assets/netmage/xxyz.png) no-repeat center;
    // background-size: cover;
    //margin-bottom: 20px;
    z-index: 200;

    .back-img {
        width: 33.3%;
        display: flex;

        & > img {
            margin-top: 6px;
        }
    }

    .title {
        color: aliceblue;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        width: 33.3%;
    }

    .balance {
        display: flex;
        height: 100%;
        width: 33.3%;
        color: #fff;
        text-align: right;
        line-height: 30px;
        justify-content: end;
        align-items: center;

        & > img {
            height: 14px;
            //width: 16px;
            margin: 6px 2px;
        }
    }
}

.box-video {
    position: relative;

    .icon-voice {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        background-color: rgba(255, 255, 255, .3);
        z-index: 99;
    }

    .icon-voice-line:after {
        height: 2px;
        width: 100%;
        background-color: #ddd;
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        transform: rotate(45deg);
    }

    .icon-scale {
        position: absolute;
        top: 60px;
        left: 20px;
        z-index: 9999;
        width: 25px;
        height: 25px;
        //background-color: rgba(255, 255, 255, .5);
        border-radius: 50%;

        .icon-scale-icon {
            width: 24px;
            color: #3977C4;
            margin: 2px auto;
        }
    }


    .desc-popup {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        color: #fff;
        background-color: transparent;
        font-size: 10px;
        line-height: 45px;
        text-align: center;
    }

    .heguan {
        position: absolute;
        bottom: 0;
        width: 200px;
        height: 30px;
        color: #fff;
        font-size: 12px;
        line-height: 30px;
        text-align: left;
        text-indent: 12px;
    }

    .person {
        position: absolute;
        bottom: 0;
        right: 10px;
        width: 200px;
        height: 30px;
        color: #fff;
        font-size: 12px;
        line-height: 30px;
        text-align: right;
        font-weight: 500;
        display: flex;
        justify-content: end;
        align-items: center;

        & > img {
            margin-right: 4px;
            height: 12px;
        }
    }
}

.box-info {
    background-color: #08392A;
    width: 100%;
    height: 30px;
    display: flex;
    line-height: 30px;
    color: #fff;
    font-size: 14px;
    justify-content: space-around;
    text-align: center;
    align-items: center;

    .arr {
        //position: absolute;
        //color: rgba(255, 255, 255, .6);
        font-size: 26px;
        //top: 34%;
        //bottom: 0;
        //margin: auto;
        z-index: 999;
        //background-color: pink;
    }

    .arr:active {
        transform: scale(1.2);
    }
}

.bet-box-box {
    width: 100%;
    position: relative;

    //.arr {
    //    position: absolute;
    //    color: rgba(255, 255, 255, .6);
    //    font-size: 30px;
    //    top: 34%;
    //    //bottom: 0;
    //    margin: auto;
    //    z-index: 999;
    //    //background-color: pink;
    //}

    //.arrow-left {
    //    left: 10px;
    //}
    //
    //.arrow-right {
    //    right: 10px;
    //}

    .box-bet-scroll {
        width: 200%;
        display: flex;
    }
}

.box-bet {
    width: 100%;
    overflow-x: scroll;
    background-color: #000;
}

.box-bet::-webkit-scrollbar {
    height: 0;
}

.bet-top {
    width: 50%;
    height: 368px;
    background-image: url("../components/img/room/bet-box.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 -28px;
    display: flex;


    .bet-top-box {
        height: 92%;
        width: 100%;
        margin: 0.5% auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .box-mid {
            //display: none;
            width: 60%;
            height: 56%;
            left: 20%;
            top: 19%;
            position: absolute;
            overflow: hidden;
            z-index: 99;

            .wrap-top {
                position: absolute;
                width: 50%;
                height: 24%;
                left: 25%;
                top: 2%;
                //background-color: aqua;
            }

            .wrap-top-l {
                position: absolute;
                width: 140px;
                height: 100%;
                left: -140px;
                //background-color: aqua;
                transform-origin: bottom right; /* 设置旋转的中心点为左上角 */
                transform: rotate(40deg); /* 逆时针旋转45度 */
            }

            .wrap-top-r {
                position: absolute;
                width: 140px;
                height: 100%;
                right: -140px;
                //background-color: aqua;
                transform-origin: bottom left; /* 设置旋转的中心点为左上角 */
                transform: rotate(-40deg); /* 逆时针旋转45度 */
            }

            .wrap-bottom-t {
                position: absolute;
                width: 140px;
                height: 100%;
                left: -140px;
                //background-color: aqua;
                transform-origin: top right; /* 设置旋转的中心点为左上角 */
                transform: rotate(-40deg); /* 逆时针旋转45度 */
            }

            .wrap-bottom-b {
                position: absolute;
                width: 140px;
                height: 100%;
                right: -140px;
                //background-color: aqua;
                transform-origin: top left; /* 设置旋转的中心点为左上角 */
                transform: rotate(40deg); /* 逆时针旋转45度 */
            }

            .wrap-left-t {
                position: absolute;
                width: 100%;
                height: 140px;
                top: -140px;
                //background-color: green;
                transform-origin: bottom right; /* 设置旋转的中心点为左上角 */
                transform: rotate(-40deg); /* 逆时针旋转45度 */
            }

            .wrap-left-b {
                position: absolute;
                width: 100%;
                height: 140px;
                top: 103px;
                //background-color: green;
                transform-origin: top right; /* 设置旋转的中心点为左上角 */
                transform: rotate(40deg); /* 逆时针旋转45度 */
            }

            .wrap-right-t {
                position: absolute;
                width: 100%;
                height: 140px;
                top: -140px;
                //background-color: gold;
                transform-origin: bottom left; /* 设置旋转的中心点为左上角 */
                transform: rotate(40deg); /* 逆时针旋转45度 */
            }

            .wrap-right-b {
                position: absolute;
                width: 100%;
                height: 140px;
                top: 103px;
                //background-color: gold;
                transform-origin: top left; /* 设置旋转的中心点为左上角 */
                transform: rotate(-40deg); /* 逆时针旋转45度 */
            }


            .wrap-left {
                position: absolute;
                width: 24.5%;
                height: 50%;
                top: 26%;
                left: 1.1%;
                //background-color: green;
            }

            .wrap-right {
                position: absolute;
                width: 24.5%;
                height: 50%;
                top: 26%;
                right: 1.1%;
                //background-color: green;
            }

            .wrap-bottom {
                position: absolute;
                width: 50%;
                height: 25%;
                bottom: 0;
                left: 25%;
                //background-color: aqua;
            }

            .left {
                width: 25%;
                height: 50%;
                position: absolute;
                left: 50%;
                top: 26%;
            }

            .right {
                width: 25%;
                height: 50%;
                position: absolute;
                left: 25%;
                top: 26%;
            }

            .breath {
                background-color: rgba(173, 255, 47, 0.2);
                animation: breathingAnimation1 4s forwards;
            }

            @keyframes breathingAnimation1 {
                0% {
                    background-color: rgba(173, 255, 47, 0.2);
                }
                25% {
                    background-color: rgba(173, 255, 47, 0.5);
                }
                50% {
                    background-color: rgba(173, 255, 47, 0.2);
                }
                100% {
                    background-color: rgba(173, 255, 47, 0.5);
                }
            }

        }

        .col {
            height: 20%;
            width: 20%;
            display: flex;
            position: relative;
            //border: 1px solid red;
            //box-sizing: border-box;

            .wra {
                position: relative;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                z-index: 99;

                .my-bet {
                    z-index: 99;
                }

                .bet-show {
                    bottom: 0;
                    height: 16px;
                    width: 100%;
                    position: absolute;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    font-size: 12px;

                    & > span {
                        left: 0;
                        position: absolute;
                        min-width: 20px;
                        //top: 50%;
                        line-height: 5px;
                        //display: block;
                        height: 16px;
                        padding: 5px;
                        //border: 1px solid #fff;
                        //background-color: rgba(0, 0, 0, .6);
                        border-radius: 6px;
                        color: #fff;
                        //margin-bottom: 5px;
                    }
                }

                .bet-show1 {
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 100%;
                    position: absolute;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    font-size: 8px;

                    & > span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        line-height: 5px;
                        display: block;
                        height: 16px;
                        padding: 5px;
                        border: 1px solid #fff;
                        background-color: rgba(255, 0, 0, .6);
                        border-radius: 6px;
                        color: #fff;
                        margin-bottom: 5px;
                    }
                }


                .my-bet {
                    bottom: 20px;
                    position: absolute;
                    color: #fff;
                    display: flex;
                    justify-content: flex-start;
                    font-size: 12px;

                    img {
                        height: 18px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    & > span {
                        position: absolute;
                        top: -16px;
                        left: 20px;
                        display: block;
                        height: 20px;
                        padding: 5px;
                        line-height: 10px;
                        border: 1px solid #fff;
                        background-color: rgba(9, 135, 16, .6);
                        border-radius: 6px;
                        color: #fff;
                        margin-bottom: 5px;
                    }
                }

                .my-bet {
                    img {
                        height: 18px;
                        position: absolute;
                        top: -8px;
                        left: 0;
                    }
                }

                .betShow12 {
                    transform: rotate(-90deg) translateX(10px) translateY(50%);
                }

                .betShow13 {
                    transform: rotate(-90deg) translateX(10px) translateY(50%);
                }

            }

            .col-info {
                display: none;
                width: 100%;
                height: 100%;
                position: absolute;

                & > p {
                    height: 30px;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    position: absolute;
                    text-align: center;
                }
            }
        }

        .col-s { //呼吸灯
            background-color: rgba(173, 255, 47, 0.2);
            animation: breathingAnimation 4s infinite forwards;
        }

        @keyframes breathingAnimation {
            0% {
                background-color: rgba(173, 255, 47, 0.2);
            }
            25% {
                background-color: rgba(173, 255, 47, 0.5);
            }
            50% {
                background-color: rgba(173, 255, 47, 0.2);
            }
            100% {
                background-color: rgba(173, 255, 47, 0.5);
            }
        }


        .col14, .col7, .col11, .col18 {
            .col-info {
                color: #000;
            }

            .wra {
                .my-bet {
                    span {
                        color: #000;
                    }
                }
            }
        }

        .col11 {
            .wra {
                .bet-show1 {
                    top: -20px;
                    left: 5px;

                    & > span {
                        left: 18%;
                    }

                    & > img {
                        width: 20px;
                        position: absolute;
                        left: 40px;
                    }
                }

                .bet-show {
                    bottom: -20px;

                    & > span {
                        left: 22%;
                    }
                }

                .my-bet {
                    img {
                        top: 15px;
                        left: 20px;
                    }

                    & > span {
                        top: -50px;
                        left: 15px;
                    }
                }

                .my-bet {
                    img {
                        top: 10px;
                    }
                }
            }
        }

        .col14 {
            .wra {
                .bet-show1 {
                    left: 9px;
                    top: -20px;
                    transform: rotate(0);

                    & > img {
                        width: 20px;
                        left: 30px;
                        position: absolute;
                    }
                }

                .bet-show {
                    bottom: -20px;

                    & > span {
                        left: 0;
                        //position: static;
                    }
                }

                .my-bet {
                    img {
                        top: 15px;
                        left: 36px;
                    }

                    & > span {
                        top: -50px;
                        left: 32px;
                    }
                }

                .my-bet {
                    img {
                        top: 10px;
                    }
                }
            }
        }

        .col7 {
            .wra {
                .bet-show1 {
                    top: -12px;

                    & > span {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        min-width: 20px;
                        margin: auto;
                        position: static;
                    }
                }

                .bet-show {
                    bottom: 22px;
                    left: -20px;

                    & > span {
                        top: 0;
                    }
                }

                .my-bet {
                    img {
                        top: -33px;
                        left: -4px;
                    }

                    & > span {
                        top: -40px;
                        left: 60px;
                    }
                }

                .my-bet {
                    img {
                        top: -40px;
                    }
                }
            }
        }

        .col18 {
            .wra {
                .bet-show1 {
                    top: 20px;

                    & > span {
                        margin: auto;
                        display: block;
                        min-width: 20px;
                        position: static;
                    }
                }

                .bet-show {
                    & > span {
                        top: 13px;
                        left: -20px;
                    }
                }

                .my-bet {
                    img {
                        top: 4px;
                        left: -4px;
                    }

                    & > span {
                        top: -4px;
                        left: 60px;
                    }
                }

                .my-bet {
                    img {
                        top: -4px;
                    }
                }
            }
        }

        .col13 {
            .wra {
                .bet-show1 {
                    left: 9px;
                    top: -20px;
                    transform: rotate(0);

                    & > img {
                        width: 20px;
                        left: 30px;
                        position: absolute;
                    }
                }

                .bet-show {
                    transform: rotate(0);
                    bottom: -20px;
                }

                .my-bet {
                    transform: rotate(0);

                    img {
                        left: 20px;
                    }

                    & > span {
                        left: 16px;
                        top: -44px;
                    }
                }

                .my-bet {
                    img {
                        left: 20px;
                    }
                }

            }
        }

        .col12 {
            .wra {
                .bet-show1 {
                    left: 9px;
                    top: -20px;
                    transform: rotate(0);

                    & > img {
                        width: 20px;
                        left: 30px;
                        position: absolute;
                    }
                }

                .bet-show {
                    transform: rotate(0);
                    bottom: -20px;
                }

                .my-bet {
                    transform: rotate(0);

                    img {
                        left: 16px;
                    }

                    & > span {
                        left: 16px;
                        top: -44px;
                    }
                }

            }
        }

        .col7 {
            height: 1px;

            .col-info {
                line-height: 0;
            }
        }

        .col18 {
            .col-info {
                & > p {
                    line-height: 60px;
                }
            }
        }

        .col11 {
            .col-info {
                & > p {
                    text-align: left;
                    text-indent: 25px;
                }
            }
        }

        .col14 {
            .col-info {
                text-align: left;
                text-indent: 20px;
            }
        }


        .col12, .col13 {
            width: 14.5%;
        }

        .col0, .col4, .col21, .col25 {
            height: 24.5%;
            width: 21%;
        }

        .col1, .col2, .col3, .col22, .col23, .col24 {
            height: 15.5%;
            width: 19.3%;
        }

        .col22, .col23, .col24 {
            margin-top: 7.5%;
        }

        .col5, .col6, .col7, .col8, .col9, .col10, .col11, .col12, .col13, .col14, .col15, .col16, .col17, .col18, .col19, .col20 {
            height: 15.5%;
        }

        .col5, .col9, .col10, .col15, .col16, .col20 {
            width: 16.5%;
        }

        .col6, .col8, .col11, .col14, .col17, .col19 {
            width: 23.5%;
        }

        .col11, .col14 {
            width: 19%;

            .wra {
                .bet-show {
                    & > span {
                        color: #696969;
                    }
                }
            }
        }

        .col9, .col15, .col20, {
            .wra {
                .bet-show, .my-bet {
                    img {
                        margin-left: 16px;
                    }
                }
            }
        }

        .cla-last {
            height: 5%;
        }

    }
}

.bet-top-right {
    background-image: url("../components/img/room/bet_bg_02.png");

    .bet-top-box {
        height: 82%;
        //border: 1px solid greenyellow;
        //box-sizing: border-box;

        .col {
            //border: 1px solid red;
            //box-sizing: border-box;
            width: 25%;

            .wra {
                .my-bet {
                    & > img {
                        height: 18px;
                        position: absolute;
                        top: -8px;
                        left: 0;
                    }

                    & > span {
                        position: absolute;
                        top: -16px;
                        left: 20px;
                        display: block;
                        height: 20px;
                        padding: 5px;
                        line-height: 10px;
                        border: 1px solid #fff;
                        background-color: rgba(9, 135, 16, 0.6);
                        border-radius: 6px;
                        color: #fff;
                        margin-bottom: 5px;
                    }
                }

                .bet-show {
                    left: 0;
                    bottom: 0;

                    & > span {
                        left: 0;
                        color: #fff;
                    }
                }
            }
        }

        .col26, .col27, .col28, .col29 {
            height: 34.5%;
        }

        .col30, .col31, .col32, .col33 {
            height: 33%;
        }

        .col34, .col35, .col36, .col37 {
            height: 33%;
        }
    }
}

.my-bet {
    & > span {
        background-color: #146999 !important;
    }
}

.opencard::after {
    background: linear-gradient(#2D7D3E, #01D366, #2D7D3E);
    box-shadow: inset 0 0 0 2px #6BFF64;
    z-index: 0;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    animation: portrait_opencard 1s infinite;
}

@-webkit-keyframes portrait_opencard {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        opacity: 0;
    }
}

@keyframes portrait_opencard {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        opacity: 0;
    }
}


onbet::before {
    display: none;
}

bet_1.opencard::after {
    background: url('../assets/newImage/onbet_1.png') no-repeat 0 0 / 100% 100%;
}

bet_2.opencard::after {
    background: url('../assets/newImage/onbet_2.png') no-repeat 0 0 / 100% 100%;
}

bet_3.opencard::after {
    background: url('../assets/newImage/onbet_3.png') no-repeat 0 0 / 100% 100%;
}

bet_4.opencard::after {
    background: url('../assets/newImage/onbet_4.png') no-repeat 0 0 / 100% 100%;
}

bet_13.opencard::after {
    background: url('../assets/newImage/onbet_5.png') no-repeat 0 0 / 100% 100%;
}

bet_14.opencard::after {
    background: url('../assets/newImage/onbet_6.png') no-repeat 0 0 / 100% 100%;
}

.bet-coin-list {
    position: relative;
    height: 50px;
    // background-color: #100C03;
    width: 80%;
    margin: -25px auto 0;
    display: flex;

    .arr {
        position: absolute;
        color: #fff;
        top: 10px;
        bottom: 0;
        font-size: 35px;
    }

    .arr-r {
        left: -35px;
    }

    .arr-l {
        right: -35px;
    }

    .box-list {
        width: 100%;
        position: relative;
        overflow: hidden;
        // background-color: #100C03;
        // background-image: url("../components/img/room/bet-list-bg.jpg");
        // background-repeat: no-repeat;
        // background-size: 100% 100%;
        // background-position: center center;
    }

    .bet-coin-list-box {
        position: absolute;
        overflow-x: auto;
        height: 60px;
        display: flex;
        left: 10px;

        .bet-coin-item {
            height: 40px;
            margin-left: 10px;
            margin-top: 10px;
            float: left;
            opacity: .6;
        }

        .active {
            border: 2px solid #ccc;
            border-radius: 50%;
            opacity: 1;
        }
    }

}

.bot-btn-box {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .btn {
        background-color: red;
        display: flex;
        align-items: center;

        background-color: rgba(24, 25, 32, 0.7);
        border-right: 1px solid #3e3e3e;
        //width: 20%;
        height: 50px;

        .btn-box {
            display: flex;
            align-items: center;
        }
    }

    .icon {
        font-size: 24px;
        align-items: center;
    }

    .icon-1 {
        color: #909092;
        font-size: 36px;
    }

    .icon-5 {
        color: #fff;
        font-size: 36px;
    }

    .btn_active {
        //color: #666565;
        //background-color: #3B413A;
        background-image: url("../components/img/room/active.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.lu-tu {
    width: 100%;
    height: 37vw;
    background-image: url("../assets/newImage/cardroad_bg_16.png");
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;

    .lu-list {
        width: 100%;
        height: 100%;
        display: flex;

        .lu-col {
            width: 6.25%;
            height: 100%;

            .lu-item {
                display: flex;
                flex-direction: column;
                height: 16.6%;
                background-color: pink;
                border-radius: 50%;
                line-height: 24px;
                color: #fff;

                & > span {
                    position: relative;
                    height: 100%;
                    display: block;
                }

                & > .line:after {
                    top: -100%;
                    right: -60%;
                    display: block;
                    position: relative;
                    content: '';
                    width: 2px;
                    height: 100%;
                    background-color: red;
                    border: 1px solid #000;
                }

            }

            .c1 {
                background-color: #757474;
                background-image: url("../components/img/room/1.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .line1 {
                background-image: url("../components/img/room/1long.png");
            }

            .c2 {
                background-color: #075622;
                background-image: url("../components/img/room/2.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .line2 {
                background-image: url("../components/img/room/2long.png");
            }

            .c3 {
                background-color: #D9A51F;
                background-image: url("../components/img/room/3.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .line3 {
                background-image: url("../components/img/room/3long.png");
            }

            .c4 {
                background-color: #B63A33;
                background-image: url("../components/img/room/4.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .line4 {
                background-image: url("../components/img/room/4long.png");
            }
        }
    }
}

.tableStatus {
    background-color: rgba(0, 0, 0, .4);
    color: #fff;
    border-radius: 50%;
}

.desc-num {
    font-size: 16px;
}

/deep/ .ivu-modal-mask {
    background-color: rgba(55, 55, 55, .3);
}

/deep/ .ivu-btn {
    border-radius: 0
}

/deep/ .ivu-btn-primary {
    border-color: transparent
}

/deep/ .ivu-spin-fix {
    background-color: rgba(255, 255, 255, .5);
}

/deep/ .ivu-spin-dot {
    width: 60px;
    height: 60px;
}

</style>
